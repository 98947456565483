<template>
  <div>
    <!-- 订单改派 -->
    <div
      :class="mapyin == true ? 'box' : 'box2'"
      v-if="(detail && alters) || zhis || shoulis"
    >
      <img
        class="cha"
        @click="close"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="box-main" :class="{ boxmain2: mapyin == true }">
        <div class="main-nav" :class="{ jiaMain: mapyin == true }">
          <h1 v-if="detail.接单人员 != ''">订单改派</h1>
          <h1 v-if="detail.接单人员 == ''">订单指派</h1>
          <div v-show="!newbtnyin">
            <img class="shua" src="../assets/img/ding/shua.png" alt="" />
            <i class="xin" @click="refresh(detail.订单号)">刷新</i>
          </div>
        </div>

        <div id="peoper" :class="{ jiaMain: mapyin == true }">
          <div>
            <span class="tiao2"></span>
            <span class="pai" v-if="detail.接单人员 == ''">可指派人员</span>
            <span class="pai" v-if="detail.接单人员 != ''">可改派人员</span>

            <div class="qie" v-if="!showKj">
              <div class="list" @click="paiTab">
                <img class="listImg" :src="img" alt="" />
                <span class="listTit" ref="lie">列表</span>
              </div>

              <div class="ditu" @click="paiMap">
                <img class="dituImg" :src="img1" alt="" />
                <span class="dituTit dynamic" ref="di">地图</span>
              </div>
            </div>

            <div class="biao" v-show="mapyin">
              <div class="newxuan">
                <el-checkbox-button
                  v-model="checked1"
                  label="车牌"
                  size="large"
                  @change="jiage"
                />
                <div class="newxuanbox">
                  <i
                    @click="shoutype(0)"
                    :class="[cartype == 0 ? 'shoufeiz' : 'zhang']"
                    >全部</i
                  >
                  <i class="tiao3"></i>
                  <i
                    @click="shoutype(1)"
                    :class="[cartype == 1 ? 'shoufeiz' : 'zhang']"
                    >任务点</i
                  >
                  <i class="tiao3" v-if="detail.目的地 != ''"></i>
                  <i
                    @click="shoutype(2)"
                    :class="[cartype == 2 ? 'shoufeiz' : 'zhang']"
                    v-if="detail.目的地 != ''"
                    >目的地</i
                  >
                </div>

                <div class="newxuanbox" v-if="false">
                  <i
                    @click="shoukj(1)"
                    :class="[iskj == 1 ? 'shoufeiz' : 'zhang']"
                    >快捷</i
                  >
                  <i class="tiao3"></i>
                  <i
                    @click="shoukj(2)"
                    :class="[iskj == 2 ? 'shoufeiz' : 'zhang']"
                    >其他</i
                  >
                </div>
              </div>

              <div class="site">
                <img class="ddd" src="../assets/img/cd/kong.png" alt="" />
                <span class="depart-main">空闲</span>
              </div>
              <div class="site">
                <img class="ddd" src="../assets/img/cd/fuwu1.png" alt="" />
                <span class="depart-main">服务中</span>
              </div>
              <div class="site">
                <img class="ddd" src="../assets/img/cd/fuwu.png" alt="" />
                <span class="depart-main">离线</span>
              </div>
            </div>
          </div>

          <div class="newbtn" v-if="newbtnyin">
            <el-popover
              :visible="visible"
              placement="left"
              :width="400"
              trigger="click"
            >
              <div class="sharenav">共享价格</div>
              <div>
                <div class="name1">
                  <label class="pricelabel">价格设置</label>
                  <el-radio
                    v-model="gxchargeType"
                    label="1"
                    size="large"
                    @change="funGonggx"
                    >一口价</el-radio
                  >
                  <el-radio
                    v-model="gxchargeType"
                    label="2"
                    size="large"
                    @change="funGonggx"
                    >公里价</el-radio
                  >
                </div>
                <div class="elect">
                  <div class="elect1">
                    <input
                      class="elect-span1"
                      v-model="gxa"
                      placeholder="基准价"
                    />
                    <span class="elect-span2">元</span>
                  </div>

                  <div class="electbox" v-if="showYiKou">
                    <div class="elect2">
                      <input
                        class="elect-span1"
                        v-model="gxb"
                        placeholder="基准公里"
                      />
                      <span class="elect-span3">公里</span>
                    </div>
                    <div class="elect2">
                      <input
                        class="elect-span1"
                        v-model="gxc"
                        placeholder="超基准价"
                      />
                      <span class="elect-span4">元/公里</span>
                    </div>
                  </div>
                </div>
                <div class="gaipricebtn">
                  <button class="reset" @click="visible = false">取消</button>
                  <button class="deter" @click="shareorder">确定</button>
                </div>
              </div>
              <template #reference>
                <button
                  class="recycle"
                  @click="visible = true"
                  v-if="detail.canShare"
                >
                  共享
                </button>
              </template>
            </el-popover>
            <button
              class="recycle2"
              v-if="detail.canH5"
              @click="xiangbtn(detail.订单号)"
            >
              H5 分享
            </button>

            <el-popover
              :visible="visible1"
              placement="left"
              :width="400"
              trigger="click"
            >
              <div class="sharenav">订单回收</div>
              <div style="display: contents">
                <div
                  style="
                    width: 60px;
                    float: left;
                    margin-left: 20px;
                    margin-right: 20px;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    line-height: 32px;
                    text-align: right;
                  "
                >
                  原因
                </div>
                <div style="margin-top: 30px; margin-bottom: 30px">
                  <el-select v-model="gp_reason" placeholder="请选择原因">
                    <el-option
                      v-for="item in gplist"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </div>

                <div class="gaipricebtn">
                  <button class="reset" @click="visible1 = false">取消</button>
                  <button
                    class="deter"
                    @click="recycleNew(detail.订单号)"
                    :disabled="gp_reason == '' ? true : false"
                    :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                  >
                    确定
                  </button>
                </div>
              </div>
              <template #reference>
                <button
                  class="recycle"
                  @click="visible1 = true"
                  v-if="detail.canRecycle"
                >
                  回收
                </button>
              </template>
            </el-popover>

            <!--<button
              class="recycle"
              v-if="detail.canRecycle"
              :plain="true"
              @click="recycle(detail.订单号)"
            >
              回收
            </button>-->
            <button
              class="cancel"
              v-if="detail.可编辑"
              :plain="true"
              @click="edit(detail.订单号)"
            >
              修改
            </button>

            <button class="cancel" @click="dingbei(detail.订单号)">备注</button>

            <button class="cancel" v-if="detail.canCancel" @click="lish">
              取消
            </button>

            <div class="shuaxin">
              <img class="mapshua" src="../assets/img/ding/shua.png" alt="" />
              <i class="mapxin" @click="refresh(detail.订单号)">刷新</i>
            </div>
          </div>
        </div>

        <div class="dingyin">
          <div class="map" :class="{ jiamap: mapyin == true }" v-show="yin">
            <div id="container" :class="{ jiamapmain: mapyin == true }"></div>
          </div>
          <div class="map" :class="{ jiamap: mapyin == true }" v-show="mapyin">
            <div id="container2" :class="{ jiamapmain: mapyin == true }"></div>
          </div>

          <div class="mapxinxi" ref="zhanmap" v-show="mapyin">
            <div class="suomap">
              <img
                class="zhanimg"
                @click="zhankai"
                src="../assets/img/cd/fanhui.png"
                alt=""
                v-show="zhan"
              />
              <img
                class="fanimg"
                @click="suo"
                src="../assets/img/cd/fanhui.png"
                alt=""
                v-show="!zhan"
              />
            </div>

            <div class="mapxinxibox">
              <div class="order2">
                <span class="tiao"></span>
                订单号:{{ detail.订单号 }}
                <span class="take">{{ detail.服务状态 }}</span>
                <span class="take1" v-if="detail.rescue_style == 1"
                  >即时单</span
                >
                <span class="take2" v-if="detail.rescue_style == 2"
                  >预约单</span
                >
                <span class="take2" v-if="detail.rescue_style == 3"
                  >顺路单</span
                >
              </div>
              <div class="p1">
                <span class="title">合作单位&#12288;&nbsp;</span>
                <span class="title-main">{{ detail.合作单位 }}</span>
              </div>
              <div class="p1">
                <span class="title">服务项目&#12288;&nbsp;</span>
                <i class="title-main yanse">{{ detail.服务项目 }}</i>
              </div>
              <div class="p1">
                <span class="title">救援时间&#12288;&nbsp;</span>
                <span class="title-main">{{ detail.rescue_time }}</span>
              </div>

              <div class="p1" v-if="detail.appointment_time">
                <span class="title">预约时间&#12288;&nbsp;</span>
                <i class="title-main yanse">{{ detail.appointment_time }}</i>
              </div>

              <div class="diBox" :class="{ jiaBox: mapyin == true }">
                <label class="remTit">任务点&#12288;&#12288;&nbsp;</label>
                <div class="diTxt" :class="{ jiaTit: mapyin == true }">
                  {{ detail.事故地点 }}
                </div>
              </div>

              <div
                class="diBox"
                v-if="detail.目的地"
                :class="{ jiaBox: mapyin == true }"
              >
                <span class="remTit">目的地&#12288;&#12288;&nbsp;</span>
                <i class="diTxt" :class="{ jiaTit: mapyin == true }">
                  {{ detail.目的地 }}
                </i>
              </div>

              <div class="diBox" :class="{ jiaBox: mapyin == true }">
                <span class="remTit">协作商&#12288;&#12288;&nbsp;</span>

                <div
                  class="diTxt"
                  :class="{ jieTit: mapyin == true }"
                  v-if="detail.接单公司"
                >
                  {{ detail.接单公司 }}{{ detail.xzs }}
                  <button
                    @click="call(detail.xzs, detail.订单号)"
                    class="call"
                    v-show="emicreg != '' && detail.xzs != ''"
                  ></button>
                </div>
              </div>
              <div class="diBox" :class="{ jiaBox: mapyin == true }">
                <span class="remTit">技师&#12288;&#12288;&#12288;&nbsp;</span>

                <div
                  class="diTxt"
                  :class="{ jieTit: mapyin == true }"
                  v-if="detail.接单人员"
                >
                  {{ detail.接单人员 }}
                  <button
                    @click="call(detail.takemobile, detail.订单号)"
                    class="call"
                    v-show="emicreg != '' && detail.takemobile != ''"
                  ></button>
                </div>
              </div>
              <!-- <div class="diBox" :class="{ jiaBox: mapyin == true }">
                <span class="remTit">接单人&#12288;&#12288;&nbsp;</span>

                <div
                  class="diTxt"
                  :class="{ jieTit: mapyin == true }"
                  v-if="detail.接单人员"
                >
                  {{ detail.接单公司 }}/{{ detail.接单人员 }}
                  <button
                    @click="call(detail.takemobile, detail.订单号)"
                    class="call"
                    v-show="emicreg != '' && detail.takemobile != ''"
                  ></button>
                </div>
              </div> -->

              <div
                class="remBox"
                v-if="detail.订单备注"
                :class="{ jiabeiBox: mapyin == true }"
              >
                <span class="remTit">订单备注&#12288;&nbsp;</span>
                <span
                  class="remTxt"
                  :class="{ jiabeiTit: mapyin == true }"
                  v-html="detail.订单备注"
                ></span>
              </div>

              <div
                class="remBox"
                v-if="detail.spRemark"
                :class="{ jiabeiBox: mapyin == true }"
              >
                <span class="remTit">合作商备注&nbsp;</span>
                <span
                  class="remTxt"
                  v-html="detail.spRemark"
                  :class="{ jiabeiTit: mapyin == true }"
                ></span>
              </div>

              <div class="p21">
                <label class="title">车主信息&#12288;&nbsp;</label>
                <span class="title-main3" style="width: 350px"
                  >{{ detail.车主姓名 }}/{{ detail.手机号
                  }}<button
                    @click="call(detail.手机号, detail.订单号)"
                    class="call"
                    v-show="emicreg != ''"
                  ></button
                  ><span
                    style="
                      background-color: red;
                      color: white;
                      font-size: 14px;
                      padding: 1px 7px;
                      font-weight: normal;
                      margin-left: 10px;
                    "
                    v-if="detail.isBlack == 1"
                    ><img
                      src="../assets/img/black.png"
                      style="width: 14px; margin-right: 10px"
                    />黑名单 请注意</span
                  ></span
                >
              </div>
              <div class="p2">
                <label class="title">车牌&#12288;&#12288;&#12288;&nbsp;</label>
                <span class="title-main3">{{ detail.车牌号 }}</span>
              </div>
              <div class="p2">
                <label class="title">车架号&#12288;&#12288;&nbsp;</label>
                <span class="title-main3">{{ detail.车架号 }}</span>
              </div>

              <div class="p2">
                <label class="title">车型&#12288;&#12288;&#12288;&nbsp;</label>
                <span class="title-main3">{{ detail.cartype }} </span>
              </div>

              <div class="p2">
                <label class="title" v-if="detail.编号"
                  >编号&#12288;&#12288;&#12288;&nbsp;</label
                >
                <span class="title-main3" v-if="detail.编号" ref="jiahao">{{
                  detail.编号
                }}</span>
              </div>

              <!-- 下单人 -->
              <div
                class="hezuo"
                v-if="detail.givename"
                :class="{ jiap: mapyin == true }"
              >
                <p class="p2">
                  <span class="title">下单人&#12288;&#12288;&nbsp;</span>
                  <span class="title-main"
                    >{{ detail.givename }}/{{ detail.givemobile
                    }}<button
                      @click="call(detail.givemobile, detail.订单号)"
                      class="call"
                      v-show="emicreg != ''"
                    ></button
                  ></span>
                </p>
              </div>
              <!-- 发车人 -->
              <div
                class="hezuo"
                v-if="detail.name1"
                :class="{ jiap: mapyin == true }"
              >
                <p class="p2">
                  <span class="title">发车人&#12288;&#12288;&nbsp;</span>
                  <span class="title-main"
                    >{{ detail.name1 }}/{{ detail.tel1
                    }}<button
                      @click="call(detail.tel1, detail.订单号)"
                      class="call"
                      v-show="emicreg != ''"
                    ></button
                  ></span>
                </p>
              </div>
              <!-- 接车人 -->
              <div
                class="hezuo"
                v-if="detail.name2"
                :class="{ jiap: mapyin == true }"
              >
                <p class="p2">
                  <span class="title">接车人&#12288;&#12288;&nbsp;</span>
                  <span class="title-main"
                    >{{ detail.name2 }}/{{ detail.tel2
                    }}<button
                      @click="call(detail.tel2, detail.订单号)"
                      class="call"
                      v-show="emicreg != ''"
                    ></button
                  ></span>
                </p>
              </div>

              <!-- 业务员 -->
              <div
                class="hezuo"
                v-if="detail.saleman"
                :class="{ jiap: mapyin == true }"
              >
                <p class="p2">
                  <span class="title">业务员&#12288;&#12288;&nbsp;</span>
                  <span class="title-main"
                    >{{ detail.saleman
                    }}<button
                      @click="call(detail.salemanmobile, detail.订单号)"
                      class="call"
                      v-show="emicreg != ''"
                    ></button
                  ></span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="order" v-show="mapyin == false">
          <span class="tiao"></span>
          订单号:{{ detail.订单号 }}
          <span class="take">{{ detail.服务状态 }}</span>
          <span class="take1" v-if="detail.rescue_style == 1">即时单</span>
          <span class="take2" v-if="detail.rescue_style == 2">预约单</span>
          <span class="take2" v-if="detail.rescue_style == 3">顺路单</span>
        </div>
        <div class="order-main" v-show="mapyin == false">
          <div>
            <div class="hezuo">
              <div class="p1">
                <span class="title">合作单位&#12288;</span>
                <span class="title-main">{{ detail.合作单位 }}</span>
              </div>
              <div class="xiangmu">
                <span class="title">服务项目</span>
                <i class="title-main yanse">{{ detail.服务项目 }}</i>
              </div>
            </div>

            <div class="hezuo">
              <div class="p1">
                <span class="title">救援时间&#12288;</span>
                <span class="title-main">{{ detail.rescue_time }}</span>
              </div>
              <div class="xiangmu" v-if="detail.appointment_time">
                <span class="title">预约时间</span>
                <i class="title-main yanse">{{ detail.appointment_time }}</i>
              </div>
            </div>
          </div>
          <div>
            <div class="diBox">
              <label class="remTit">任务点&#12288;&#12288;</label>
              <div class="diTxt">
                {{ detail.事故地点 }}
              </div>
            </div>

            <div class="diBox" v-if="detail.目的地">
              <span class="remTit">目的地&#12288;&#12288;</span>
              <i class="diTxt">
                {{ detail.目的地 }}
              </i>
            </div>
          </div>

          <div class="receiving">
            <span class="title">协作商&#12288;&#12288;</span>
            <div class="title-main" v-if="detail.接单人员">
              {{ detail.接单公司 }}{{ detail.xzs }}
              <button
                @click="call(detail.xzs, detail.订单号)"
                class="call"
                v-show="emicreg != '' && detail.xzs != ''"
              ></button>
            </div>
          </div>
          <div class="receiving">
            <span class="title">技师&#12288;&#12288;&#12288;</span>
            <div class="title-main" v-if="detail.接单人员">
              {{ detail.接单人员 }}
              <button
                @click="call(detail.takemobile, detail.订单号)"
                class="call"
                v-show="emicreg != '' && detail.takemobile != ''"
              ></button>
            </div>
          </div>
          <!-- <div class="receiving">
            <span class="title">接单人&#12288;</span>
            <div class="title-main" v-if="detail.接单人员">
              {{ detail.接单公司 }}/{{ detail.接单人员 }}
              <button
                @click="call(detail.takemobile, detail.订单号)"
                class="call"
                v-show="emicreg != '' && detail.takemobile != ''"
              ></button>
            </div>
          </div> -->

          <div class="remBox" v-if="detail.订单备注">
            <span class="remTit">订单备注&#12288;</span>
            <span class="remTxt" v-html="detail.订单备注"></span>
          </div>

          <div class="remBox">
            <span class="remTit" v-if="detail.spRemark">合作商备注</span>
            <span class="remTxt" v-html="detail.spRemark"></span>
          </div>
        </div>
        <div class="owner" v-show="mapyin == false">
          <span class="tiao1"></span>
          车主信息
        </div>
        <div class="owner-main" v-show="mapyin == false">
          <!-- 车主信息/车牌号/手机号/编号 -->
          <div>
            <div class="hezuo">
              <div class="p2">
                <label class="title">车主信息</label>
                <span class="title-main"
                  >{{ detail.车主姓名 }}/{{ detail.手机号
                  }}<button
                    @click="call(detail.手机号, detail.订单号)"
                    class="call"
                    v-show="emicreg != ''"
                  ></button
                ></span>
              </div>
              <div class="xiangmu">
                <label class="title">车牌&#12288;&#12288;</label>
                <span class="title-main2">{{ detail.车牌号 }}</span>
              </div>
            </div>

            <div class="hezuo">
              <div class="p2">
                <label class="title">车型&#12288;&#12288;</label>
                <span class="title-main">{{ detail.cartype }} </span>
              </div>

              <div class="xiangmu">
                <label class="title" v-if="detail.编号"
                  >编号&#12288;&#12288;</label
                >
                <span class="title-main2" v-if="detail.编号" ref="jiahao">{{
                  detail.编号
                }}</span>
              </div>
            </div>
            <div class="hezuo">
              <div class="p2">
                <label class="title">车架号&#12288;</label>
                <span class="title-main">{{ detail.车架号 }} </span>
              </div>
            </div>
          </div>

          <!-- 发车接车人 -->
          <div>
            <!-- 下单人 -->
            <div class="hezuo" v-if="detail.givename">
              <p class="p2">
                <span class="title">下单人&#12288;</span>
                <span class="title-main"
                  >{{ detail.givename }}/{{ detail.givemobile
                  }}<button
                    @click="call(detail.givemobile, detail.订单号)"
                    class="call"
                    v-show="emicreg != ''"
                  ></button
                ></span>
              </p>
            </div>

            <!-- 发车人 -->
            <div class="hezuo" v-if="detail.name1">
              <p class="p2">
                <span class="title">发车人&#12288;</span>
                <span class="title-main"
                  >{{ detail.name1 }}/{{ detail.tel1
                  }}<button
                    @click="call(detail.tel1, detail.订单号)"
                    class="call"
                    v-show="emicreg != ''"
                  ></button
                ></span>
              </p>
            </div>

            <!-- 接车人 -->
            <div class="hezuo" v-if="detail.name2">
              <p class="p2">
                <span class="title">接车人&#12288;</span>
                <span class="title-main"
                  >{{ detail.name2 }}/{{ detail.tel2
                  }}<button
                    @click="call(detail.tel2, detail.订单号)"
                    class="call"
                    v-show="emicreg != ''"
                  ></button
                ></span>
              </p>
            </div>

            <!-- 业务员 -->
            <div class="hezuo" v-if="detail.saleman">
              <p class="p2">
                <span class="title">业务员&#12288;</span>
                <span class="title-main"
                  >{{ detail.saleman
                  }}<button
                    @click="call(detail.salemanmobile, detail.订单号)"
                    class="call"
                    v-show="emicreg != ''"
                  ></button
                ></span>
              </p>
            </div>
          </div>
        </div>

        <div class="btn" v-if="btnyin">
          <el-popover
            :visible="visible"
            placement="left"
            :width="400"
            trigger="click"
          >
            <div class="sharenav">共享价格</div>
            <div>
              <div class="name1">
                <label class="pricelabel">价格设置</label>
                <el-radio
                  v-model="gxchargeType"
                  label="1"
                  size="large"
                  @change="funGonggx"
                  >一口价</el-radio
                >
                <el-radio
                  v-model="gxchargeType"
                  label="2"
                  size="large"
                  @change="funGonggx"
                  >公里价</el-radio
                >
              </div>
              <div class="elect">
                <div class="elect1">
                  <input
                    class="elect-span1"
                    v-model="gxa"
                    placeholder="基准价"
                  />
                  <span class="elect-span2">元</span>
                </div>

                <div class="electbox" v-if="showYiKou">
                  <div class="elect2">
                    <input
                      class="elect-span1"
                      v-model="gxb"
                      placeholder="基准公里"
                    />
                    <span class="elect-span3">公里</span>
                  </div>
                  <div class="elect2">
                    <input
                      class="elect-span1"
                      v-model="gxc"
                      placeholder="超基准价"
                    />
                    <span class="elect-span4">元/公里</span>
                  </div>
                </div>
              </div>
              <div class="gaipricebtn">
                <button class="reset" @click="visible = false">取消</button>
                <button class="deter" @click="shareorder">确定</button>
              </div>
            </div>
            <template #reference>
              <button
                class="recycle"
                @click="visible = true"
                v-if="detail.canShare"
              >
                共享
              </button>
            </template>
          </el-popover>
          <el-popover
            :visible="visible1"
            placement="left"
            :width="400"
            trigger="click"
          >
            <div class="sharenav">订单回收</div>
            <div style="display: contents">
              <div
                style="
                  width: 60px;
                  float: left;
                  margin-left: 20px;
                  margin-right: 20px;
                  margin-top: 30px;
                  margin-bottom: 30px;
                  line-height: 32px;
                  text-align: right;
                "
              >
                原因
              </div>
              <div style="margin-top: 30px; margin-bottom: 30px">
                <el-select v-model="gp_reason" placeholder="请选择原因">
                  <el-option
                    v-for="item in gplist"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="gaipricebtn">
                <button class="reset" @click="visible1 = false">取消</button>
                <button
                  class="deter"
                  @click="recycleNew(detail.订单号)"
                  :disabled="gp_reason == '' ? true : false"
                  :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                >
                  确定
                </button>
              </div>
            </div>
            <template #reference>
              <button
                class="recycle"
                @click="visible1 = true"
                v-if="detail.canRecycle"
              >
                回收
              </button>
            </template>
          </el-popover>
          <button
            class="cancel"
            v-if="detail.可编辑"
            :plain="true"
            @click="edit(detail.订单号)"
          >
            修改
          </button>

          <button class="cancel" @click="dingbei(detail.订单号)">备注</button>

          <button class="cancel" v-if="detail.canCancel" @click="lish">
            取消
          </button>
        </div>

        <div class="paiList" :class="{ jiapai: mapyin == true }" v-if="!showKj">
          <div class="paiListNav" :class="{ paiNav: mapyin == true }">
            <input
              class="int"
              type="text"
              placeholder="请输入车牌号/帐号搜索"
              v-model="carNum"
            />
            <div class="paiBtn" style="float: right">
              <button
                class="deter"
                :class="{ detersou: mapyin == true }"
                @click="que"
              >
                搜索
              </button>
              <button
                class="reset"
                :class="{ resetsou: mapyin == true }"
                @click="reset"
              >
                重置
              </button>
            </div>
          </div>

          <table border="0" cellspacing="0" :class="{ paiNav: mapyin == true }">
            <thead>
              <th class="jiedan">接单</th>
              <th class="zhongduan" v-show="mapyin == true">终端</th>
              <th class="cartype" v-show="mapyin == true">车辆类型</th>
              <th class="information">技师信息</th>
              <th class="juli">距离</th>
              <th class="operation">操作</th>
            </thead>
            <tbody v-if="mapyin == true">
              <tr v-for="(data, index) in driverListMap" :key="index">
                <td class="jiedanTit">{{ data.type }}</td>
                <td
                  class="jzhongduanTit"
                  v-show="mapyin == true && data.is_warning == 0"
                >
                  {{ data.gps_up_time }}
                </td>
                <td
                  class="jzhongduanTitRed"
                  style="color: red"
                  v-show="mapyin == true && data.is_warning == 1"
                >
                  {{ data.gps_up_time }}
                </td>

                <td class="jzhongduanTit" v-show="mapyin == true">
                  {{ data.carstyle }}
                </td>

                <td class="informationTit">
                  <img
                    class="jiimg"
                    v-if="data.grade == 3"
                    :src="gold"
                    alt=""
                  />
                  <img
                    class="jiimg"
                    v-if="data.grade == 2"
                    :src="silver"
                    alt=""
                  />
                  <img
                    class="jiimg"
                    v-if="data.grade == 1"
                    :src="bronze"
                    alt=""
                  />
                  {{ data.companyname }} {{ data.nickname }}
                  <el-popover placement="top" :width="130" trigger="hover">
                    <div class="haomaMain">
                      <label class="haomaTit">工作:</label>
                      <span class="haomaTel">{{ data.tel }}</span>
                      <img
                        v-if="emicreg != ''"
                        @click="call(data.tel, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>
                    <div class="haomaMain" v-if="data.tel1 != '无'">
                      <label class="haomaTit">私人:</label>
                      <span class="haomaTel">{{ data.tel1 }}</span>
                      <img
                        v-if="emicreg != '' && data.tel1 != '无'"
                        @click="call(data.tel1, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>
                    <div class="haomaMain" v-if="data.tel2 != '无'">
                      <label class="haomaTit">紧急:</label>
                      <span class="haomaTel">{{ data.tel2 }}</span>
                      <img
                        v-if="emicreg != '' && data.tel1 != '无'"
                        @click="call(data.tel2, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>

                    <template #reference>
                      <div class="hovertel">{{ data.tel }}</div>
                    </template>
                  </el-popover>

                  <el-popover placement="right" :width="605" trigger="hover">
                    <template #reference>
                      <img
                        class="yaImg"
                        src="../assets/img/index/ya.png"
                        alt=""
                        v-if="data.total_order.length > 0"
                      />
                    </template>
                    <el-table :data="data.total_order">
                      <el-table-column width="105" label="服务类型">
                        <template #default="scope">
                          <div v-html="scope.row.service1"></div>
                          <div class="zhuangtai3">{{ scope.row.status }}</div>
                        </template>
                      </el-table-column>

                      <el-table-column
                        width="250"
                        property="address"
                        label="任务点"
                      />
                      <el-table-column
                        width="250"
                        property="destination"
                        label="目的地"
                      />
                    </el-table>
                  </el-popover>

                  <div
                    :class="
                      data.tt === '休息'
                        ? 'zhuangtai2'
                        : data.tt === ''
                        ? ''
                        : 'zhuangtai1'
                    "
                  >
                    <span class="jiao">今</span>
                    {{ data.tt }}
                  </div>
                  <div 
                  :class="
                      data.tt1 === '休息'
                        ? 'zhuangtai2'
                        : data.tt1 === ''
                        ? ''
                        : 'zhuangtai1'
                    ">
                    <span class="jiao2">明</span>
                    {{ data.tt1 }}
                  </div>
                  <div class="newmode" v-if="data.is_new == 1 && data.is_np == 0">新模式</div>
                </td>
                <td class="juliTit">
                  <img
                    class="xianImg"
                    src="../assets/img/cd/fu.png"
                    alt=""
                    v-if="data.status == 3"
                    :class="{ taiImg: mapyin == true }"
                  />
                  <img
                    class="xianImg"
                    src="../assets/img/cd/xian.png"
                    alt=""
                    v-if="data.status == 2"
                    :class="{ taiImg: mapyin == true }"
                  />
                  <img
                    class="xianImg"
                    src="../assets/img/cd/ting.png"
                    alt=""
                    v-if="data.status == 1"
                    :class="{ taiImg: mapyin == true }"
                  />
                  {{ data.distance }}公里
                </td>
                <td class="operationTit">
                  <span
                    class="operBtn"
                    @click="xiuprice(index, data)"
                    v-if="this.detail.接单人员 == ''"
                    >指派</span
                  >
                  <span
                    class="operBtn"
                    @click="xiuprice(index, data)"
                    v-if="this.detail.接单人员 != '' && data.is_take == 0"
                    >改派</span
                  >
                  <span
                    class="operBtn2"
                    @click="xiuprice(index, data)"
                    v-if="
                      this.detail.接单人员 != '' &&
                      data.is_take == 1 &&
                      data.is_self == 0
                    "
                    >改价</span
                  >

                  <span
                    class="operBtn2"
                    v-if="
                      this.detail.接单人员 != '' &&
                      data.is_take == 1 &&
                      data.is_self == 1 &&
                      data.is_op == 0
                    "
                    >当前</span
                  >

                  <span
                    class="operBtn2"
                    @click="xiuprice(index, data)"
                    v-if="
                      this.detail.接单人员 != '' &&
                      data.is_take == 1 &&
                      data.is_self == 1 &&
                      data.is_op == 1
                    "
                    >改价</span
                  >

                  <div class="gaiprice" v-show="index == gaiindex">
                    <div class="gaipricetit">
                      流转价格
                      <div class="btns">
                        <button
                          class="jishi"
                          :class="jishi == true ? 'color' : ''"
                          @click="typejishi"
                        >
                          即时
                        </button>
                        <button
                          class="yuyue"
                          :class="yuyue == true ? 'color' : ''"
                          @click="typeyuyue"
                        >
                          预约
                        </button>
                        <button
                          class="shunlu"
                          :class="shunlu == true ? 'color' : ''"
                          @click="typeshunlu"
                        >
                          顺路
                        </button>
                      </div>
                    </div>
                    <div class="name1" v-if="is_gaip">
                      <div
                        style="
                          width: 60px;
                          float: left;
                          margin-right: 20px;
                          line-height: 32px;
                          text-align: right;
                        "
                      >
                        改派原因
                      </div>
                      <div>
                        <el-select v-model="gp_reason" placeholder="请选择原因">
                          <el-option
                            v-for="item in gplist"
                            :key="item.label"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="name1">
                      <label class="pricelabel">价格设置</label>
                      <el-radio
                        v-model="chargeType"
                        label="1"
                        size="large"
                        @change="funGong"
                        >一口价</el-radio
                      >
                      <el-radio
                        v-model="chargeType"
                        label="2"
                        size="large"
                        @change="funGong"
                        >公里价</el-radio
                      >
                    </div>
                    <!-- 即时 -->
                    <div class="elect">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          v-model="a"
                          placeholder="基准价"
                        />
                        <span class="elect-span2">元</span>
                      </div>

                      <div class="electbox" v-if="showYiKou">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="b"
                            placeholder="基准公里"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="c"
                            placeholder="超基准价"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                      </div>
                    </div>
                    <div class="gaipricebtn">
                      <button class="reset" @click="gaicancel">取消</button>
                      <button class="deter" @click="gaiseal" v-if="!is_gaip">
                        确定
                      </button>
                      <button
                        class="deter"
                        @click="gaisealg"
                        v-if="is_gaip == true"
                        :disabled="gp_reason == '' ? true : false"
                        :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                      >
                        确定
                      </button>
                    </div>
                  </div>
                  <div class="gaiprice" v-show="index == gaiindex3">
                    <div class="gaipricetit">外协价格</div>
                    <div class="name1" v-if="is_gaip">
                      <div
                        style="
                          width: 60px;
                          float: left;
                          margin-right: 20px;
                          line-height: 32px;
                          text-align: right;
                        "
                      >
                        改派原因
                      </div>
                      <div>
                        <el-select v-model="gp_reason" placeholder="请选择原因">
                          <el-option
                            v-for="item in gplist"
                            :key="item.label"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="name1">
                      <label class="pricelabel">价格设置</label>
                      <el-radio
                        v-model="opchargeType"
                        label="1"
                        size="large"
                        @change="opfunGong"
                        >一口价</el-radio
                      >
                      <el-radio
                        v-model="opchargeType"
                        label="2"
                        size="large"
                        @change="opfunGong"
                        >公里价</el-radio
                      >
                    </div>
                    <!-- 即时 -->
                    <div class="elect">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          v-model="opa"
                          placeholder="基准价"
                        />
                        <span class="elect-span2">元</span>
                      </div>

                      <div class="electbox" v-if="showOpYiKou">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="opb"
                            placeholder="基准公里"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="opc"
                            placeholder="超基准价"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                      </div>
                    </div>
                    <div class="gaipricebtn">
                      <button class="reset" @click="gaicancel3">取消</button>
                      <button
                        class="deter"
                        @click="gaiseal3"
                        v-if="is_gaip == false"
                      >
                        确定
                      </button>
                      <button
                        class="deter"
                        @click="gaiseal3g"
                        v-if="is_gaip == true"
                        :disabled="gp_reason == '' ? true : false"
                        :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                      >
                        确定
                      </button>
                    </div>
                  </div>

                  <div
                    class="gaiprice4"
                    style="height: 200px !important"
                    v-show="index == gaiindex4"
                  >
                    <div class="gaipricetit">确认改派 - {{ gp_name }}</div>
                    <div class="name1" v-if="is_gaip" style="margin-top: 40px">
                      <div
                        style="
                          width: 60px;
                          float: left;
                          margin-right: 20px;
                          line-height: 32px;
                          text-align: right;
                        "
                      >
                        改派原因
                      </div>
                      <div>
                        <el-select v-model="gp_reason" placeholder="请选择原因">
                          <el-option
                            v-for="item in gplist"
                            :key="item.label"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="gaipricebtn">
                      <button class="reset" @click="gaiindex4 = -1">
                        取消
                      </button>
                      <button
                        class="deter"
                        @click="gaipaigo(detail.订单号, gp_uid)"
                        :disabled="gp_reason == '' ? true : false"
                        :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                      >
                        确定
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="mapyin == false">
              <tr v-for="(data, index) in driverList" :key="index">
                <td class="jiedanTit">{{ data.type }}</td>
                <td class="jzhongduanTit" v-show="mapyin == true">未绑定</td>
                <td class="informationTit2">
                  {{ data.companyname }} {{ data.nickname }}
                  <el-popover placement="top" :width="130" trigger="hover">
                    <div class="haomaMain">
                      <label class="haomaTit">工作:</label>
                      <span class="haomaTel">{{ data.tel }}</span>
                      <img
                        v-if="emicreg != ''"
                        @click="call(data.tel, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>
                    <div class="haomaMain" v-if="data.tel1 != '无'">
                      <label class="haomaTit">私人:</label>
                      <span class="haomaTel">{{ data.tel1 }}</span>
                      <img
                        v-if="emicreg != '' && data.tel1 != '无'"
                        @click="call(data.tel1, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>
                    <div class="haomaMain" v-if="data.tel2 != '无'">
                      <label class="haomaTit">紧急:</label>
                      <span class="haomaTel">{{ data.tel2 }}</span>
                      <img
                        v-if="emicreg != '' && data.tel1 != '无'"
                        @click="call(data.tel2, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>
                    <template #reference>
                      <div class="hovertel">{{ data.tel }}</div>
                    </template>
                  </el-popover>

                  <el-popover placement="left" :width="500" trigger="hover">
                    <template #reference>
                      <img
                        class="yaImg"
                        src="../assets/img/index/ya.png"
                        alt=""
                        v-if="data.total_order.length > 0"
                      />
                    </template>
                    <el-table :data="data.total_order">
                      <el-table-column width="105" label="服务类型">
                        <template #default="scope">
                          <div v-html="scope.row.service1"></div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="200"
                        property="address"
                        label="任务点"
                      />
                      <el-table-column
                        width="200"
                        property="destination"
                        label="目的地"
                      />
                    </el-table>
                  </el-popover>
                </td>
                <td class="juliTit">
                  <img
                    class="xianImg"
                    src="../assets/img/cd/fu.png"
                    alt=""
                    v-if="data.status == 3"
                    :class="{ taiImg: mapyin == true }"
                  />
                  <img
                    class="xianImg"
                    src="../assets/img/cd/xian.png"
                    alt=""
                    v-if="data.status == 2"
                    :class="{ taiImg: mapyin == true }"
                  />
                  <img
                    class="xianImg"
                    src="../assets/img/cd/ting.png"
                    alt=""
                    v-if="data.status == 1"
                    :class="{ taiImg: mapyin == true }"
                  />
                  {{ data.distance }}公里
                </td>
                <td class="operationTit">
                  <span
                    class="operBtn"
                    @click="xiuprice2(index, data)"
                    v-if="this.detail.接单人员 == ''"
                    >指派</span
                  >
                  <!-- <span
                    class="operBtn"
                    @click="dispatch(this.detail.订单号, data.uid)"
                    v-if="this.detail.接单人员 == ''"
                    >指派</span
                  > -->
                  <span
                    class="operBtn"
                    @click="xiuprice2(index, data)"
                    v-if="this.detail.接单人员 != '' && data.is_take == 0"
                    >改派</span
                  >
                  <span
                    class="operBtn2"
                    @click="xiuprice2(index, data)"
                    v-if="
                      this.detail.接单人员 != '' &&
                      data.is_take == 1 &&
                      data.is_self == 0
                    "
                    >改价</span
                  >

                  <span
                    class="operBtn2"
                    v-if="
                      this.detail.接单人员 != '' &&
                      data.is_take == 1 &&
                      data.is_self == 1
                    "
                    >当前</span
                  >

                  <div class="gaiprice2" v-show="index == gaiindex11">
                    <div class="gaipricetit">
                      流转价格
                      <div class="btns">
                        <button
                          class="jishi"
                          :class="jishi == true ? 'color' : ''"
                          @click="typejishi"
                        >
                          即时
                        </button>
                        <button
                          class="yuyue"
                          :class="yuyue == true ? 'color' : ''"
                          @click="typeyuyue"
                        >
                          预约
                        </button>
                        <button
                          class="shunlu"
                          :class="shunlu == true ? 'color' : ''"
                          @click="typeshunlu"
                        >
                          顺路
                        </button>
                      </div>
                    </div>
                    <div class="name1" v-if="is_gaip">
                      <div
                        style="
                          width: 60px;
                          float: left;
                          margin-right: 20px;
                          line-height: 32px;
                          text-align: right;
                        "
                      >
                        改派原因
                      </div>
                      <div>
                        <el-select v-model="gp_reason" placeholder="请选择原因">
                          <el-option
                            v-for="item in gplist"
                            :key="item.label"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="name1">
                      <label class="pricelabel">价格设置</label>
                      <el-radio
                        v-model="chargeType"
                        label="1"
                        size="large"
                        @change="funGong"
                        >一口价</el-radio
                      >
                      <el-radio
                        v-model="chargeType"
                        label="2"
                        size="large"
                        @change="funGong"
                        >公里价</el-radio
                      >
                    </div>
                    <!-- 即时 -->
                    <div class="elect">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          v-model="a"
                          placeholder="基准价"
                        />
                        <span class="elect-span2">元</span>
                      </div>

                      <div class="electbox" v-if="showYiKou">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="b"
                            placeholder="基准公里"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="c"
                            placeholder="超基准价"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                      </div>
                    </div>
                    <div class="gaipricebtn">
                      <button class="reset" @click="gaicancel">取消</button>
                      <button class="deter" @click="gaiseal" v-if="!is_gaip">
                        确定
                      </button>
                      <button
                        class="deter"
                        @click="gaisealg"
                        v-if="is_gaip == true"
                        :disabled="gp_reason == '' ? true : false"
                        :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                      >
                        确定
                      </button>
                    </div>
                  </div>
                  <div class="gaiprice2" v-show="index == gaiindex13">
                    <div class="gaipricetit">外协价格</div>
                    <div class="name1" v-if="is_gaip">
                      <div
                        style="
                          width: 60px;
                          float: left;
                          margin-right: 20px;
                          line-height: 32px;
                          text-align: right;
                        "
                      >
                        改派原因
                      </div>
                      <div>
                        <el-select v-model="gp_reason" placeholder="请选择原因">
                          <el-option
                            v-for="item in gplist"
                            :key="item.label"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="name1">
                      <label class="pricelabel">价格设置</label>
                      <el-radio
                        v-model="opchargeType"
                        label="1"
                        size="large"
                        @change="opfunGong"
                        >一口价</el-radio
                      >
                      <el-radio
                        v-model="opchargeType"
                        label="2"
                        size="large"
                        @change="opfunGong"
                        >公里价</el-radio
                      >
                    </div>
                    <!-- 即时 -->
                    <div class="elect">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          v-model="opa"
                          placeholder="基准价"
                        />
                        <span class="elect-span2">元</span>
                      </div>

                      <div class="electbox" v-if="showOpYiKou">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="opb"
                            placeholder="基准公里"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="opc"
                            placeholder="超基准价"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                      </div>
                    </div>
                    <div class="gaipricebtn">
                      <button class="reset" @click="gaicancel3">取消</button>
                      <button
                        class="deter"
                        @click="gaiseal3"
                        v-if="is_gaip == false"
                      >
                        确定
                      </button>
                      <button
                        class="deter"
                        @click="gaiseal3g"
                        v-if="is_gaip == true"
                        :disabled="gp_reason == '' ? true : false"
                        :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                      >
                        确定
                      </button>
                    </div>
                  </div>

                  <div class="gaiprice2" v-show="index == gaiindex14">
                    <div class="gaipricetit">确认改派 - {{ gp_name }}</div>
                    <div class="name1" v-if="is_gaip" style="margin-top: 40px">
                      <div
                        style="
                          width: 60px;
                          float: left;
                          margin-right: 20px;
                          line-height: 32px;
                          text-align: right;
                        "
                      >
                        改派原因
                      </div>
                      <div>
                        <el-select v-model="gp_reason" placeholder="请选择原因">
                          <el-option
                            v-for="item in gplist"
                            :key="item.label"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="gaipricebtn">
                      <button class="reset" @click="gaiindex14 = -1">
                        取消
                      </button>
                      <button
                        class="deter"
                        @click="gaipaigo(detail.订单号, gp_uid)"
                        :disabled="gp_reason == '' ? true : false"
                        :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                      >
                        确定
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <div class="paiList" :class="{ jiapai: mapyin == true }" v-if="showKj">
          <div class="paiListNav" :class="{ paiNav: mapyin == true }">
            <div style="float:left;width:40%;">
                <div :class="newtype == 1?'newtype1':'newtype2'" @click="changenewtype(1)">就近派单</div>
                <div :class="newtype == 2?'newtype1':'newtype2'" @click="changenewtype(2)">压单派单</div>
                <div :class="newtype == 3?'newtype1':'newtype2'" @click="changenewtype(3)">协作商</div>
            </div>
            <div style="float:right;width:60%; margin-bottom:10px;"> <div class="paiBtn" style="float: right">
              <button
                class="deter"
                :class="{ detersou: mapyin == true }"
                @click="que"
              >
                搜索
              </button>
              <button
                class="reset"
                :class="{ resetsou: mapyin == true }"
                @click="reset"
              >
                重置
              </button>
            </div>
            <input
              class="int"
              type="text"
              style="float:right;"
              placeholder="请输入车牌号/帐号搜索"
              v-model="carNum"
            /></div>
           
           
          </div>

          <table border="0" cellspacing="0" :class="{ paiNav: mapyin == true }">
            <thead>
              <th class="a1">技师信息</th>
              <th class="a2" v-if="newtype != 3">标注</th>
              <th class="a3">车辆类型</th>
              <th class="a4" v-if="newtype != 3">终端定位</th>
              <th class="a5" v-if="newtype == 3">定位方式</th>
              <th class="a6" v-if="newtype == 3">定位时间</th>
              <th class="a7">技师状态</th>
              <th class="a8">当前任务</th>
              <th class="a9">{{newtype==2?'完成后前往':'立即前往'}}</th>
              <th class="a10">操作</th>
            </thead>
            <tbody>
              <tr v-for="(data, index) in driverListMap" :key="index">
                <td class="a1t"> <img
                    class="jiimg"
                    v-if="data.grade == 3 && newtype == 3"
                    :src="gold"
                    alt=""
                  />
                  <img
                    class="jiimg"
                    v-if="data.grade == 2 && newtype == 3"
                    :src="silver"
                    alt=""
                  />
                  <img
                    class="jiimg"
                    v-if="data.grade == 1 && newtype == 3"
                    :src="bronze"
                    alt=""
                  />{{newtype == 3?data.companyname:''}} {{data.nickname}} {{ data.tel }}</td>
                <td
                  class="a2t"
                  v-if="newtype != 3"
                >
                <div
                    :class="
                      data.tt === '休息'
                        ? 'zhuangtai22'
                        : data.tt === ''
                        ? ''
                        : 'zhuangtai12'
                    "
                  >
                    <span class="jiao">今</span>
                    {{ data.tt }}
                  </div>
                  <div 
                    :class="
                      data.tt1 === '休息'
                        ? 'zhuangtai22'
                        : data.tt1 === ''
                        ? ''
                        : 'zhuangtai12'
                    ">
                    <span class="jiao2">明</span>
                    {{ data.tt1 }}
                  </div>
                  <div class="newmode1" v-if="data.is_new == 1 && data.is_np == 0">新模式</div>
                  <el-popover placement="top" :width="80" trigger="hover" v-if="data.is_np == 1">
                    <div style="text-align: center" >
                      <div>续航里程</div>
                      <div style="color:#27c09c">{{data.np_info.range }}公里</div>
                    </div>
                    <template #reference>      
                      <div style="width:90px; float:left;">
                        <div style="float:left;width:50px;margin-left:5px;background-color: #27c09c;padding: 1px 0px;color: #fff;font-size:12px;">新能源</div><div v-if="data.is_np == 1" style="float:left;width:30px;color:#27c09c;padding: 0px 0px; border: 1px solid #27c09c; border-left:none;font-size:12px;">{{data.np_info.soc }}</div>
                      </div>
                    </template>
                  </el-popover>
                  
                </td>
                <td
                  class="a3t"
                >
                {{ data.carstyle }}
                </td>
                <td
                  class="a4t"
                  v-if="newtype != 3"
                >
                <span :style="data.is_warning == 1?'color:red;':''">{{ data.gps_up_time }}</span>
                </td>
                <td
                  class="a5t"
                  v-if="newtype == 3"
                >
                {{ data.type }}
                </td>
                <td
                  class="a6t"
                  v-if="newtype == 3"
                >
                <span :style="data.is_warning == 1?'color:red;':''">{{ data.last_time }}</span>
                </td>
                <td
                  class="a7t"
                >
                <span :style="data.status == 3?'color:blue':(data.status == 2?'color:green':'color:gray')">{{ data.status == 3 ?'已接单':(data.status == 2?'空闲':'停止接单')}}</span>
                </td>
                <td
                  class="a8t"
                >
                <el-popover placement="left" :width="605" trigger="hover" v-if="data.total_order.length > 0">
                    <template #reference>
                      <span style="background-color: #d9e4ff;
  padding: 1px 16px;
  color: #2c68ff;">{{data.total_order.length}}</span>
                    </template>
                    <el-table :data="data.total_order">
                      <el-table-column width="105" label="服务类型">
                        <template #default="scope">
                          <div v-html="scope.row.service1"></div>
                          <div class="zhuangtai3">{{ scope.row.status }}</div>
                        </template>
                      </el-table-column>

                      <el-table-column
                        width="250"
                        property="address"
                        label="任务点"
                      />
                      <el-table-column
                        width="250"
                        property="destination"
                        label="目的地"
                      />
                    </el-table>
                  </el-popover>
                  <span v-else> 0 </span>
                </td>
                <td
                  class="a9t"
                >
                <div style="float:left;width:40%;">{{data.distance}}公里</div>
                <div style="float:left;width:60%;">{{data.time }}</div>
                </td>
                <!--<td
                  class="jzhongduanTitRed"
                  style="color: red"
                  v-show="mapyin == true && data.is_warning == 1"
                >
                  {{ data.gps_up_time }}
                </td>

                <td class="jzhongduanTit" v-show="mapyin == true">
                  {{ data.carstyle }}
                </td>

                <td class="informationTit">
                  <img
                    class="jiimg"
                    v-if="data.grade == 3"
                    :src="gold"
                    alt=""
                  />
                  <img
                    class="jiimg"
                    v-if="data.grade == 2"
                    :src="silver"
                    alt=""
                  />
                  <img
                    class="jiimg"
                    v-if="data.grade == 1"
                    :src="bronze"
                    alt=""
                  />
                  {{ data.companyname }} {{ data.nickname }}
                  <el-popover placement="top" :width="130" trigger="hover">
                    <div class="haomaMain">
                      <label class="haomaTit">工作:</label>
                      <span class="haomaTel">{{ data.tel }}</span>
                      <img
                        v-if="emicreg != ''"
                        @click="call(data.tel, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>
                    <div class="haomaMain" v-if="data.tel1 != '无'">
                      <label class="haomaTit">私人:</label>
                      <span class="haomaTel">{{ data.tel1 }}</span>
                      <img
                        v-if="emicreg != '' && data.tel1 != '无'"
                        @click="call(data.tel1, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>
                    <div class="haomaMain" v-if="data.tel2 != '无'">
                      <label class="haomaTit">紧急:</label>
                      <span class="haomaTel">{{ data.tel2 }}</span>
                      <img
                        v-if="emicreg != '' && data.tel1 != '无'"
                        @click="call(data.tel2, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>

                    <template #reference>
                      <div class="hovertel">{{ data.tel }}</div>
                    </template>
                  </el-popover>

                  <el-popover placement="right" :width="605" trigger="hover">
                    <template #reference>
                      <img
                        class="yaImg"
                        src="../assets/img/index/ya.png"
                        alt=""
                        v-if="data.total_order.length > 0"
                      />
                    </template>
                    <el-table :data="data.total_order">
                      <el-table-column width="105" label="服务类型">
                        <template #default="scope">
                          <div v-html="scope.row.service1"></div>
                          <div class="zhuangtai3">{{ scope.row.status }}</div>
                        </template>
                      </el-table-column>

                      <el-table-column
                        width="250"
                        property="address"
                        label="任务点"
                      />
                      <el-table-column
                        width="250"
                        property="destination"
                        label="目的地"
                      />
                    </el-table>
                  </el-popover>

                  <div
                    :class="
                      data.tt === '休息'
                        ? 'zhuangtai2'
                        : data.tt === ''
                        ? ''
                        : 'zhuangtai1'
                    "
                  >
                    <span class="jiao">今</span>
                    {{ data.tt }}
                  </div>
                  <div 
                  :class="
                      data.tt1 === '休息'
                        ? 'zhuangtai2'
                        : data.tt1 === ''
                        ? ''
                        : 'zhuangtai1'
                    ">
                    <span class="jiao2">明</span>
                    {{ data.tt1 }}
                  </div>
                  <div class="newmode" v-if="data.is_new == 1">新模式</div>
                </td>
                <td class="juliTit">
                  <img
                    class="xianImg"
                    src="../assets/img/cd/fu.png"
                    alt=""
                    v-if="data.status == 3"
                    :class="{ taiImg: mapyin == true }"
                  />
                  <img
                    class="xianImg"
                    src="../assets/img/cd/xian.png"
                    alt=""
                    v-if="data.status == 2"
                    :class="{ taiImg: mapyin == true }"
                  />
                  <img
                    class="xianImg"
                    src="../assets/img/cd/ting.png"
                    alt=""
                    v-if="data.status == 1"
                    :class="{ taiImg: mapyin == true }"
                  />
                  {{ data.distance }}公里
                </td>-->
                <td class="operationTit">
                  <el-popover placement="top" :width="130" trigger="hover">
                    <div class="haomaMain">
                      <label class="haomaTit">工作:</label>
                      <span class="haomaTel">{{ data.tel }}</span>
                      <img
                        v-if="emicreg != ''"
                        @click="call(data.tel, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>
                    <div class="haomaMain" v-if="data.tel1 != '无'">
                      <label class="haomaTit">私人:</label>
                      <span class="haomaTel">{{ data.tel1 }}</span>
                      <img
                        v-if="emicreg != '' && data.tel1 != '无'"
                        @click="call(data.tel1, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>
                    <div class="haomaMain" v-if="data.tel2 != '无'">
                      <label class="haomaTit">紧急:</label>
                      <span class="haomaTel">{{ data.tel2 }}</span>
                      <img
                        v-if="emicreg != '' && data.tel1 != '无'"
                        @click="call(data.tel2, detail.订单号)"
                        class="haomaImg"
                        src="../assets/img/index/call.png"
                        alt=""
                      />
                    </div>

                    <template #reference>
                      <span style="float:left; width:55px;padding:1px 5px; background:#E5F9F1;color:#5FD9A8; margin-left:10%;">联系他</span>
                    </template>
                  </el-popover>
                  <span
                    class="operBtnX"
                    @click="xiuprice(index, data)"
                    v-if="this.detail.接单人员 == ''"
                    >指派</span
                  >
                  <span
                    class="operBtnX"
                    @click="xiuprice(index, data)"
                    v-if="this.detail.接单人员 != '' && data.is_take == 0"
                    >改派</span
                  >
                  <span
                    class="operBtn2X"
                    @click="xiuprice(index, data)"
                    v-if="
                      this.detail.接单人员 != '' &&
                      data.is_take == 1 &&
                      data.is_self == 0
                    "
                    >改价</span
                  >

                  <span
                    class="operBtn2X"
                    v-if="
                      this.detail.接单人员 != '' &&
                      data.is_take == 1 &&
                      data.is_self == 1 &&
                      data.is_op == 0
                    "
                    >当前</span
                  >

                  <span
                    class="operBtn2X"
                    @click="xiuprice(index, data)"
                    v-if="
                      this.detail.接单人员 != '' &&
                      data.is_take == 1 &&
                      data.is_self == 1 &&
                      data.is_op == 1
                    "
                    >改价</span
                  >

                  <div class="gaiprice" v-show="index == gaiindex">
                    <div class="gaipricetit">
                      流转价格
                      <div class="btns">
                        <button
                          class="jishi"
                          :class="jishi == true ? 'color' : ''"
                          @click="typejishi"
                        >
                          即时
                        </button>
                        <button
                          class="yuyue"
                          :class="yuyue == true ? 'color' : ''"
                          @click="typeyuyue"
                        >
                          预约
                        </button>
                        <button
                          class="shunlu"
                          :class="shunlu == true ? 'color' : ''"
                          @click="typeshunlu"
                        >
                          顺路
                        </button>
                      </div>
                    </div>
                    <div class="name1" v-if="is_gaip">
                      <div
                        style="
                          width: 60px;
                          float: left;
                          margin-right: 20px;
                          line-height: 32px;
                          text-align: right;
                        "
                      >
                        改派原因
                      </div>
                      <div>
                        <el-select v-model="gp_reason" placeholder="请选择原因">
                          <el-option
                            v-for="item in gplist"
                            :key="item.label"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="name1">
                      <label class="pricelabel">价格设置</label>
                      <el-radio
                        v-model="chargeType"
                        label="1"
                        size="large"
                        @change="funGong"
                        >一口价</el-radio
                      >
                      <el-radio
                        v-model="chargeType"
                        label="2"
                        size="large"
                        @change="funGong"
                        >公里价</el-radio
                      >
                    </div>
                    <!-- 即时 -->
                    <div class="elect">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          v-model="a"
                          placeholder="基准价"
                        />
                        <span class="elect-span2">元</span>
                      </div>

                      <div class="electbox" v-if="showYiKou">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="b"
                            placeholder="基准公里"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="c"
                            placeholder="超基准价"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                      </div>
                    </div>
                    <div class="gaipricebtn">
                      <button class="reset" @click="gaicancel">取消</button>
                      <button class="deter" @click="gaiseal" v-if="!is_gaip">
                        确定
                      </button>
                      <button
                        class="deter"
                        @click="gaisealg"
                        v-if="is_gaip == true"
                        :disabled="gp_reason == '' ? true : false"
                        :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                      >
                        确定
                      </button>
                    </div>
                  </div>
                  <div class="gaiprice" v-show="index == gaiindex3">
                    <div class="gaipricetit">外协价格</div>
                    <div class="name1" v-if="is_gaip">
                      <div
                        style="
                          width: 60px;
                          float: left;
                          margin-right: 20px;
                          line-height: 32px;
                          text-align: right;
                        "
                      >
                        改派原因
                      </div>
                      <div>
                        <el-select v-model="gp_reason" placeholder="请选择原因">
                          <el-option
                            v-for="item in gplist"
                            :key="item.label"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="name1">
                      <label class="pricelabel">价格设置</label>
                      <el-radio
                        v-model="opchargeType"
                        label="1"
                        size="large"
                        @change="opfunGong"
                        >一口价</el-radio
                      >
                      <el-radio
                        v-model="opchargeType"
                        label="2"
                        size="large"
                        @change="opfunGong"
                        >公里价</el-radio
                      >
                    </div>
                    <!-- 即时 -->
                    <div class="elect">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          v-model="opa"
                          placeholder="基准价"
                        />
                        <span class="elect-span2">元</span>
                      </div>

                      <div class="electbox" v-if="showOpYiKou">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="opb"
                            placeholder="基准公里"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            v-model="opc"
                            placeholder="超基准价"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                      </div>
                    </div>
                    <div class="gaipricebtn">
                      <button class="reset" @click="gaicancel3">取消</button>
                      <button
                        class="deter"
                        @click="gaiseal3"
                        v-if="is_gaip == false"
                      >
                        确定
                      </button>
                      <button
                        class="deter"
                        @click="gaiseal3g"
                        v-if="is_gaip == true"
                        :disabled="gp_reason == '' ? true : false"
                        :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                      >
                        确定
                      </button>
                    </div>
                  </div>

                  <div
                    class="gaiprice4"
                    style="height: 200px !important"
                    v-show="index == gaiindex4"
                  >
                    <div class="gaipricetit">确认改派 - {{ gp_name }}</div>
                    <div class="name1" v-if="is_gaip" style="margin-top: 40px">
                      <div
                        style="
                          width: 60px;
                          float: left;
                          margin-right: 20px;
                          line-height: 32px;
                          text-align: right;
                        "
                      >
                        改派原因
                      </div>
                      <div>
                        <el-select v-model="gp_reason" placeholder="请选择原因">
                          <el-option
                            v-for="item in gplist"
                            :key="item.label"
                            :label="item.label"
                            :value="item.label"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="gaipricebtn">
                      <button class="reset" @click="gaiindex4 = -1">
                        取消
                      </button>
                      <button
                        class="deter"
                        @click="gaipaigo(detail.订单号, gp_uid)"
                        :disabled="gp_reason == '' ? true : false"
                        :style="gp_reason == '' ? 'background: #edf2fc;' : ''"
                      >
                        确定
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <rescue-edit
    :isshowedit="showedit"
    :rescueIdx="xiugaiidx"
    @hide="hide"
  ></rescue-edit>

  <remarks
    :isgaiBei="gaibei"
    :dingId="resdingIds"
    @remarksx="beizhuMessage"
  ></remarks>

  <canael :iscar="car" :rescueIds="guanbiids"></canael>

  <sharedan
    v-show="isxiang"
    :xiangid="xiangid"
    @guanpientyr="guanpientyr"
  ></sharedan>
</template>

<script>
import * as api from "../api/orderDetail";
import * as api1 from "../api/order";
import * as api2 from "../api/index";
import { ElMessageBox } from "element-plus";
import AMap from "AMap";
import rescueEdit from "../components/rescueEdit.vue";
import canael from "../components/canael.vue";
import sharedan from "../components/sharedan.vue";
import remarks from "../components/remarks.vue";
import phonebar from "jssip-emicnet/dist/phonebar";
import { id } from "element-plus/lib/locale";
import * as ola from "../api/olawebsock.js";
import * as vt from "../api/verto.js";
import Cookies from "js-cookie";
export default {
  props: [
    "isshows",
    "diaodurescueid",
    "iszhi",
    "partId",
    "iswei",
    "weishouliId",
  ],
  components: { rescueEdit, canael, remarks, sharedan },
  data() {
    return {
      is_gaip: false,
      visible: false,
      visible1: false,
      mapyin: true,
      yaIndex: -1,
      hao: -1,
      pai: 1,
      carNum: "",
      alters: this.isshows,
      zhis: this.iszhi,
      shoulis: this.iswei,
      detail: [],
      center: [121.483874, 29.861542],
      map: "",
      driverList: [],
      driverList1: [],
      driverListMap: [],
      driverListMap1: [],
      showedit: false,
      car: 0,
      gaibei: 0,
      rescueId: this.id,
      guanbiids: "",
      xiugaiidx: "",
      resdingIds: "",
      emicreg: this.$store.state.emic,
      img: require("../assets/img/cd/list.png"),
      img1: require("../assets/img/cd/wei.png"),
      imgs: require("../assets/img/cd/list1.png"),
      imgs1: require("../assets/img/cd/wei1.png"),
      checked1: true,
      cartype: 1,
      planline: [],
      plankm: "",
      plantime: "",
      btnyin: false,
      newbtnyin: true,

      zhan: false,
      gaiindex: -1,
      gaiindex2: -1,
      gaiindex3: -1,
      gaiindex4: -1,

      gaiindex11: -1,
      gaiindex12: -1,
      gaiindex13: -1,
      gaiindex14: -1,
      chargeType: "1",
      showYiKou: false,

      a: "",
      aa: "",
      b: "",
      bb: "",
      c: "",
      cc: "",
      d: "",
      flag: true,

      jishi: true,
      yuyue: false,
      shunlu: false,
      discount1: 1,
      discount2: 1,
      discount3: 1,
      gold: "https://dispatch-test.nituowola.com/img/amap/gold.png",
      silver: "https://dispatch-test.nituowola.com/img/amap/silver.png",
      bronze: "https://dispatch-test.nituowola.com/img/amap/bronze.png",

      gxchargeType: "1",
      gxa: "",
      gxb: "",
      gxc: "",
      isxiang: false,
      xiangid: "",

      opchargeType: "0",
      opa: "",
      opb: "",
      opc: "",
      opd: "",
      showOpYiKou: false,
      busypng1: "https://dispatch-test.nituowola.com/img/amap/busy.png",
      freepng1: "https://dispatch-test.nituowola.com/img/amap/free.png",
      offlinepng1: "https://dispatch-test.nituowola.com/img/amap/offline.png",
      amaplog: 1,
      showKj: false,
      iskj: 0,
      gp_reason: "",
      gplist: [],
      gp_name: "",
      gp_uid: "",

      ditugaipai: 0,
      ditugaipai_uid: "",
      ditugaipai_id: "",

      freshID: "",
      newtype:1,
    };
  },
  created() {
    window.dispatch = this.dispatch;
    window.dispatchgaijia = this.dispatchgaijia;
    window.dispatchopgo = this.dispatchopgo;
    window.dispatchgo = this.dispatchgo;
    window.ququ = this.ququ;
    window.opququ = this.opququ;
    window.pricechange = this.pricechange;
    window.oppricechange = this.oppricechange;
    window.queding = this.queding;
    window.opqueding = this.opqueding;
    window.call = this.call;
    window.jishibtn = this.jishibtn;
    window.yuyuebtn = this.yuyuebtn;
    window.shunlubtn = this.shunlubtn;
    window.closemarker = this.closemarker;
    window.refreshX = this.refreshX;
    window.tipss = this.tips;
    window.gg_change = this.gg_change;
    window.dispatchgogp = this.dispatchgogp;
    this.img1 = require("../assets/img/cd/wei1.png");
    window.ggp_cancel = this.ggp_cancel;
    window.ggp_queding = this.ggp_queding;

    //this.getgplist()
    //this.gettechlist(1);

    //let who = Cookies.get('ntwl-uid');

    // if(who == 87)
    // {
    //   this.busypng1    = 'https://dispatch-test.nituowola.com/img/amap/bak/busy.png';
    //   this.freepng1    = 'https://dispatch-test.nituowola.com/img/amap/bak/free.png';
    //   this.offlinepng1 = 'https://dispatch-test.nituowola.com/img/amap/bak/offline.png';
    // }
  },

  methods: {
    async getgplist() {
      let res = await api.getgplist();

      this.gplist = res.data;
    },
    async shareorder() {
      let chargetype = this.gxchargeType;
      let guidePrice = this.gxa;
      let guideKm = this.gxb;
      let kmPrice = this.gxc;
      if (guidePrice == "") {
        this.$message.warning("请输入共享价格");
        return false;
      }
      ElMessageBox.confirm("确定共享此订单吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await api1.shareOrder(
            this.detail.订单号,
            chargetype,
            guidePrice,
            guideKm,
            kmPrice
          );
          if (res.code == 1) {
            this.$message.success("共享成功");
            //this.btndisabled = true;
            this.visible = false;
            this.refresh();
          } else {
            this.$message.warning(res.message);
            this.refresh();
          }
        })
        .catch(() => {
          //this.btndisabled = true;
          this.$message.info("取消");
        });
    },
    jiage(e) {
      console.log("选中了", e);
      this.checked1 = e;
      this.initMap();
    },
    async gettechlist() {
      let res = await api2.gettechlist(1);
      this.amaplog = res.logType;
      if (this.amaplog == 2) {
        this.busypng =
          "https://dispatch-test.nituowola.com/img/amap/jt/busy.png";
        this.freepng =
          "https://dispatch-test.nituowola.com/img/amap/jt/free.png";
        this.offlinepng =
          "https://dispatch-test.nituowola.com/img/amap/jt/offline.png";
      }
      this.showKj = res.showKj;
      if (this.showKj) {
        this.iskj = 1;
      }
    },
    hide() {
      this.showedit = false;
    },
    xiangbtn(id) {
      this.xiangid = id;
      this.isxiang = true;
    },
    guanpientyr() {
      this.isxiang = false;
      this.refresh();
    },
    typejishi() {
      this.jishi = true;
      this.yuyue = false;
      this.shunlu = false;
      this.a = (this.aa * this.discount1).toFixed(1);
      this.b = this.bb;
      this.c = (this.cc * this.discount1).toFixed(1);
    },
    typeyuyue() {
      this.yuyue = true;
      this.jishi = false;
      this.shunlu = false;
      this.a = (this.aa * this.discount2).toFixed(1);
      this.b = this.bb;
      this.c = (this.cc * this.discount2).toFixed(1);
    },
    typeshunlu() {
      this.shunlu = true;
      this.jishi = false;
      this.yuyue = false;
      this.a = (this.aa * this.discount3).toFixed(1);
      this.b = this.bb;
      this.c = (this.cc * this.discount3).toFixed(1);
    },

    suo() {
      this.zhan = true;
      this.$refs.zhanmap.classList.add("shrinkmap");
    },
    zhankai() {
      this.zhan = false;
      this.$refs.zhanmap.classList.remove("shrinkmap");
    },
    gg_change() {
      console.log(document.getElementById("gaipai_reason").value);
    },
    close() {
      this.cartype = 1;
      this.newtype = 1;
      this.alters = false;
      this.mapyin = true;
      this.zhis = false;
      this.shoulis = false;
      this.btnyin = false;
      this.newbtnyin = true;
      this.$refs.lie.classList.add("dynamic");
      this.img = require("../assets/img/cd/list.png");
      this.img1 = require("../assets/img/cd/wei1.png");
      this.visable1 = false;
      this.visable = false;
      this.gaiindex = -1;
      this.gaiindex2 = -1;
      this.gaiindex3 = -1;
      this.gaiindex4 = -1;
      this.gaiindex11 = -1;
      this.gaiindex12 = -1;
      this.gaiindex13 = -1;
      this.gaiindex14 = -1;
      this.gp_reason = "";
      this.freshID = "";

      this.cartype = 1;

    },
    lish() {
      console.log("点击了-----");
      this.car++;
      if (this.car == 3) {
        this.car = 1;
      }
      this.guanbiids = this.detail.订单号;
    },
    beizhuMessage() {
      this.refresh(this.detail.订单号);
      this.rescueIdx = "";
    },
    dingbei(id) {
      this.gaibei++;
      if (this.gaibei == 3) {
        this.gaibei = 1;
      }
      console.log("*/**/*/*/*/*", id);
      this.resdingIds = id;
    },

    async xiuprice(index, data) {
      if (data.is_op == 1) {
        if (data.uid != this.detail.take_user) {
          let res = await api.orderopprice(data.uid, this.detail.订单号);
          this.opchargeType = res.charge_type.toString();

          if (this.opchargeType == "1") {
            this.showOpYiKou = false;
          } else {
            this.showOpYiKou = true;
          }
          this.opa = res.a;
          this.opb = res.b;
          this.opc = res.c;
          this.opd = data.uid;

          if (
            this.detail.take_user != "" &&
            this.detail.take_user != undefined
          ) {
            this.is_gaip = true;
          } else {
            this.is_gaip = false;
          }
        } else {
          let res = await api.orderoppricenow(this.detail.订单号);

          this.opchargeType = res.charge_type.toString();

          if (this.opchargeType == "1") {
            this.showOpYiKou = false;
          } else {
            this.showOpYiKou = true;
          }
          this.opa = res.a;
          this.opb = res.b;
          this.opc = res.c;
          this.opd = data.uid;

          this.is_gaip = false;
        }

        if (this.gaiindex3 == -1) {
          this.gaiindex3 = index;
        } else {
          this.gaiindex3 = -1;
        }
      } else {
        if (data.is_self == 0) {
          if (data.is_take == 0) {
            this.getPlatprice(data.uid);

            if (
              this.detail.take_user != "" &&
              this.detail.take_user != undefined
            ) {
              this.is_gaip = true;
            } else {
              this.is_gaip = false;
            }

            if (this.gaiindex == -1) {
              this.gaiindex = index;
            } else {
              this.gaiindex = -1;
            }
          } else {
            this.getPlatpriceNow(data.uid);

            if (this.gaiindex == -1) {
              this.gaiindex = index;
            } else {
              this.gaiindex = -1;
            }

            this.is_gaip = false;
          }
        } else {
          if (this.detail.take_user == "") {
            this.dispatchgo(this.detail.订单号, data.uid);
            this.is_gaip = false;
          } else {
            this.is_gaip = true;
            if (this.gaiindex4 == -1) {
              this.gaiindex4 = index;
              this.gp_name = data.nickname;
              this.gp_uid = data.uid;
            } else {
              this.gaiindex4 = -1;
              this.gp_name = "";
              this.gp_uid = "";
            }
          }
        }
      }
    },

    async gaipaigo(id, uid) {
      let that = this;
      this.detail.订单号 = id;
      let res = await api.OrderDispatchNew(id, uid, this.gp_reason);
      if (res.code == 1) {
        that.$message.success(res.message);
        this.gaiindex4 = -1;
        this.gaiindex14 = -1;
        this.gp_name = "";
        this.gp_uid = "";
        that.refresh();
      } else {
        that.$message.warning(res.message);
      }
    },

    async xiuprice2(index, data) {
      if (data.is_op == 1) {
        if (data.uid != this.detail.take_user) {
          let res = await api.orderopprice(data.uid, this.detail.订单号);
          this.opchargeType = res.charge_type.toString();

          if (this.opchargeType == "1") {
            this.showOpYiKou = false;
          } else {
            this.showOpYiKou = true;
          }
          this.opa = res.a;
          this.opb = res.b;
          this.opc = res.c;
          this.opd = data.uid;

          if (
            this.detail.take_user != "" &&
            this.detail.take_user != undefined
          ) {
            this.is_gaip = true;
          } else {
            this.is_gaip = false;
          }
        } else {
          let res = await api.orderoppricenow(this.detail.订单号);

          this.opchargeType = res.charge_type.toString();

          if (this.opchargeType == "1") {
            this.showOpYiKou = false;
          } else {
            this.showOpYiKou = true;
          }
          this.opa = res.a;
          this.opb = res.b;
          this.opc = res.c;
          this.opd = data.uid;

          this.is_gaip = false;
        }

        if (this.gaiindex13 == -1) {
          this.gaiindex13 = index;
        } else {
          this.gaiindex13 = -1;
        }
      } else {
        if (data.is_self == 0) {
          if (data.is_take == 0) {
            this.getPlatprice(data.uid);

            if (
              this.detail.take_user != "" &&
              this.detail.take_user != undefined
            ) {
              this.is_gaip = true;
            } else {
              this.is_gaip = false;
            }

            if (this.gaiindex11 == -1) {
              this.gaiindex11 = index;
            } else {
              this.gaiindex11 = -1;
            }
          } else {
            this.getPlatpriceNow(data.uid);

            if (this.gaiindex11 == -1) {
              this.gaiindex11 = index;
            } else {
              this.gaiindex11 = -1;
            }

            this.is_gaip = false;
          }
        } else {
          if (this.detail.take_user == "") {
            this.dispatchgo(this.detail.订单号, data.uid);
            this.is_gaip = false;
          } else {
            this.is_gaip = true;
            if (this.gaiindex14 == -1) {
              this.gaiindex14 = index;
              this.gp_name = data.nickname;
              this.gp_uid = data.uid;
            } else {
              this.gaiindex14 = -1;
              this.gp_name = "";
              this.gp_uid = "";
            }
          }
        }
      }
    },
    async getPlatprice(uid) {
      let res = await api.getPlatpriceNew(
        uid,
        this.detail.rescue_type,
        this.detail.订单号
      );
      this.chargeType = res.data.chargeType.toString();
      this.funGong(this.chargeType);
      this.aa = res.data.guidePirce;
      this.bb = res.data.guideKm;
      this.cc = res.data.kmPrice;
      this.discount1 = 1;
      this.discount2 = res.data.discount1;
      this.discount3 = res.data.discount2;
      if (this.detail.rescue_style == 1) {
        this.a = res.data.guidePirce;
        this.b = res.data.guideKm;
        this.c = res.data.kmPrice;
        this.jishi = true;
        this.yuyue = false;
        this.shunlu = false;
      } else if (this.detail.rescue_style == 2) {
        this.a = (this.aa * this.discount2).toFixed(1);
        this.b = res.data.guideKm;
        this.c = (this.cc * this.discount2).toFixed(1);
        this.jishi = false;
        this.yuyue = true;
        this.shunlu = false;
      } else if (this.detail.rescue_style == 3) {
        this.a = (this.aa * this.discount3).toFixed(1);
        this.b = res.data.guideKm;
        this.c = (this.cc * this.discount3).toFixed(1);
        this.jishi = false;
        this.yuyue = false;
        this.shunlu = true;
      }

      this.d = uid;
    },
    async getPlatpriceNow(uid) {
      let res = await api.getPlatpriceNow(this.detail.订单号);
      this.chargeType = res.data.chargeType.toString();
      this.funGong(this.chargeType);
      this.a = res.data.guidePirce;
      this.b = res.data.guideKm;
      this.c = res.data.kmPrice;
      this.d = uid;
      this.aa = res.data.a2;
      this.bb = res.data.a3;
      this.cc = res.data.a4;
      this.discount1 = 1;
      this.discount2 = res.data.discount1;
      this.discount3 = res.data.discount2;
      if (this.detail.rescue_style == 1) {
        this.jishi = false;
        this.yuyue = false;
        this.shunlu = false;
      } else if (this.detail.rescue_style == 2) {
        this.jishi = false;
        this.yuyue = false;
        this.shunlu = false;
      } else if (this.detail.rescue_style == 3) {
        this.jishi = false;
        this.yuyue = false;
        this.shunlu = false;
      }
    },
    // async getPlatpriceMap(rescuetype,uid)
    // {

    // },
    funGong(label) {
      console.log("点击了：", label);
      this.showYiKou = label == 2 ? true : false;
      console.log(this.showYiKou);
    },

    opfunGong(label) {
      console.log("点击了：", label);
      this.showOpYiKou = label == 2 ? true : false;
      console.log(this.showOpYiKou);
    },
    funGonggx(label) {
      console.log("点击了：", label);
      this.showYiKou = label == 2 ? true : false;
      console.log(this.showYiKou);
    },
    gaicancel() {
      this.a = "";
      this.b = "";
      this.c = "";
      this.d = "";
      this.gaiindex = -1;
      this.gaiindex11 = -1;
    },
    gaiseal() {
      this.dispatchX();
      this.gaiindex = -1;
      this.gaiindex11 = -1;
    },

    gaisealg() {
      if (this.gp_reason == "") {
        this.$message.warning("请选择改派理由");
        return false;
      }
      this.dispatchXG();
      this.gaiindex = -1;
      this.gaiindex11 = -1;
    },

    gaicancel3() {
      this.opa = "";
      this.opb = "";
      this.opc = "";
      this.opd = "";
      this.gaiindex3 = -1;
      this.gaiindex13 = -1;
    },
    async gaiseal3() {
      if (this.opd != this.detail.take_user) {
        let res = await api.OrderOpPush(
          this.detail.订单号,
          this.opd,
          this.opchargeType,
          this.opa,
          this.opb,
          this.opc
        );
        if (res.code == 1) {
          this.$message.success(res.message);
          this.refresh();
          this.gaiindex3 = -1;
          this.gaiindex13 = -1;
        } else {
          this.$message.warning(res.message);
        }
      } else {
        let res = await api.OrderOpMoney(
          this.detail.订单号,
          this.opchargeType,
          this.opa,
          this.opb,
          this.opc
        );
        if (res.code == 1) {
          this.$message.success(res.message);
          this.refresh();
          this.gaiindex3 = -1;
          this.gaiindex13 = -1;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    async gaiseal3g() {
      console.log(this.gp_reason);

      if (this.gp_reason == "") {
        this.$message.warning("请选择改派原因");
        return false;
      }

      let res = await api.OrderOpPushNew(
        this.detail.订单号,
        this.opd,
        this.opchargeType,
        this.opa,
        this.opb,
        this.opc,
        this.gp_reason
      );
      if (res.code == 1) {
        this.$message.success(res.message);
        this.gp_reason = "";
        this.refresh();
        this.gaiindex3 = -1;
        this.gaiindex13 = -1;
      } else {
        this.$message.warning(res.message);
      }
    },
    gaicancel2() {
      this.a = "";
      this.b = "";
      this.c = "";
      this.d = "";
      this.gaiindex2 = -1;
    },
    gaiseal2() {
      this.dispatchX();
      this.a = "";
      this.b = "";
      this.c = "";
      this.d = "";
      this.gaiindex2 = -1;
    },

    async que() {
      console.log("输入框的值：", this.carNum);

      if(this.showKj)
      {
        let zhi = this.carNum;
        let res = await api.getCarX(zhi, this.detail.订单号,this.newtype);

        if (this.mapyin == true) {
          this.driverListMap = res.data;
          this.initMap();
        } else {
          this.driverList = res.data;
        }
      }else{
        let zhi = this.carNum;
        let res = await api.getCar(zhi, this.detail.订单号);

        if (this.mapyin == true) {
          this.driverListMap = res.data;
          this.initMap();
        } else {
          this.driverList = res.data;
        }
      }

    },

    reset() {
      this.carNum = "";
      this.driverList = this.driverList1;
      this.driverListMap = this.driverListMap1;
      if (this.mapyin == true) {
        this.initMap();
      }
    },

    paiTab() {
      this.mapyin = false;
      this.btnyin = true;
      this.newbtnyin = false;

      this.$refs.lie.classList.add("dynamic");
      this.$refs.di.classList.remove("dynamic");

      // this.$refs.jiahao.classList.remove("newjiahao");

      this.img = require("../assets/img/cd/list.png");
      this.img1 = require("../assets/img/cd/wei.png");
      if (this.mapyin == false) {
        this.img = require("../assets/img/cd/list1.png");
      }
      if (this.mapyin == true) {
        this.img1 = require("../assets/img/cd/wei1.png");
      }
    },

    paiMap() {
      this.mapyin = true;
      this.btnyin = false;
      this.newbtnyin = true;

      this.$refs.lie.classList.remove("dynamic");
      this.$refs.di.classList.add("dynamic");

      // this.$refs.jiahao.classList.add("newjiahao");

      this.img = require("../assets/img/cd/list.png");
      this.img1 = require("../assets/img/cd/wei.png");
      if (this.mapyin == false) {
        this.img = require("../assets/img/cd/list1.png");
      }
      if (this.mapyin == true) {
        this.img1 = require("../assets/img/cd/wei1.png");
      }
    },
    mouseenterTel(index) {
      this.hao = index;
    },
    mouseleaveTel() {
      this.hao = -1;
    },

    mouseenterFun(index) {
      console.log("悬浮--", index);
      this.yaIndex = index;
    },
    mouseleaveFun() {
      this.yaIndex = -1;
    },
    async shoutype(e) {
      this.cartype = e;
      let res_driverMore = await api.OrderGetDriverMore(
        this.detail.订单号,
        this.cartype,
        this.iskj
      );
      this.driverListMap = res_driverMore.data;
      this.driverListMap1 = res_driverMore.data;
      this.driverList = res_driverMore.data;
      this.driverList1 = res_driverMore.data;
      
      this.initMap();
    },
    async shoukj(e) {
      this.iskj = e;
      let res_driverMore = await api.OrderGetDriverMore(
        this.detail.订单号,
        this.cartype,
        this.iskj
      );
      this.driverListMap = res_driverMore.data;
      this.driverListMap1 = res_driverMore.data;
      this.initMap();
    },
    initMap() {
      if (
        this.detail.事故点经纬度 != undefined &&
        this.detail.事故点经纬度 != ""
      ) {
        var a = this.detail.事故点经纬度.split(",");
        this.center = [a[0], a[1]];
      }

      var map = new AMap.Map("container2", {
        center: this.center,
        resizeEnable: true,
        zoom: 10,
      });
      var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) });
      if (
        this.detail.事故点经纬度 != undefined &&
        this.detail.事故点经纬度 != ""
      ) {
        var marker = new AMap.Marker({
          position: new AMap.LngLat(a[0], a[1]),
          title: this.detail.事故地点,
          icon: "https://dispatch-test.nituowola.com/img/amap/address.png",
        });

        map.add(marker);
      }

      if (
        this.detail.目的地经纬度 != undefined &&
        this.detail.目的地经纬度 != ""
      ) {
        var b = this.detail.目的地经纬度.split(",");

        if (this.plantime != "") {
          var plan =
            '<div class="carnumhao1">预计' +
            this.plankm +
            "公里，" +
            this.plantime +
            "</div>";
          var markerb = new AMap.Marker({
            position: new AMap.LngLat(b[0], b[1]),
            title: this.detail.目的地,
            content:
              '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/destination.png"></br>' +
              plan +
              "</div>",
          });
        } else {
          var markerb = new AMap.Marker({
            position: new AMap.LngLat(b[0], b[1]),
            title: this.detail.目的地,
            icon: "https://dispatch-test.nituowola.com/img/amap/destination.png",
          });
        }

        map.add(markerb);
      }

      if (
        this.planline != "" &&
        this.planline != undefined &&
        this.planline.length > 0
      ) {
        var takeline = new AMap.Polyline({
          map: map,
          path: this.planline,
          borderWeight: 2, // 线条宽度，默认为 1
          strokeColor: "green", // 线条颜色
          lineJoin: "round", // 折线拐点连接处样式     //设置线覆盖物路径
        });
        //map.add(takeline);
      }

      //接单司机
      if (this.driverListMap.length > 0) {
        if (this.detail.接单人员 == "") {
          var dispatchtype = "指派";
        } else {
          var dispatchtype = "改派";
        }
        let that = this;

        if (this.gplist.length > 0) {
          var ggp = '<select id="gaipai_reason">';

          ggp += "<option>请选择改派原因</option>";
          for (var i = 0; i < this.gplist.length; i++) {
            ggp += "<option>" + this.gplist[i].label + "</option>";
          }
          ggp += "</select>";

          var ggp1 = '<select id="gaipai_reason1">';

          ggp1 += "<option>请选择改派原因</option>";
          for (var i = 0; i < this.gplist.length; i++) {
            ggp1 += "<option>" + this.gplist[i].label + "</option>";
          }
          ggp1 += "</select>";

          var ggp2 = '<select id="gaipai_reason2">';

          ggp2 += "<option>请选择改派原因</option>";
          for (var i = 0; i < this.gplist.length; i++) {
            ggp2 += "<option>" + this.gplist[i].label + "</option>";
          }
          ggp2 += "</select>";
        }

        console.log("地图里面的数据", this.driverListMap);
        this.driverListMap.forEach(function (item) {
          var apptype =
            item.is_new == 1
              ? (item.is_np == 0?'<img class="mapimg1" src="https://dispatch-test.nituowola.com/img/amap/newmode.png"/>':'<img class="mapimg1" src="https://dispatch-test.nituowola.com/img/amap/new_pw.png"/>')
              : "";
          apptype +=
            item.isT == 1
              ? '<img class="mapimg" src="https://dispatch-test.nituowola.com/img/amap/gps_new.png"/>'
              : '<img class="mapimg" src="https://dispatch-test.nituowola.com/img/amap/app_new.png"/>';
          if (that.checked1 == true) {
            if (item.is_take == 1) {
              var carnumber =
                '<div class="carnumhaotake">' +
                apptype +
                item.car_number +
                "</div>";
              if (item.is_self == 0) {
                dispatchtype = "改价";
              } else {
                dispatchtype = "当前";
              }
            } else {
              if (item.green == 0) {
                if (item.is_warning == 1) {
                  var carnumber =
                    '<div class="carnumhaoyellow">' +
                    apptype +
                    item.car_number +
                    "</div>";
                } else {
                  var carnumber =
                    '<div class="carnumhao">' +
                    apptype +
                    item.car_number +
                    "</div>";
                }
              } else {
                if (item.is_warning == 1) {
                  var carnumber =
                    '<div class="carnumhaoyellowg">' +
                    apptype +
                    item.car_number +
                    "</div>";
                } else {
                  var carnumber =
                    '<div class="carnumhaog">' +
                    apptype +
                    item.car_number +
                    "</div>";
                }
              }

              if (that.detail.接单人员 == "") {
                dispatchtype = "指派";
                var is_gaip = 0;
              } else {
                dispatchtype = "改派";
                var is_gaip = 1;
              }
            }
          } else {
            var carnumber = "";
          }

          if (item.status == 3) {
            var driver = new AMap.Marker({
              position: new AMap.LngLat(item.lng, item.lat),
              map: map,
              content:
                '<div class="makerlabel"><img src="' +
                that.busypng1 +
                '" style="transform:rotate(' +
                item.angel +
                'deg)"></br>' +
                carnumber +
                "</div>",
            });
          } else if (item.status == 2) {
            var driver = new AMap.Marker({
              position: new AMap.LngLat(item.lng, item.lat),
              map: map,
              content:
                '<div class="makerlabel"><img src="' +
                that.freepng1 +
                '" style="transform:rotate(' +
                item.angel +
                'deg)"></br>' +
                carnumber +
                "</div>",
            });
          } else if (item.status == 1) {
            var driver = new AMap.Marker({
              position: new AMap.LngLat(item.lng, item.lat),
              map: map,
              content:
                '<div class="makerlabel"><img src="' +
                that.offlinepng1 +
                '" style="transform:rotate(' +
                item.angel +
                'deg)"></br>' +
                carnumber +
                "</div>",
            });
          }
          if (item.tel1 != "无") {
            var call11 = `
                      </div>
                      <div class="maphaomaMain">
                        <label class="maphaomaTit" >私人:</label>
                        <span class="maphaomaTel">${item.tel1}</span>
                        `;
          } else {
            var call11 = "";
          }

          if (item.tel2 != "无") {
            var call22 = `
                      </div>
                      <div class="maphaomaMain">
                        <label class="maphaomaTit" >紧急:</label>
                        <span class="maphaomaTel">${item.tel2}</span>
                        `;
          } else {
            var call22 = "";
          }

          if (that.emicreg != "") {
            var call =
              "<a onclick='call(" +
              item.tel +
              "," +
              that.detail.订单号 +
              ")'><img class='amp-title-x' src='https://dispatch-test.nituowola.com/img/amap/call.png' alt='' /></a>";
            if (item.tel1 != "无") {
              var call1 =
                "<a onclick='call(" +
                item.tel1 +
                "," +
                that.detail.订单号 +
                ")'><img class='amp-title-x' src='https://dispatch-test.nituowola.com/img/amap/call.png' alt='' /></a>";
            } else {
              var call1 = "";
            }
            if (item.tel2 != "无") {
              var call2 =
                "<a onclick='call(" +
                item.tel2 +
                "," +
                that.detail.订单号 +
                ")'><img class='amp-title-x' src='https://dispatch-test.nituowola.com/img/amap/call.png' alt='' /></a>";
            } else {
              var call2 = "";
            }
            if (item.xzs != "") {
              var callxzs =
                "<a onclick='call(" +
                item.xzs +
                "," +
                that.detail.订单号 +
                ")'><img class='amp-title-x' src='https://dispatch-test.nituowola.com/img/amap/call.png' alt='' /></a>";
            } else {
              var callxzs = "";
            }
          } else {
            var call = "";
            var call1 = "";
            var call2 = "";
            var callxzs = "";
          }

          if (item.is_self == 1) {
            if (item.is_take == 1) {
              if (item.is_op == 0) {
                var diaodu =
                  `<button class='amp-gai-red' disabled>` +
                  dispatchtype +
                  `</button>`;
              } else {
                var diaodu =
                  `<button class='amp-gai-red' disabled>` +
                  dispatchtype +
                  `</button>`;
              }
            } else {
              if (is_gaip == 0) {
                if (item.is_op == 0) {
                  var diaodu =
                    `<button class='amp-gai' onclick='dispatchgo(${that.detail.订单号},
                            ${item.uid})'>` +
                    dispatchtype +
                    `</button>`;
                } else {
                  var diaodu =
                    `<button class='amp-gai' onclick='dispatchopgo(${that.detail.订单号},
                            ${item.uid},0)'>` +
                    dispatchtype +
                    `</button>`;
                }
              } else {
                if (item.is_op == 0) {
                  var diaodu =
                    `<button class='amp-gai' onclick='dispatchgogp(${that.detail.订单号},
                            ${item.uid},1)'>` +
                    dispatchtype +
                    `</button>`;
                } else {
                  var diaodu =
                    `<button class='amp-gai' onclick='dispatchopgo(${that.detail.订单号},
                            ${item.uid},1)'>` +
                    dispatchtype +
                    `</button>`;
                }
              }
            }
          } else {
            if (item.is_take == 1) {
              var diaodu =
                `<button class='amp-gai' onclick='dispatchgaijia(${that.detail.订单号},${item.uid},${that.detail.rescue_style})'>` +
                dispatchtype +
                `</button>`;
            } else {
              if (is_gaip == 0) {
                var diaodu =
                  `<button class='amp-gai' onclick='dispatch(${that.detail.订单号},${that.detail.rescue_type},
                        ${item.uid},${that.detail.rescue_style},0)'>` +
                  dispatchtype +
                  `</button>`;
              } else {
                var diaodu =
                  `<button class='amp-gai' onclick='dispatch(${that.detail.订单号},${that.detail.rescue_type},
                        ${item.uid},${that.detail.rescue_style},1)'>` +
                  dispatchtype +
                  `</button>`;
              }
            }
          }

          if (item.tt == "") {
            var paiban = "";
          } else if (item.tt == "休息") {
            // var paiban = `<span class="zhuangtai2">${item.tt}</span>`;
            var paiban = `<span class="zhuangtai5">
                              <i class="jiao">今</i>
                              ${item.tt}
                          </span>`;
          } else {
            // var paiban = `<span class="zhuangtai">${item.tt}</span>`;
            var paiban = `<span class="zhuangtai4">
                              <i class="jiao2">今</i>
                              ${item.tt}
                          </span>`;
          }

          if (item.tt1 == "") {
            var paiban2 = "";
          } else if (item.tt1 == "休息") {
            var paiban2 = `<span class="zhuangtai5">
                              <i class="jiao2">明</i>
                              ${item.tt1}
                          </span>`;
          } else {
            var paiban2 = `<span class="zhuangtai4">
                              <i class="jiao2">明</i>
                              ${item.tt1}
                          </span>`;
          }

          if (item.grade == 0) {
            var gradeimg = "";
          } else if (item.grade == 3) {
            var gradeimg = `<img class='gradeimg' src='https://dispatch-test.nituowola.com/img/amap/gold.png' alt='' />`;
          } else if (item.grade == 2) {
            var gradeimg = `<img class='gradeimg' src='https://dispatch-test.nituowola.com/img/amap/silver.png' alt='' />`;
          } else if (item.grade == 1) {
            var gradeimg = `<img class='gradeimg' src='https://dispatch-test.nituowola.com/img/amap/bronze.png' alt='' />`;
          }

          if(item.is_np == 1)
          {
            var np = `<span class="npgreen">新能源</span>`;
            var np1 =  `<div class="xinxiBox newtimebox">
                  <div class="newtime">
                     <label class="xiTit2" >充电状态:</label>
                     <span class="xinTxt2"
                       >${item.np_info.is_charge == 1?'充电中':'未充电'}</span
                     >
                  </div>
                  <div class="newtime">
                    <label class="xiTit" >当前电量:</label>
                    <span class="timeTxt"
                      >${item.np_info.soc}%</span
                    >
                 </div>
                 <div>
                   <label class="xiTit2" >续航里程:</label>
                   <span class="xinTxt2"
                     >${item.np_info.range}公里</span
                   >
                 </div>
              </div>`;
          }else{
            var np = '';
            var np1 = '';
          }

          if (item.is_self == 1) {
            var tips =
              `<button class='amp-gai1' onclick='tipss(1,${item.uid},"${item.nickname}")'>拒接</button>` +
              `<button class='amp-gai1' onclick='tipss(2,${item.uid},"${item.nickname}")'>拒单</button>`;
          } else {
            var tips = "";
          }

          var content =
            `<div class="mapBox">
                <div class="mapBoxNav">
                  <h2 class="mapBoxTit">车辆详情</h2>
                </div>
                <!-- 车辆信息 -->
                <div class="carXinXi carXinXiDing">
                  <div class="carXinXiNav2">
                    <strong>车辆信息</strong> `+ np +`
                    <span class="tool">${item.type}</span>
                    <span class="tool">${item.carstyle}</span>
                    <span class="tool">${
                      item.isT == 0 ? "终端未绑定" : "终端已绑定"
                    }</span>
                    <span class="zhuangtai">${item.statustxt}</span>
                    ` +
            paiban +
            paiban2 +
            `
                    <div class='amp-btn1'> 
                     ` +
            diaodu +
            `
                    </div>
                  </div>

              <div id="gaiprice3">
                  <div class="gaipricetit">
                  流转价格
                    <div id="btns">
                      <button id="jishi" class="color" onclick="jishibtn()">即时</button>
                      <button id="yuyue" onclick="yuyuebtn()">预约</button>
                      <button id="shunlu" onclick="shunlubtn()">顺路</button>
                    </div>
                  </div>
                  <div class="name1" id="gaipaishow1">
                    <label class="pricelabel" >改派原因</label>
` +
            ggp2 +
            `
                  </div>
                  <div class="name1">
                    <label class="pricelabel" >价格设置</label>
                    <input type="radio" name="price" checked id="yikouprice" onchange="pricechange()">
                    <label for="yikouprice" class="yikoutit">一口价</label>
                    <input type="radio" name="price" id="gongliprice" onchange="pricechange()">
                    <label for="gongliprice" class="yikoutit">公里价</label> 
                  </div>
                    <div id="electbox2" style="display:flex;">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          placeholder="基准价"
                          id="a"
                        />
                        <span class="elect-span2">元</span>
                      </div>
                      <div class="electbox yinprice" id="priceyin">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            placeholder="基准公里"
                            id="b"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            placeholder="超基准价"
                            id="c"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                      </div>
                    </div>

                    <div id="electbox3" style="display:none;">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          placeholder="基准价"
                        />
                        <span class="elect-span2">元</span>
                      </div>
                      <div class="electbox">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            placeholder="基准公里"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            placeholder="超基准价"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                      </div>
                    </div>
                    <div id="electbox4" style="display:none;">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          placeholder="基准价"
                        />
                        <span class="elect-span2">元</span>
                      </div>
                      <div class="electbox">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            placeholder="基准公里"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            placeholder="超基准价"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            placeholder="百分比"
                          />
                          <span class="elect-span4">%/上限</span>
                        </div>
                      </div>
                    </div>

                  
                    <div class="gaipricebtn">
                      <input
                          class="elect-span1"
                          placeholder="基准价"
                          id="rescueId"
                          hidden
                        />
                      <button id="case" class="reset" onclick="ququ()">取消</button>
                      <button id="qure" class="deter" onclick="queding()">确定</button>
                    </div>
                </div>


                <div id="opprice">
                  <div class="gaipricetit">
                  外协价格
                  </div>
                  <div class="name1" id="gaipaishow">
                    <label class="pricelabel" >改派原因</label>
` +
            ggp +
            `
                  </div>
                  <div class="name1">
                    <label class="pricelabel" >价格设置</label>
                    <input type="radio" name="price" checked id="opyikouprice" onchange="oppricechange()">
                    <label for="yikouprice" class="yikoutit">一口价</label>
                    <input type="radio" name="price" id="opgongliprice" onchange="oppricechange()">
                    <label for="gongliprice" class="yikoutit">公里价</label> 
                  </div>
                    <div id="electbox5" style="display:flex;">
                      <div class="elect1">
                        <input
                          class="elect-span1"
                          placeholder="基准价"
                          id="opa"
                        />
                        <span class="elect-span2">元</span>
                      </div>
                      <div class="electbox yinprice" id="oppriceyin">
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            placeholder="基准公里"
                            id="opb"
                          />
                          <span class="elect-span3">公里</span>
                        </div>
                        <div class="elect2">
                          <input
                            class="elect-span1"
                            placeholder="超基准价"
                            id="opc"
                          />
                          <span class="elect-span4">元/公里</span>
                        </div>
                      </div>
                    </div>
   
                    <div class="gaipricebtn">
                      <input
                          class="elect-span1"
                          placeholder="基准价"
                          id="rescueId"
                          hidden
                        />
                      <button id="case" class="reset" onclick="opququ()">取消</button>
                      <button id="qure" class="deter" onclick="opqueding()">确定</button>
                    </div>
                </div>


                <div id="ditu_gaipai">
                  <div class="gaipricetit">
                    确认改派
                  </div>
                  <div class="name1" id="gaipx">
                    <label class="pricelabel" >改派原因</label>
` +
            ggp1 +
            `
                  </div>
                    <div class="gaipricebtn">
                      <button id="case" class="reset" onclick="ggp_cancel()">取消</button>
                      <button id="qure" class="deter" onclick="ggp_queding()">确定</button>
                    </div>
                </div>


                <div class="xinxiBox">
                  <label class="xiTit" >协作商:</label>
                  <span class="xinTxt"
                    >` +
            gradeimg +
            `${item.companyname}/${item.xzs}` +
            callxzs +
            `</span
                  >
                </div>

                <div class="xinxiBox">
                  <label class="xiTit" >技师:</label>
                  <span class="xinTxt"
                    >${item.nickname}/
                    <div class="haoBox">
              <div class="haoBox2">${item.tel}` +
            call +
            ` </div>

            <div class="maphaoma">
                <div class="maphaomaMain">
                  <label class="maphaomaTit" >工作:</label>
                  <span class="maphaomaTel">${item.tel}</span>
                  ` +
            call +
            call11 +
            call1 +
            call22 +
            call2 +
            `
                </div>
              </div>
            </div>     
                    /${item.car_number}` +
            tips +
            `</span
                  >  </div>

                <div class="xinxiBox">
                  <label class="xiTit" >当前位置:</label>
                  <span class="xinTxt"
                    >${item.address}</span
                  >
                </div>
                

                <div class="xinxiBox newtimebox">
                  <div class="newtime">
                     <label class="xiTit2" >上单完成:</label>
                     <span class="xinTxt2"
                       >${item.last_order_time}</span
                     >
                  </div>
                  <div class="newtime">
                    <label class="xiTit" >当前定位:</label>
                    <span class="timeTxt"
                      >${item.last_time}</span
                    >
                 </div>
                 <div class="${item.isT == 0 ? "newtimeyin" : ""}">
                   <label class="xiTit2" >当前速度:</label>
                   <span class="xinTxt2"
                     >${item.speed}Km/h</span
                   >
                 </div>
                
              </div>` + np1+ `
        </div>`;

          if (
            item.next_order_num > 0 ||
            (item.now_order.address != "" &&
              item.now_order.address != undefined)
          ) {
            content += ` <!-- 当前任务 -->
          <div class="taskBox">
            <div class="taskBoxNav">
              <strong>当前任务</strong>
              <span class="zhuangtai">${item.now_order.status}</span>
              <span class="tool">${item.now_order.service}</span>
            </div>

            <div class="xinxiBox">
              <div class="xinLeft">
                <label class="xiTit2" >合作单位:</label>
                <span class="xinTxt2"
                  >${item.now_order.companyname}</span
                >
              </div>
              <div class="xinRight">
                <label class="xiTit2" >救援时间:</label>
                <span class="xiTxt2"
                  >${item.now_order.rescueTime}</span
                >
              </div>
            </div>

            <div class="xinxiBox xinxiaddress">
                <label class="xiTit2" >任务点:</label>
                <span class="taskTit2"
                >${item.now_order.address}</span
              >
            </div>`;
            if (item.now_order.destination != "") {
              content += `<div class="xinxiBox xinxiaddress">
                                  <label class="xiTit2" >目的地:</label>
                                  <span class="taskTit2"
                                    >${item.now_order.destination}</span
                                  >
                                </div>`;
            }
            content += `</div>`;

            if (item.next_order_num > 0) {
              content += `<div class="pressSheet">
                <div class="pressSheetNav">
                  <strong>压单信息</strong>
                  <span class="pressSheetNum">${item.next_order_num}</span>
                </div>

                <table class="maptable" border="0" cellspacing="0">
                  <thead>
                    <th class="partner">合作单位</th>
                    <th class="serviceType">救援时间</th>
                    <th class="taskPoint">任务点</th>
                    <th class="destination">目的地</th>
                  </thead>
                  <tbody>`;
              for (var i = 0; i < item.next_order_num; i++) {
                content += `<tr
                      :class=" 
                        (index % 2 == 0 && !changecolor) ||
                        (index % 2 !== 0 && changecolor)
                          ? 'bg_color'
                          : 'bg_color2'
                      "
                    >
                      <td class="td-partner" style="text-align:center;">${item.next_order[i].companyname}<br><span class="zhuangtai1">${item.next_order[i].status}</span></td>
                      <td class="td-serviceType">${item.next_order[i].rescueTime}</td>
                      <td class="td-taskPoint">
                        ${item.next_order[i].address}
                      </td>
                      <td class="td-destination">
                        ${item.next_order[i].destination}
                      </td>
                    </tr>`;
              }

              content += `</tbody>
                </table>
              </div>
              </div>
            </div>`;
            }
          }
          driver.content = content;
          driver.on("click", markerClick);
        });
      }
      function markerClick(e) {
        console.log("--------click", e);
        infoWindow.setContent(e.target.content);
        infoWindow.open(map, e.target.getPosition());
      }
      map.setFitView();
    },

    async dispatch(id, rescue_type, uid, rescue_style, type) {
      let res = await api.getPlatpriceNew(uid, rescue_type, id);
      this.chargeType = res.data.chargeType.toString();
      this.aa = res.data.guidePirce;
      this.bb = res.data.guideKm;
      this.cc = res.data.kmPrice;
      this.discount1 = 1;
      this.discount2 = res.data.discount1;
      this.discount3 = res.data.discount2;
      this.d = uid;

      if (type == 1) {
        this.ditugaipai = 1;
        let xx = document.getElementById("gaipaishow1");
        xx.classList.remove("yinprice");
      } else {
        this.ditugaipai = 0;
        let xx = document.getElementById("gaipaishow1");
        xx.classList.add("yinprice");
      }

      let jishi = document.getElementById("jishi");
      let yuyue = document.getElementById("yuyue");
      let shunlu = document.getElementById("shunlu");

      if (rescue_style == 1) {
        this.a = this.aa;
        this.b = this.bb;
        this.c = this.cc;
        jishi.classList.add("color");
        yuyue.classList.remove("color");
        shunlu.classList.remove("color");
      } else if (rescue_style == 2) {
        this.a = (this.aa * this.discount2).toFixed(1);
        this.b = this.bb;
        this.c = (this.cc * this.discount2).toFixed(1);
        jishi.classList.remove("color");
        yuyue.classList.add("color");
        shunlu.classList.remove("color");
      } else if (rescue_style == 3) {
        this.a = (this.aa * this.discount3).toFixed(1);
        this.b = this.bb;
        this.c = (this.cc * this.discount3).toFixed(1);
        jishi.classList.remove("color");
        yuyue.classList.remove("color");
        shunlu.classList.add("color");
      }

      let xiujiage = document.getElementById("gaiprice3");

      let a = document.getElementById("a");
      let b = document.getElementById("b");
      let c = document.getElementById("c");
      document.getElementById("rescueId").value = id;

      if (this.chargeType == "1") {
        $("#yikouprice").attr("checked", "checked"); //设置选中
        let danrido = document.getElementById("priceyin");
        danrido.classList.add("yinprice");
        a.value = this.a;
      } else {
        $("#gongliprice").attr("checked", "checked"); //设置选中
        let danrido = document.getElementById("priceyin");
        danrido.classList.remove("yinprice");
        a.value = this.a;
        b.value = this.b;
        c.value = this.c;
      }

      if (this.flag === true) {
        xiujiage.classList.add("yingaiprice3");
      } else {
        xiujiage.classList.remove("yingaiprice3");
      }
      this.flag = !this.flag;
    },

    async dispatchgaijia(id, uid, rescue_style) {
      let res = await api.getPlatpriceNow(id);
      this.chargeType = res.data.chargeType.toString();
      this.a = res.data.guidePirce;
      this.b = res.data.guideKm;
      this.c = res.data.kmPrice;
      this.d = uid;

      this.aa = res.data.a2;
      this.bb = res.data.a3;
      this.cc = res.data.a4;
      this.discount1 = 1;
      this.discount2 = res.data.discount1;
      this.discount3 = res.data.discount2;

      let jishi = document.getElementById("jishi");
      let yuyue = document.getElementById("yuyue");
      let shunlu = document.getElementById("shunlu");

      jishi.classList.remove("color");
      yuyue.classList.remove("color");
      shunlu.classList.remove("color");

      let xiujiage = document.getElementById("gaiprice3");

      let a = document.getElementById("a");
      let b = document.getElementById("b");
      let c = document.getElementById("c");
      document.getElementById("rescueId").value = id;

      if (this.chargeType == "1") {
        $("#yikouprice").attr("checked", "checked"); //设置选中
        let danrido = document.getElementById("priceyin");
        danrido.classList.add("yinprice");
        a.value = this.a;
      } else {
        $("#gongliprice").attr("checked", "checked"); //设置选中
        let danrido = document.getElementById("priceyin");
        danrido.classList.remove("yinprice");
        a.value = this.a;
        b.value = this.b;
        c.value = this.c;
      }

      if (this.flag === true) {
        xiujiage.classList.add("yingaiprice3");
      } else {
        xiujiage.classList.remove("yingaiprice3");
      }
      this.flag = !this.flag;
    },

    pricechange() {
      let danrido = document.getElementById("priceyin");
      console.log("触发单选");
      if (yikouprice.checked == true) {
        danrido.classList.add("yinprice");
      } else if (gongliprice.checked == true) {
        danrido.classList.remove("yinprice");
      }
    },
    ququ() {
      let xiujiage = document.getElementById("gaiprice3");
      if (this.flag === true) {
        xiujiage.classList.add("yingaiprice3");
      } else {
        xiujiage.classList.remove("yingaiprice3");
      }
      this.flag = !this.flag;
    },

    jishibtn() {
      let jishi = document.getElementById("jishi");
      let yuyue = document.getElementById("yuyue");
      let shunlu = document.getElementById("shunlu");
      let div2 = document.getElementById("electbox2");
      let div3 = document.getElementById("electbox3");
      let div4 = document.getElementById("electbox4");
      console.log("点击选项即时", jishi);
      jishi.classList.add("color");
      yuyue.classList.remove("color");
      shunlu.classList.remove("color");

      div2.style.display = "flex";
      div3.style.display = "none";
      div4.style.display = "none";

      if (this.chargeType == "2") {
        let a = document.getElementById("a");
        let b = document.getElementById("b");
        let c = document.getElementById("c");
        this.a = this.aa;
        this.b = this.bb;
        this.c = this.cc;
        a.value = this.a;
        b.value = this.b;
        c.value = this.c;
      }
    },
    yuyuebtn() {
      let jishi = document.getElementById("jishi");
      let yuyue = document.getElementById("yuyue");
      let shunlu = document.getElementById("shunlu");
      let div2 = document.getElementById("electbox2");
      let div3 = document.getElementById("electbox3");
      let div4 = document.getElementById("electbox4");
      console.log("点击选项即时", yuyue);
      jishi.classList.remove("color");
      yuyue.classList.add("color");
      shunlu.classList.remove("color");

      div2.style.display = "flex";
      div3.style.display = "none";
      div4.style.display = "none";

      if (this.chargeType == "2") {
        let a = document.getElementById("a");
        let b = document.getElementById("b");
        let c = document.getElementById("c");
        this.a = (this.aa * this.discount2).toFixed(1);
        this.b = this.bb;
        this.c = (this.cc * this.discount2).toFixed(1);
        a.value = this.a;
        b.value = this.b;
        c.value = this.c;
      }
    },
    shunlubtn() {
      let jishi = document.getElementById("jishi");
      let yuyue = document.getElementById("yuyue");
      let shunlu = document.getElementById("shunlu");
      let div2 = document.getElementById("electbox2");
      let div3 = document.getElementById("electbox3");
      let div4 = document.getElementById("electbox4");
      console.log("点击选项即时", shunlu);
      shunlu.classList.add("color");
      yuyue.classList.remove("color");
      jishi.classList.remove("color");

      div2.style.display = "flex";
      div3.style.display = "none";
      div4.style.display = "none";

      if (this.chargeType == "2") {
        let a = document.getElementById("a");
        let b = document.getElementById("b");
        let c = document.getElementById("c");
        this.a = (this.aa * this.discount3).toFixed(1);
        this.b = this.bb;
        this.c = (this.cc * this.discount3).toFixed(1);
        a.value = this.a;
        b.value = this.b;
        c.value = this.c;
      }
    },

    async queding() {
      let that = this;
      let a = document.getElementById("a");
      let b = document.getElementById("b");
      let c = document.getElementById("c");
      let rescueId = document.getElementById("rescueId");
      let chargetype = 1;
      if (yikouprice.checked == true) {
        chargetype = 1;
      } else if (gongliprice.checked == true) {
        chargetype = 2;
      }

      if (that.ditugaipai == 1) {
        let gaipai_reason = document.getElementById("gaipai_reason2").value;
        if (gaipai_reason == "请选择改派原因") {
          this.$message.warning("请选择改派原因");
          return false;
        }

        let res = await api.OrderDispatchXNew(
          rescueId.value,
          that.d,
          chargetype,
          a.value,
          b.value,
          c.value,
          gaipai_reason
        );
        if (res.code == 1) {
          that.$message.success(res.message);
          that.refreshX(rescueId.value);
          that.ditugaipai = 0;
        } else {
          that.$message.warning(res.message);
          that.refreshX(rescueId.value);
        }
      } else {
        let res = await api.OrderDispatchX(
          rescueId.value,
          that.d,
          chargetype,
          a.value,
          b.value,
          c.value
        );
        if (res.code == 1) {
          that.$message.success(res.message);
          that.refreshX(rescueId.value);
        } else {
          that.$message.warning(res.message);
        }
      }
    },

    async dispatchgogp(id, uid, type) {
      let xiujiage = document.getElementById("ditu_gaipai");

      xiujiage.classList.add("yingaiprice3");

      this.ditugaipai_id = id;
      this.ditugaipai_uid = uid;
    },

    ggp_cancel() {
      let xiujiage = document.getElementById("ditu_gaipai");

      xiujiage.classList.remove("yingaiprice3");

      this.ditugaipai_id = "";
      this.ditugaipai_uid = "";
    },
    async ggp_queding() {
      let gaipai_reason = document.getElementById("gaipai_reason1").value;
      if (gaipai_reason == "请选择改派原因") {
        this.$message.warning("请选择改派原因");
        return false;
      }

      let that = this;

      let id = that.ditugaipai_id;

      let res = await api.OrderDispatchNew(
        that.ditugaipai_id,
        that.ditugaipai_uid,
        gaipai_reason
      );
      if (res.code == 1) {
        that.$message.success(res.message);
        that.ditugaipai_id = "";
        that.ditugaipai_uid = "";
        that.refreshX(id);
      } else {
        that.$message.warning(res.message);
      }
    },

    //地图里面指派改派
    async dispatchgo(id, uid) {
      let that = this;
      console.log(this.detail.订单号);
      this.detail.订单号 = id;
      let res = await api.OrderDispatch(id, uid);
      if (res.code == 1) {
        that.$message.success(res.message);
        that.freshID = id;
      } else {
        that.$message.warning(res.message);
      }
    },
    async dispatchopgo(id, uid, type) {
      let res = await api.orderopprice(uid, id);

      this.opchargeType = res.charge_type.toString();
      let xiujiage = document.getElementById("opprice");

      this.opa = res.a;
      this.opb = res.b;
      this.opc = res.c;
      this.opd = uid;

      let a = document.getElementById("opa");
      let b = document.getElementById("opb");
      let c = document.getElementById("opc");
      document.getElementById("rescueId").value = id;

      if (this.opchargeType == "1") {
        $("#opyikouprice").attr("checked", "checked"); //设置选中
        let danrido = document.getElementById("oppriceyin");
        danrido.classList.add("yinprice");
        a.value = this.opa;
      } else {
        $("#opgongliprice").attr("checked", "checked"); //设置选中
        let danrido = document.getElementById("oppriceyin");
        danrido.classList.remove("yinprice");
        a.value = this.opa;
        b.value = this.opb;
        c.value = this.opc;
      }

      if (type == 1) {
        this.ditugaipai = 1;
        let xx = document.getElementById("gaipaishow");
        xx.classList.remove("yinprice");
      } else {
        this.ditugaipai = 0;
        let xx = document.getElementById("gaipaishow");
        xx.classList.add("yinprice");
      }

      if (this.flag === true) {
        xiujiage.classList.add("yingaiprice3");
      } else {
        xiujiage.classList.remove("yingaiprice3");
      }
      this.flag = !this.flag;
    },
    oppricechange() {
      let danrido = document.getElementById("oppriceyin");
      console.log("触发单选");

      console.log(opyikouprice.checked);
      console.log(opgongliprice.checked);
      if (opyikouprice.checked == true) {
        danrido.classList.add("yinprice");
      } else if (opgongliprice.checked == true) {
        danrido.classList.remove("yinprice");
      }
    },
    opququ() {
      let xiujiage = document.getElementById("opprice");
      if (this.flag === true) {
        xiujiage.classList.add("yingaiprice3");
      } else {
        xiujiage.classList.remove("yingaiprice3");
      }
      this.ditugaipai = 0;
      this.flag = !this.flag;
    },
    async opqueding() {
      let that = this;

      let gaipai_reason = "";

      if (that.ditugaipai == 1) {
        gaipai_reason = document.getElementById("gaipai_reason").value;
        if (gaipai_reason == "请选择改派原因") {
          that.$message.warning("请选择改派原因");
          return false;
        }

        let a = document.getElementById("opa");
        let b = document.getElementById("opb");
        let c = document.getElementById("opc");
        let rescueId = document.getElementById("rescueId");
        let chargetype = 1;
        if (opyikouprice.checked == true) {
          chargetype = 1;
        } else if (opgongliprice.checked == true) {
          chargetype = 2;
        }
        let res = await api.OrderOpPushNew(
          rescueId.value,
          that.opd,
          chargetype,
          a.value,
          b.value,
          c.value,
          gaipai_reason
        );
        if (res.code == 1) {
          that.$message.success(res.message);
          that.ditugaipai = 0;
          that.refreshX(rescueId.value);
        } else {
          that.$message.warning(res.message);
        }
      } else {
        let a = document.getElementById("opa");
        let b = document.getElementById("opb");
        let c = document.getElementById("opc");
        let rescueId = document.getElementById("rescueId");
        let chargetype = 1;
        if (opyikouprice.checked == true) {
          chargetype = 1;
        } else if (opgongliprice.checked == true) {
          chargetype = 2;
        }
        let res = await api.OrderOpPush(
          rescueId.value,
          that.opd,
          chargetype,
          a.value,
          b.value,
          c.value
        );
        if (res.code == 1) {
          that.$message.success(res.message);
          that.refreshX(rescueId.value);
        } else {
          that.$message.warning(res.message);
        }
      }
    },
    async dispatchX() {
      let that = this;
      let res = await api.OrderDispatchX(
        that.detail.订单号,
        that.d,
        that.chargeType,
        that.a,
        that.b,
        that.c
      );
      if (res.code == 1) {
        that.$message.success(res.message);
        that.refresh();
      } else {
        that.$message.warning(res.message);
      }
    },
    async dispatchXG() {
      let that = this;
      let res = await api.OrderDispatchXNew(
        that.detail.订单号,
        that.d,
        that.chargeType,
        that.a,
        that.b,
        that.c,
        that.gp_reason
      );
      if (res.code == 1) {
        that.$message.success(res.message);
        that.gp_reason = "";
        that.refresh();
      } else {
        that.$message.warning(res.message);
        that.refresh();
      }
    },
    async refresh() {
      let res = await api.OrderDetail(this.detail.订单号);
      console.log("订单详情", res);
      this.detail = res.data;

      if (this.detail.目的地 != "") {
        let pl = await api.OrderGetPoly(this.detail.订单号);
        this.planline = pl.data.planline;
        this.plantime = pl.data.plantime;
        this.plankm = pl.data.plankm;
      } else {
        this.planline = [];
        this.plantime = "";
        this.plankm = "";
      }

      if(!this.showKj)
      {
        let res_driverMore = await api.OrderGetDriverMore(
          this.detail.订单号,
          this.cartype,
          this.iskj,
        );

        this.driverListMap = res_driverMore.data;
        this.driverListMap1 = res_driverMore.data;
        this.driverList = res_driverMore.data;
        this.driverList1 = res_driverMore.data;
        this.initMap();
      }else{
        let res_driverMore = await api.OrderGetDriverMoreX(
          this.detail.订单号,
          this.cartype,
          this.iskj,
          this.newtype
        );

        this.driverListMap = res_driverMore.data;
        this.driverListMap1 = res_driverMore.data;
        this.driverList = res_driverMore.data;
        this.driverList1 = res_driverMore.data;
        this.initMap();
      }
    },
    async refreshX(id) {
      //location.reload();
      let that = this;
      that.detail.订单号 = id;
      setTimeout(function () {
        that.freshID = id;
      }, 1000);
    },
    async partList() {
      let res = await api.OrderDetail(this.partId);
      console.log("订单详情", res);
      this.detail = res.data;

      if (this.detail.目的地 != "") {
        let pl = await api.OrderGetPoly(this.detail.订单号);
        this.planline = pl.data.planline;
        this.plantime = pl.data.plantime;
        this.plankm = pl.data.plankm;
      } else {
        this.planline = [];
        this.plantime = "";
        this.plankm = "";
      }

      if(!this.showKj)
      {
        let res_driverMore = await api.OrderGetDriverMore(
          this.partId,
          this.cartype,
          this.iskj
        );

        this.driverListMap = res_driverMore.data;
        this.driverListMap1 = res_driverMore.data;
        this.driverList = res_driverMore.data;
        this.driverList1 = res_driverMore.data;
        this.initMap();
      }else{
        let res_driverMore = await api.OrderGetDriverMore(
          this.partId,
          this.cartype,
          this.iskj
        );

        this.driverListMap = res_driverMore.data;
        this.driverListMap1 = res_driverMore.data;
        this.driverList = res_driverMore.data;
        this.driverList1 = res_driverMore.data;
        this.initMap();
      }
    },

    async weishouli() {
      let res = await api.OrderDetail(this.weishouliId);
      console.log("订单详情", this.weishouliId, "qqqqq", res);
      this.detail = res.data;

      if (this.detail.目的地 != "") {
        let pl = await api.OrderGetPoly(this.detail.订单号);
        this.planline = pl.data.planline;
        this.plantime = pl.data.plantime;
        this.plankm = pl.data.plankm;
      } else {
        this.planline = [];
        this.plantime = "";
        this.plankm = "";
      }

      
      if(!this.showKj)
      {
        let res_driverMore = await api.OrderGetDriverMore(
          this.weishouliId,
          this.cartype,
          this.iskj
        );

        this.driverListMap = res_driverMore.data;
        this.driverListMap1 = res_driverMore.data;
        this.driverList = res_driverMore.data;
        this.driverList1 = res_driverMore.data;
        this.initMap();
      }else{
        let res_driverMore = await api.OrderGetDriverMore(
          this.weishouliId,
          this.cartype,
          this.iskj
        );

        this.driverListMap = res_driverMore.data;
        this.driverListMap1 = res_driverMore.data;
        this.driverList = res_driverMore.data;
        this.driverList1 = res_driverMore.data;
        this.initMap();
      }


    },

    async recycle() {
      let res = await api.OrderRecycle(this.detail.订单号);
      if (res.code == 1) {
        // alert(res.message);
        this.$message.success(res.message);
        this.refresh();
      } else {
        // alert(res.message);
        this.$message.warning(res.message);
      }
    },

    async recycleNew() {
      if (this.gp_reason == "") {
        this.$message.warning("请选择回收原因");
        return false;
      }
      let res = await api.OrderRecycleNew(this.detail.订单号, this.gp_reason);
      if (res.code == 1) {
        this.$message.success(res.message);
        this.visible1 = false;
        this.gp_reason = "";
        this.refresh();
      } else {
        this.$message.warning(res.message);
      }
    },
    edit() {
      this.showedit = true;
      this.xiugaiidx = this.detail.订单号;
    },

    call(tel, str) {
      tel = tel.toString();
      str = str.toString();
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话1234", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
    async tips(type, uid, username) {
      let type_name = "";
      if (type == 1) {
        type_name = username + "拒接电话的记录";
      } else {
        type_name = username + "拒单的记录";
      }
      ElMessageBox.confirm("是否提交 - " + type_name, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await api.postTips(type, uid, username);
          if (res.code == 1) {
            this.$message.success("成功");
          } else {
            this.$message.warning(res.message);
          }
        })
        .catch(() => {
          this.$message.info("取消");
        });
    },
    async freshXX() {
      this.freshID = "";
      document.querySelector(".mapxin").click();
    },
    async changenewtype(n)
    {
   


      let res_driverMore = await api.OrderGetDriverMoreX(
          this.detail.订单号,
          this.cartype,
          this.iskj,
          n
        );

        this.driverListMap = res_driverMore.data;
        this.driverListMap1 = res_driverMore.data;
        this.driverList = res_driverMore.data;
        this.driverList1 = res_driverMore.data;
        this.initMap();

        this.newtype = n;
    }
  },

  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
  },
  watch: {
    isshows(newval) {
      this.alters = newval;
      this.detail.订单号 = this.diaodurescueid;

      this.gettechlist(1);
      this.getgplist();

      if (newval >= 0) {
        this.refresh();
      }
    },
    getEmic(newval) {
      this.emicreg = newval;
    },
    iszhi(newval) {
      this.zhis = newval;

      this.gettechlist(1);
      this.getgplist();

      if (newval >= 0) {
        this.partList();
      }
    },
    iswei(newval) {
      this.shoulis = newval;

      this.gettechlist(1);
      this.getgplist();

      if (newval >= 0) {
        this.weishouli();
      }
    },
    freshID(newval) {
      if (newval != "") {
        this.freshXX();
      }
    },
  },
};
</script>

<style lang="less">
.dingyin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.p3 {
  // margin: 0;
  margin: 10px;
}
.amp-title-l {
  margin-left: 44px;
}
.map-main {
  width: 410px;
  // height: 170px;
  padding-left: 20px;
  padding-top: 10px;
  opacity: 1;
  box-sizing: border-box;
  // background-color: #fff;
  position: relative;
}
.amp-btn1 {
  // margin-left: 10px;
  // padding: 1px 8px;
  font-size: 12px;
  float: right;
}

.amp-title {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.amp-title-main,
.amp-title-m,
.amp-tit-m,
.amp-title-l {
  width: 126px;
  height: 19px;
  font-size: 14px;

  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.amp-title-main {
  margin-left: 30px;
}

.amp-title-img {
  width: 18px;
  height: 18px;
  float: right;
  margin-top: 4px;
  margin-right: 104px;
  margin-left: 260px;
  margin-top: -18px;
}
.amp-title-distance {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #2a3346;
  opacity: 1;
  float: right;
  margin-right: 10px;
  margin-top: -24px;
}

.amp-tit-main {
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-left: 30px;
  color: #2c68ff;
  opacity: 1;
}

.amp-leisure {
  float: left;
  font-size: 12px;
  margin-left: 280px;
  margin-top: -50px;
  width: 88px;
  height: 30px;
  background-color: #eefbf4;
  text-align: center;
  line-height: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #27cc8b;
  opacity: 1;
}
.amp-gai,
.amp-gai1,
.amp-qu {
  width: 60px;
  height: 26px;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
}
.amp-qu {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
}
.amp-gai {
  height: 26px;
  background: #2c68ff;
  opacity: 1;
  border-radius: 2px;
  border: 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
  opacity: 1;
}

.amp-gai1 {
  width: 40px;
  height: 18px;
  background: red;
  opacity: 1;
  border-radius: 2px;
  border: 0;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 18px;
  color: white;
  opacity: 1;
  margin-left: 10px;
  cursor: pointer;
}
.amp-gai-red {
  width: 60px;
  height: 26px;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  height: 26px;
  background: red;
  opacity: 1;
  border-radius: 2px;
  border: 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
  opacity: 1;
}

.amap-info-content {
  padding: 0;
}
.amap-info-contentContainer.bottom-left {
  position: relative;
}

.haoBox {
  cursor: pointer;
  position: relative;
}
.haoBox2 {
  cursor: pointer;
  display: flex;
}

.maphaoma {
  width: 170px;
  padding: 12px;
  border-radius: 8px;
  position: absolute;
  // left: 50px;
  top: 18px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 10;
  display: none;
}
.haoBox:hover .maphaoma {
  display: block;
}
.haoBox2:hover .maphaoma {
  display: block;
}

.maphaomaMain {
  display: flex;
  margin: 5px 0;
  align-items: center;
}

.mapBox {
  width: 750px !important;
  // width: 345px;
  height: auto;
  min-height: 250px;
  max-height: 750px !important;
  border-radius: 8px;
  background-color: #fff;
  // position: relative;
  padding-bottom: 30px;
  box-sizing: border-box;
  // box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
  //   0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  position: relative;
}
.mapBox::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.mapBoxNav {
  // position: absolute;
  // top: 0;
  width: 750px !important;
  height: 50px;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  // padding-right: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #d9d9d9;
  z-index: 5;
  background-color: #fff;
  padding-left: 20px;
}
.mapBoxTit {
  font-size: 14px;
}
.gradeimg {
  width: 16px;
  height: 20px;
  margin-right: 10px;
  margin-top: -3px;
}
.amap-info-close {
  z-index: 5;
}
.carXinXi,
.taskBox,
.pressSheet {
  margin: 10px 24px;
  width: 700px !important;
}
.carXinXiDing {
  margin-top: 20px;
}
.taskBoxNav,
.pressSheetNav {
  display: flex;
  align-items: center;
  padding-left: 12px;
  box-sizing: border-box;
  border-left: 2px solid #2c68ff;
  // background-color: pink;
  // width: 550px;
}
.carXinXiNav2 {
  padding-left: 12px;
  box-sizing: border-box;
  border-left: 2px solid #2c68ff;
}
strong {
  font-size: 16px;
  font-weight: 500;
}
.tool {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 1px 8px;
  box-sizing: border-box;
  color: #000;
  font-size: 12px;
}
.npgreen{
  background-color: #27CC8B;
  border: 1px solid #27CC8B;
  color:#fff;
  box-sizing: border-box;
  font-size: 12px;
  padding: 1px 3px;
}
// .tool2{
//   background-color: #2c68ff;
//   border: 1px solid #d9d9d9;
//   padding: 1px 8px;
//   box-sizing: border-box;
//   color: #fff;
//   font-size: 12px;
// }

.pressSheetNum {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 1px 8px;
  box-sizing: border-box;
  font-size: 12px;
  margin-left: 10px;
}
.xinxiBox {
  display: flex;
  // align-items: center;
  margin-top: 10px;
  box-sizing: border-box;
}
.newtimebox {
  justify-content: space-between;
}
.newtimeyin {
  display: none;
}
// .newtime {
//   display: flex;
// }
.xinRight {
  width: 330px;
  display: flex;
}
.xinLeft {
  width: 220px;
  display: flex;
  position: relative;
}
.xiTit {
  // margin-right: 10px;
  // width: 150px;
  width: 60px;
  text-align: right;
  margin-right: 10px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.45);
}
.xiTit2 {
  width: 60px;
  text-align: right;
  margin-right: 10px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.45);
}

.xinTxt,
.taskTit2 {
  width: 500px;
  display: flex;
}
.xinTxt2 {
  width: 130px;
}
// .xiTxt {
//   width: 220px;
// }
.callImg {
  margin-left: 10px;
}
.taskTit {
  width: 500px;
}
// .xinxiaddress {
//   width: 500px;
// }
.maptable {
  margin-top: 10px;
}
thead {
  background-color: #f5f5f5;
  height: 32px !important;
}
.partner,
.taskPoint,
.destination {
  width: 150px;
}
.serviceType {
  width: 100px;
}

// tbody tr {
//   height: 70px !important;
// }
.partner,
.serviceType,
.taskPoint,
.destination {
  text-align: center;
}
.td-serviceType {
  text-align: center;
}
td {
  // padding: 10px;
  text-align: left;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}
.bg_color2 {
  background-color: #fafafa !important;
}
</style>

<style lang="less" scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 540px;
  height: 360px;
}
.box {
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 4;
  // background-color: #fff;
}
.box2 {
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 80;
  // background-color: #fff;
}
.cha {
  float: left;
  width: 103px;
  height: 103px;
  position: absolute;
  top: 36px;
  left: -29px;
}
.ddd {
  float: left;
  width: 22px;
  height: 22px;
  margin-top: 3px;
  margin-left: -15px;
}
.box-main,
.boxmain2 {
  width: 720px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}
.box-main {
  height: 920px;
}
.boxmain2 {
  width: 1720px;
  height: 880px;
  box-sizing: border-box;
}
.jiaMain {
  width: 1720px;
}
.newtitlemain {
  width: 300px !important;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  padding-left: 20px;
}
h1 {
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
  padding-top: 20px;
}
i {
  font-style: normal;
}
.xin {
  cursor: pointer;
  // width: 32px;
  height: 21px;
  font-style: normal;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  position: absolute;
  top: 40px;
  right: 20px;
  opacity: 1;
}
.shua {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 30px;
  right: 60px;
}
.order,
.order2 {
  //width: 280px;
  height: 21px;
  font-size: 16px;
  padding-left: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 21px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
  position: relative;
}
.order {
  margin-left: 20px;
}
.order2 {
  margin-left: 0px;
}
.take,
.take1,
.take2 {
  display: inline-block;
  // width: 64px;
  height: 22px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  margin-left: 6px;
  box-sizing: border-box;
  background: #e8f3fe;
  opacity: 1;
  border-radius: 2px;
  color: #2c68ff;
  vertical-align: top;
}
.take1 {
  color: #8c05bb;
  background: #f2ccff;
}
.take2 {
  color: #ff9100;
  background: #fff1e0;
}
.order-main {
  // width: 510px;
  height: auto;
  opacity: 1;
  //   background-color: orange;
}
.p1 {
  // margin-left: 30px;
  // padding-left: 7px;
  margin-bottom: 10px;
  width: 400px;
  box-sizing: border-box;
}
.receiving {
  width: 700px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.xiangmu {
  // margin-left: 20px;
  display: flex;
}
.newjiahao {
  width: 300px !important;
}
.yanse {
  color: #2c68ff;
}

.remBox,
.diBox {
  width: 700px;
  display: flex;
  // flex-direction: column;
  // margin-left: 37px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding-left: 30px;
  box-sizing: border-box;
}

.remTit {
  margin-right: 30px;
}

.diTxt {
  color: #2c68ff;
}
.remTxt {
  width: 500px;
}
.diTxt {
  width: 500px;
}

.hezuo {
  display: flex;
  padding-left: 30px;
  box-sizing: border-box;
  // justify-content: center;
}

.owner-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.p2 {
  width: 400px;
  // padding-left: 7px;
  // margin-left: 30px;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.p21 {
  width: 450px;
  // padding-left: 7px;
  // margin-left: 30px;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.title {
  // width: 56px;
  margin-right: 30px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  // line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.title-main,
.title-main2,
.title-main3 {
  // height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  opacity: 1;
  word-break: break-all;
}
.title-main2 {
  width: 126px;
}
.title-main3 {
  width: 250px;
}

.title-img {
  width: 18px;
  height: 18px;
  float: left;
  margin-top: 4px;
  margin-right: 104px;
  margin-left: 260px;
  margin-top: -18px;
}
.title-distance {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #2a3346;
  opacity: 1;
  float: right;
  margin-right: 10px;
  margin-top: -24px;
}

.tit {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  // margin-left: 96px;
  color: #2a3346;
  opacity: 1;
}
.tit-main {
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-left: 30px;
  color: #2c68ff;
  opacity: 1;
}
.icon {
  width: 5px;
  height: 16px;
  font-size: 12px;
  margin-left: -5px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: #ff0000;
  opacity: 1;
}
.owner,
#peoper {
  // height: 21px;
  font-size: 16px;
  // margin-top: 45px;
  // line-height: 21px;
  padding-left: 7px;
  margin-left: 20px;
  margin-bottom: 10px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
  display: flex;
  justify-content: space-between;
}
.owner {
  width: 500px;
  position: relative;
}
.tiao,
.tiao1,
.tiao2 {
  width: 3px;
  height: 15px;
  float: left;
  background: #2c68ff;
  opacity: 1;
  border-radius: 0px;
}
.tiao {
  position: absolute;
  top: 4px;
  left: -1px;
}
.tiao1 {
  position: absolute;
  top: 3px;
  left: 0;
}
#peoper {
  position: relative;
}
.tiao2 {
  position: absolute;
  top: 3px;
  left: 0;
}

.depart {
  display: inline-block;
  width: 8px;
  height: 13px;
  margin-right: 4px;
}
.depart-main {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  vertical-align: middle;
}

.jianju {
  margin-top: 0;
}
.quan,
.quan1 {
  float: left;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-top: 6px;
  border-radius: 50%;
  opacity: 1;
  box-sizing: border-box;
}
.quan {
  border: 3px solid #2c68ff;
}
.quan1 {
  border: 3px solid #27cc8b;
}
.site {
  float: right;
  margin-right: 27px;
}
.site /deep/ .el-checkbox-button:last-child .el-checkbox-button__inner {
  position: absolute;
  bottom: -13px;
  border-radius: 0;
}

.btn {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.alter,
.cancel {
  width: 80px;
  height: 32px;
  margin-right: 20px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
}
.cancel {
  vertical-align: middle;
}
.shuaxin {
  width: 80px;
  height: 32px;
  border: 1px solid #727e96;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}
.recycle,
.recycle2 {
  width: 80px;
  height: 32px;
  margin-right: 10px;
  border: 0;
  color: #fff;
  cursor: pointer;
}
.recycle {
  background-color: #2c68ff;
}
.recycle2 {
  background-color: #ff9100;
}

// 地图
.map {
  width: 540px;
  height: 360px;
  margin-top: 10px;
  // margin-left: 30px;
  background: #9ea3b9;
  border: 1px solid #707070;
  opacity: 1;
}

.gai,
.qu {
  width: 60px;
  height: 26px;
  opacity: 1;
  border-radius: 2px;
}
.qu {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
}
.gai {
  height: 26px;
  background: #2c68ff;
  opacity: 1;
  border-radius: 2px;
  border: 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
  opacity: 1;
}
.leisure {
  float: left;
  font-size: 12px;
  margin-left: 280px;
  margin-top: -50px;
  width: 88px;
  height: 30px;
  background-color: #eefbf4;
  text-align: center;
  line-height: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #27cc8b;
  opacity: 1;
}
.call {
  width: 20px;
  height: 20px;
  background: url("../assets/img/index/call.png");
  border: none;
  cursor: pointer;
  margin-left: 5px;
  background-size: contain;
}

.biao {
  float: left;
  margin-left: 50px;
}
.list,
.ditu {
  width: 64px;
  height: 24px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 9px;
  cursor: pointer;
}
.list:hover {
  background: #eaf0ff;
}
.ditu:hover {
  background: #eaf0ff;
}

.listImg,
.dituImg {
  width: 24px;
  height: 24px;
}
.listTit,
.dituTit {
  font-size: 14px;
  // color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
}
.qie {
  float: left;
}
.pai {
  float: left;
}
.paiList {
  // float: left;
  width: 640px;
  margin-top: 30px;
  padding-bottom: 50px;
  margin-left: 30px;
}
.int {
  width: 495px;
  height: 32px;
  padding-left: 12px;
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  outline: none;
}
.int:hover {
  border: 1px solid #c0c4cc;
}
input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}

table {
  width: 640px;
  // height: 360px;
  margin-top: 13px;
  font-weight: 400;
  border: 1px solid #f0f0f0;
}
thead {
  background-color: #f5f5f5;
  height: 32px;
}
th {
  text-align: center;
  font-weight: 400;
  border: 1px solid #f0f0f0;
}
.jiedan,
.zhongduan,
.cartype {
  width: 80px;
}
.information {
  width: 300px;
}
.juli {
  width: 110px;
}
.operation {
  width: 64px;
}
td {
  text-align: center;
  height: 44px;
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  font-size: 14px;
}
.juliTit {
  position: relative;
}

.operBtn,
.operBtn1,
.operBtn2 {
  display: inline-block;
  width: 40px;
  height: 21px;
  text-align: 21px;
  line-height: 21px;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
}
.operBtn {
  background: #2c68ff;
  color: #fff;
}
.operBtn1 {
  background: #f5f5f5;
  color: #000;
}
.operBtn2 {
  background: red;
  color: #fff;
}
.informationImg,
.yaImg {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
  cursor: pointer;
}
.informationTit,
.informationTit2 {
  // position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.informationTit2 {
  width: 400px;
  box-sizing: border-box;
}
.jiimg {
  margin-right: 5px;
}
.hovertel {
  cursor: pointer;
}

.yaYin {
  width: 540px;
  // height: 200px;
  background-color: #fff;
  position: absolute;
  // left: 23px;
  z-index: 5;
  border-radius: 8px;
  box-shadow: 0 0 5px 3px #e0e0e0;
}
.dingyaYin {
  position: absolute;
  left: 150px;
}
.yaYin th,
.yaYin td {
  border: 0;
}
.yaYin tr {
  height: 70px !important;
}
.yaYintable {
  width: 100%;
  margin: 0;
}
.mapTel {
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.mapTel:hover .haoma {
  display: block;
}

.haoma {
  width: 170px;
  padding: 12px;
  border-radius: 8px;
  position: absolute;
  left: -20px;
  z-index: 5;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #fff;

  display: none;
}
.haomaMain {
  display: flex;
  margin: 5px 0;
}

thead {
  background-color: #f5f5f5;
  height: 32px !important;
}
.serviceType {
  width: 100px;
}
.taskPoint,
.destination {
  width: 220px;
}
.td-serviceType {
  padding-left: 20px;
  text-align: left;
}
.td-taskPoint,
.td-destination {
  text-align: left;
}

.xianImg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 32px;
  height: 32px;
}
.dynamic {
  color: #2c68ff;
  background: #eaf0ff;
}

// 加宽样式
.jiamap {
  width: 1670px;
  height: 814px;
}
.jiamapmain {
  width: 100% !important;
  height: 100% !important;
}
.jiapai {
  width: 1640px;
}
.paiNav {
  width: 100%;
}
.jiap {
  width: 400px;
  padding: 0 !important;
}
.jiaBox {
  // width: 830px;
  padding: 0;
}
.jiaTit {
  // width: 700px;
  width: 300px;
  padding: 0;
}
.jieTit {
  width: 300px;
  padding: 0;
  color: #000;
}
.taiImg {
  width: 40px !important;
  height: 40px !important;
}
.jiedanren {
  width: 700px;
  display: flex;
  align-items: center;
}

.newxuan {
  float: right;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.newxuan
  /deep/
  .el-checkbox-button.is-checked:first-child
  .el-checkbox-button__inner {
  border-radius: 0;
}
.newxuanbox {
  height: 100%;
  border: 1px solid #e2e2e5;
  display: flex;
  align-items: center;
  margin-left: 10px;
  box-sizing: border-box;
}
.tiao3 {
  display: inline-block;
  width: 0px;
  height: 14px;
  // margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.zhang,
.shoufeiz {
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
}
.zhang {
  color: #9e9e9e;
}
.shoufeiz {
  color: #2c68ff;
}
</style>
<style>
.amap-marker-label {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.carnumhao {
  display: flex;
  align-items: center;
  width: 110px;
  padding: 3px 5px;
  background-color: #2c68ff;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -25px;
  text-align: center;
}
.carnumhaog {
  display: flex;
  align-items: center;
  width: 110px;
  padding: 3px 5px;
  background-color: green;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -25px;
  text-align: center;
}
.carnumhaotake {
  display: flex;
  align-items: center;
  width: 100px;
  padding: 3px 5px;
  background-color: red;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -25px;
  text-align: center;
}
.carnumhaoyellow {
  display: flex;
  align-items: center;
  width: 110px;
  padding: 3px 5px;
  border: solid 2px;
  border-color: red;
  background-color: #2c68ff;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -25px;
  text-align: center;
}
.carnumhaoyellowg {
  display: flex;
  align-items: center;
  width: 110px;
  padding: 3px 5px;
  border: solid 2px;
  border-color: red;
  background-color: green;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -25px;
  text-align: center;
}
.carnumhao1 {
  width: 180px;
  padding: 3px 5px;
  background-color: green;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -75px;
  text-align: center;
}
.newbtn {
  display: flex;
  align-items: center;
  margin-right: 60px;
}
.mapshua {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.mapxin {
  color: #2c68ff;
  font-size: 16px;
  font-weight: 400;
}
.newmapmain {
  display: flex;
}
.jiabeiBox {
  /* width: 1700px !important; */
  width: 500px !important;
  padding: 0 !important;
}
.jiabeiTit {
  /* width: 1550px !important; */
  width: 350px !important;
}
/* .newjiap{
  width: 400px;
  padding-left: 65px !important;
} */
.mapimg {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}

.mapimg1 {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}

.mapxinxi {
  width: 500px;
  height: 814px;
  background-color: #fff;
  /* background-color: gray; */
  opacity: 0.8;
  position: absolute;
  right: 17px;
  top: 11px;
  box-sizing: border-box;
  /* overflow: hidden;
   overflow-y: scroll; */
}
.shrinkmap {
  width: 0 !important;
}

.mapxinxibox {
  padding: 0px 20px 0 30px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
.suomap {
  position: absolute;
  top: 45%;
  cursor: pointer;
}
.fanimg,
.zhanimg {
  width: 40px;
  height: 40px;
  position: absolute;
  left: -10px;
}
.zhanimg {
  transform: rotate(180deg);
}

/* 修改价格 */
.operationTit {
  position: relative;
}
.gaiprice,
.gaiprice2,
#gaiprice3,
.gaiprice4,
#opprice,
#ditu_gaipai {
  width: 450px;
  height: 250px;
  background-color: #fff;
  z-index: 5;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 2px #ccc;
  border-radius: 4px;
}
.gaiprice {
  width: 600px;
  height: auto !important;
  min-height: 250px !important;
  position: absolute;
  right: 130px;
  top: -50px;
}
.gaiprice2 {
  position: absolute;
  right: 70px;
  top: -50px;

  height: auto !important;
  min-height: 200px !important;
}
.gaiprice4 {
  position: absolute;
  right: 70px;
  top: -50px;
}
#gaiprice3 {
  width: 550px;
  position: absolute;
  right: 15px;
  top: 20px;
  display: none;
  height: auto !important;
  min-height: 200px !important;
}
#opprice {
  width: 550px;
  position: absolute;
  right: 15px;
  top: 110px;
  display: none;
}

#ditu_gaipai {
  position: absolute;
  height: 200px;
  display: none;
}

.gaiprice::before,
.gaiprice2::before,
.gaiprice4::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  right: -25px;
  top: 55px;
  border: 13px solid #fff;
  border-top-color: transparent;
  /* border-left-color: transparent; */
  border-right-color: transparent;
  border-bottom-color: transparent;
}
#gaiprice3::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  right: 30px;
  top: -27px;
  border: 13px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  /* border-bottom-color: transparent; */
}

#opprice::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  right: 30px;
  top: -27px;
  border: 13px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  /* border-bottom-color: transparent; */
}

.pricelabel {
  margin-right: 20px;
}
.gaipricetit,
.name1 {
  display: flex;
  align-items: center;
}
.name1 {
  margin-top: 10px;
}
.elect {
  padding-left: 80px;
  margin-top: 10px;
  box-sizing: border-box;
}
#electbox2,
#electbox3,
#electbox4,
#electbox5 {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-left: 70px;
  box-sizing: border-box;
}
.elect1,
.elect2 {
  margin-right: 10px;
}
.elect,
.electbox {
  display: flex;
  align-items: center;
}
.elect-span1 {
  width: 65px;
  height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  color: #2a3346;
  opacity: 1;
  border: 1px solid #ccc;
  padding: 0px;
  outline: none;
  box-sizing: border-box;
}
.gaipricetit {
  font-size: 16px;
  font-weight: bold;
  justify-content: space-between;
}
.typexuan {
  font-weight: normal;
}
.gaipricebtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.reset,
.deter,
.detersou,
.resetsou {
  width: 60px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  margin-left: 12px;
  border-radius: 4px;
  cursor: pointer;
}
.resetsou,
.detersou {
  width: 100px;
}
.reset,
.resetsou {
  border: 1px solid #d9d9d9;
}
.reset:hover {
  background: #edf2fc;
  border: 1px solid #8aabfe;
}
.resetsou:hover {
  background: #edf2fc;
  border: 1px solid #8aabfe;
}
.deter,
.detersou {
  background: #2c68ff;
  color: #fff;
  border: 0;
}
.deter:hover {
  background: #0a4aed;
}
.detersou:hover {
  background: #0a4aed;
}
.yingaiprice3 {
  display: block !important;
  /* opacity: 1 !important; */
}
#gongliprice {
  margin-left: 10px;
}
.yinprice {
  display: none;
}
.yikoutit {
  margin-left: 5px;
}

.amap-info-close {
  top: 10px;
}

#btns button {
  width: 60px;
  height: 30px;
  float: left;
  border: 0;
  cursor: pointer;
}
.btns button {
  width: 60px;
  height: 30px;
  float: left;
  border: 0;
  cursor: pointer;
}
#divs div {
  width: 300px;
  height: 100px;
  font-size: 60px;
  padding-top: 60px;
  background-color: #eb923f;
  text-align: center;
  display: none;
}
.color {
  background-color: #2c68ff;
  color: #fff;
}

#gaipx {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}

#gaipai_reason,
#gaipai_reason1,
#gaipai_reason2 {
  padding: 5px;
}

.newmode {
  border: solid 1px red;
  color: red;
  padding: 0px 5px;
  margin-left: 10px;
}
.zhuangtai4 {
  background-color: #d9e4ff;
  padding: 1px 16px;
  box-sizing: border-box;
  margin-left: 10px;
  color: #2c68ff;
  font-size: 12px;
  position: relative;
}
.jiao {
  font-style: normal;
  width: 12px;
  height: 12px;
  font-size: 8px;
  border: 1px solid #2c68ff;
  background-color: #2c68ff;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 8px 0;
}

.zhuangtai5 {
  background-color: #bcbcbc;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  margin-left: 10px;
  padding: 1px 16px;
  box-sizing: border-box;
  position: relative;
}
.jiao2 {
  font-style: normal;
  width: 12px;
  height: 12px;
  font-size: 8px;
  border: 1px solid #2c68ff;
  background-color: #2c68ff;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 8px 0;
}

.zhuangtai {
  background-color: #d9e4ff;
  padding: 1px 16px;
  box-sizing: border-box;
  color: #2c68ff;
  font-size: 12px;
  border: 1px solid #d9e4ff;
  position: relative;
}

.zhuangtai1 {
  background-color: #d9e4ff;
  padding: 1px 16px;
  box-sizing: border-box;
  color: #2c68ff;
  font-size: 12px;
  margin-left: 10px;
  position: relative;
}

.zhuangtai3 {
  background-color: #d9e4ff;
  padding: 1px 16px;
  box-sizing: border-box;
  color: #2c68ff;
  font-size: 12px;
  position: relative;
}
.zhuangtai2 {
  background-color: #bcbcbc;
  padding: 1px 16px;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  border: 1px solid #bcbcbc;
  margin-left: 10px;
  position: relative;
}
.tool,
.zhuangtai {
  margin-left: 10px;
}

.newtype1,
.newtype2 {
   width:120px;
   float:left;
   text-align: center;
   line-height: 42px;
   border: 1px solid #f0f0f0;
   border-bottom: none;
   cursor: pointer;
}

.newtype1{
  border-top:3px solid blue;
  background-color: #f5f5f5;
}

.a1,
.a1t{
  width: 400px;
  height:30px;
}

.a2,
.a2t{
  width:220px;
}


.zhuangtai12 {
  background-color: #d9e4ff;
  padding: 1px 7px;
  box-sizing: border-box;
  color: #2c68ff;
  font-size: 12px;
  margin-left: 5px;
  position: relative;
  width:60px;
  float:left;
}

.zhuangtai22 {
  background-color: #bcbcbc;
  padding: 1px 7px;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  border: 1px solid #bcbcbc;
  margin-left: 5px;
  position: relative;
  width:60px;
  float:left;
}

.newmode1 {
  border: solid 1px red;
  color: red;
  padding: 1px 5px;
  font-size: 12px;
  margin-left: 10px;
  width:50px;
  float:left;
}

.a3,
.a3t{
  width:140px;
}

.a4,
.a4t{
  width:160px;
}

.a5,
.a5t{
  width:100px;
}

.a6,
.a6t{
  width:160px;
}

.a7,
.a7t{
  width:120px;
}

.a8,
.a8t{
  width:100px;
}



.operBtnX,
.operBtn1X,
.operBtn2X {
  display: inline-block;
  width: 50px;
  padding:1px 5px;
  margin-left:10px;
  line-height: 21px;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
}
.operBtnX {
  background: #2c68ff;
  color: #fff;
}
.operBtn1X {
  background: #f5f5f5;
  color: #000;
}
.operBtn2X {
  background: red;
  color: #fff;
}

.a9,
.a9t{
  width:250px;
}


.a10,
.a10t{
  width:165px;
}


</style>
