<template>
  <div class="beiBox" v-if="reports">
    <div class="main">
      <div class="beiNav">
        <h1>报备</h1>
        <img
          class="cha"
          @click="close"
          src="../assets/img/index/cha.png"
          alt=""
        />
      </div>
      <div class="beiMain">
        <label for="">类型:</label>
        <el-select
          filterable
          class="input-unit"
          style="margin-left:20px;"
          placeholder="请选择对应的报备类型"
          v-model="this.type"
          @change="changetype"
        >
          <el-option
            v-for="item in typelist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="beiMain" v-if="this.tips == 2">
        <span style="color:red; font-size: 13px;">注意: 先修改订单中对应属性，后进行报备</span>
      </div>
      <div class="beiMain" v-if="this.tips == 1">
        <label for="">小轮:</label>
        <input  v-model="other" type="number"  placeholder="小轮个数" autocomplete="off" class="input-owner"/>
      </div>
      <div class="beiMain" v-if="this.tips == 3">
        <label for="">等待:</label>
          <el-radio-group v-model="iswait" style="margin-left:20px;">
            <el-radio label='0'>车主不愿等待</el-radio>
            <el-radio label='1'>车主愿意等待</el-radio>
          </el-radio-group>

      </div>
      <div class="beiMain" v-if="this.tips == 3">
        <label for="">预计：</label>
        <el-date-picker
                class="yuTime"
                v-model="time1"
                type="datetime"
                placeholder="请选择预计到达时间"
                size="default"
                style="margin-left: 10px;"
              >
              </el-date-picker>

      </div>



      <div class="beiMain">
        <label for="">原因:</label>
        <div class="wenben">
          <textarea
            name=""
            class="beiTxt"
            v-model="title"
            maxlength="255"
            placeholder="请输入原因"
          ></textarea>
          <span>{{ this.titleMax - this.title.length }}/255</span>

        </div>
      </div>

      <div class="btn">
        <button class="save" @click="bao">提交</button>
        <button class="xiao" @click="close">取消</button>
      </div>

      <div style="clear: both; margin-top: 20px;">
          <div class="owner" v-if="this.log.length > 0">
            <span class="tiao1"></span>
            报备记录
          </div>
          <div class="owner-log" >
            <div class="rizhi" v-for="(value, index) in log" :key="index">
              <div class="rileft">
                <div class="rizhiTit">{{ value.time }}</div>
                <div class="rizhiMain">{{ value.name }}</div>
                <div class="rizhiTxt">【{{ value.note }}】<span :style="value.status == 1?'color:green':(value.status == 2?'color:red':'color:gray')">【{{value.status==1?'成功':(value.status == 2?'拒绝':'失败')}}】</span></div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { go_ready } from "../api/olawebsock";
import * as api from "../api/order";
export default {
  props: ["isbaobeiid", "isreports" ],
  data() {
    return {
      type:'',
      typelist:[],
      log:[],
      other:'',
      title: "",
      titleMax: 255,
      reports: this.isreports,
      id: this.isbaobeiid,
      tips:0,
      iswait:'1',
      time1:'',
    };
  },
  async created() {
  },
  methods: {
    close() {
      this.reports = false;
      this.title   = "";
      this.type    = '';
      this.other   = '';
      this.tips    = 0;
      this.time1   = '';
    },
    changetype(){
        console.log(this.type);
        var d = this.type;
        var  t = 0
        this.typelist.forEach(function(eve){
          if(eve.id == d)
          {
            t = eve.tips
          }
        })
        this.tips = t;
        console.log(t);
    },
    async getinfo()
    {
      let res = await api.getreportinfo({'rescue_id':this.isbaobeiid});
      this.typelist = res.typelist;
      this.log = res.data;
    },
    async bao() {
      if (this.title == "") {
        this.$message.warning("请输入原因");
        return;
      }

      if(this.type == "")
      {
        this.$message.warning("请选择报备类型");
        return;
      }

      if(this.tips == 1)
      {
        if(this.other == '')
        {
          this.$message.warning("请输入报备小轮数");
          return;      
        }

        if(this.other < 1 || this.other > 4)
        {
          this.$message.warning("只能提交1~4个小轮");
          return;      
        }
      }

      if(this.tips == 3)
      {
          if (this.time1 != "" && this.time1 != null) {
              var vtime = parseInt(this.time1.getTime() / 1000);

              let now = parseInt(new Date().getTime()/1000);

              if(vtime < now)
              {
                this.$message.warning("预计到达时间不能早于当前时间，请重新选择");
                return;
              }

          } else {
              this.$message.warning("请选择预计到达时间");
              return;
          }
      }else
      {
         var vtime = '';
      }

      let res = await api.savereport(this.id,this.type,this.title,this.tips == 1?this.other:(this.tips == 3?this.iswait:''),vtime);

      if (res.code == 1) {
        this.$message.warning("成功");
        this.$emit("baobeisx", 1);
        this.close();
      } else {
        this.$message.warning(res.message);
        return;
      }

     
    },
  },
  watch: {
    title() {
      if (this.title.length > this.titleMax.length) {
        this.title = String(this.title).slice(0, this.titleMax);
      }
    },
    isreports(newval) {
      this.reports = newval;
      this.getinfo();
    },
    isbaobeiid(newval) {
      this.id = newval;
    },
  },
};
</script>

<style lang="less" scoped>
.beiBox {
 float: left;
 width: 1920px;
 height: 940px;
 position: relative;
 background: rgba(0, 0, 0, 0.4);
 border-radius: 0px;
 position: absolute;
 top: 0;
 left: 0;
 z-index: 70;
}
.main {
  width: 520px;
  height: 650px;
  background-color: #fff;
  margin: 180px auto;
  border-radius: 8px;
  box-sizing: border-box;
}
.beiNav {
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
  padding: 0 10px;
  line-height: 60px;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
}
h1 {
  float: left;
  font-size: 20px;
  margin-left: 10px;
  font-weight: 500;
  color: #2a3346;
}
.cha {
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 17px;
}
label {
  font-size: 16px;
}

.beiMain {
  display: flex;
  margin-left: 30px;
  margin-top: 30px;
}
.wenben {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  box-sizing: border-box;
}
.wenben textarea {
  min-height: 80px;
  min-width: 350px;
  max-width: 500px;
  max-height: 100px;
  padding: 5px 10px;
  font-size: 20px;
}
.wenben span {
  text-align: right;
}
.beiTxt {
  width: 300px;
  border-color: #C0C4CC;
  outline:none;
}
.btn {
  margin-top: 20px;
  float: left;
  padding-bottom: 20px;
  width: 100%;
  text-align: center;
  border-bottom:1px solid #C0C4CC;
}
.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
  opacity: 1;
  border-radius: 2px;
}
.xiao:hover {
  color: #2c68ff;
  border: 1px solid #2c68ff;
}

.input-owner {
  width: 100px;
  // width: 140px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  box-sizing: border-box;
  margin-left: 20px;
  border-radius: 2px;
  outline: none;
  padding-left: 12px;
}

.owner{
  width: 500px;
  height: 21px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 21px;
  padding-left: 7px;
  margin-left: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
  position: relative;
  margin-top: 20px;
}

.tiao1{
  width: 3px;
  height: 15px;
  float: left;
  background: #2c68ff;
  opacity: 1;
  border-radius: 0px;
}

.tiao1 {
  position: absolute;
  top: 4px;
  left: 0;
}

.owner-log {
  // width: 500px;
  // background-color: pink;
  max-height: 200px;
  overflow-y: auto;
}

.rizhi {
  margin-left: 10px;
  margin-bottom: 10px;
  display: flex;
}
.rizhileft {
  width: 400px;
  background-color: pink;
  display: flex;
}
.rizhiTit,
.rizhiMain {
  font-size: 14px;
  font-weight: 400;
  color: #2a3346;

  box-sizing: border-box;
  display: inline-block;
}
.rizhiTit {
  width: 100px;
}
.rizhiTxt {
  width: 200px;
  font-size: 14px;
  color: #2c68ff;
  margin-left: 10px;
}
.rizhiMain {
  width: 100px;
  display: inline-block;
  margin-left: 10px;
  box-sizing: border-box;
}

.rileft {
  display: flex;
  // min-width: 450px;
  box-sizing: border-box;
}

</style>