<template>
  <div @click.stop>
    <!-- 创建订单 -->
    <div class="box">
      <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>创建订单</h1>
        </div>
        <!-- 基本信息 -->
        <div class="foundbox">
          <div class="foundtit">基本信息</div>
          <!-- 合作单位 -->
          <div class="unit basic">
            <div class="xing">&nbsp;&nbsp;</div>
            <label class="join" for="">合作单位</label>
            <el-select
              filterable
              class="input-unit"
              id="belongCid"
              v-model="this.belongCid"
              @change="changebelongCid"
            >
              <el-option
                v-for="item in hzlist"
                :key="item.cid"
                :label="item.name"
                :value="item.cid"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 下单人 -->
          <div class="unit" v-if="xiadan.length > 0 && companytype > 2">
            <div class="xing">&nbsp;&nbsp;</div>
            <label class="join" for="">下单人&#12288;</label>
            <!-- 下单人姓名 -->
            <el-select
              class="xiaInput"
              v-model="giveuser"
              @change="changeren"
              placeholder="请选择下单人"
            >
              <el-option
                v-for="item in xiadan"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 订单类型 -->
          <div class="unit yutime">
            <div class="xing">&nbsp;&nbsp;</div>
            <label class="join">订单类型</label>
            <div class="yuradio">
              <el-radio
                v-model="radio1"
                v-if="showjs"
                label="1"
                size="large"
                @change="funTime"
                >即时单</el-radio
              >
              <el-radio
                v-model="radio1"
                label="2"
                v-if="showyy"
                size="large"
                @change="funTime"
                >预约单</el-radio
              >
              <el-radio
                v-model="radio1"
                label="3"
                v-if="showsl"
                size="large"
                @change="funTime"
                >顺路单</el-radio
              >
            </div>

            <div class="yuetimebox" v-show="true">
              <el-date-picker
                class="yuTime"
                v-model="value1"
                type="datetime"
                placeholder="请选择时间"
                size="default"
              >
              </el-date-picker>
            </div>
          </div>
        </div>

        <div class="foundbox">
          <div class="foundtit">车主信息</div>
          <div class="unit">
            <div class="carnameleft">
              <div class="xing">*</div>
              <label class="join" for="">车主姓名</label>
              <div class="leixing">
                <input
                  class="input-owner1"
                  v-model="name"
                  type="text"
                  placeholder="车主姓名"
                  id="rescueName"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="carnameleft">
              <div class="xing">*</div>
              <label class="join" for="">手机号&#12288;</label>
              <div class="leixing">
                <input
                  class="input-owner"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  v-model="mobile"
                  type="text"
                  placeholder="手机号"
                  id="rescueMobile"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>

          <!-- 服务项目 -->
          <div class="unit">
            <div class="carnameleft">
              <div class="xing">&nbsp;</div>
              <label class="join" for="">服务项目</label>
              <el-select
                class="input-item"
                id="rescueType"
                v-model="this.rescueType"
                @change="changerescuetype"
              >
                <el-option
                  v-for="item in rescueList"
                  :key="item.rescueType"
                  :label="item.rescueName"
                  :value="item.rescueType"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 车型选择 -->
            <div class="carnameleft">
              <div class="xing">&nbsp;&nbsp;</div>
              <label class="join">车型选择</label>
              <el-cascader
                :options="options"
                placeholder="选择其他"
                @change="xuancar"
                v-model="cartype"
              />
            </div>
          </div>
          <!-- 车牌车架 -->
          <div class="newunit">
            <div
              class="newcarnameleft"
              v-for="(item, index) in carnumList"
              :key="index"
            >
              <div class="xing">*</div>
              <label class="join" for="">车牌车架</label>
              <input
                class="input-owner"
                v-model="item.text"
                type="text"
                placeholder="车牌号/车架号"
                id="rescueNumber"
                autocomplete="off"
              />
              <img
                class="addimg"
                :src="item.isadd ? addimg1 : addimg2"
                alt=""
                @click="addcarnum(item, index)"
              />
            </div>
          </div>
        </div>



        <!-- 任务信息 -->
        <div class="foundbox">
          <div class="foundtit">任务信息
          <div class="xuannum">
              <input v-model="number" readonly="true" style="width: 0.885417rem;
    height: 0.166667rem;
    background: #ffffff;
    border: 0.005208rem solid #dadfe6;
    opacity: 1;
    box-sizing: border-box;
    border-radius: 0.010417rem;
    outline: none;
    width:40px;
    text-align: center;
"/>
            </div></div>
          <!-- 任务类型 -->
          <div class="unit yutime" v-if="isTuo">
            <div class="xing">&nbsp;&nbsp;</div>
            <label class="join">任务类型</label>
            <div class="yuradio">
              <el-radio v-model="isrescue" label="1" size="large"
                >道路救援</el-radio
              >
              <el-radio v-model="isrescue" label="2" size="large"
                >整车运输</el-radio
              >
            </div>
          </div>
          <!-- 发车  接车 -->
          <div class="weituo" v-if="isTuo">
            <div class="entrust">
              <div class="carnameleft">
                <div class="xing">&nbsp;&nbsp;</div>
                <label class="join" for="">发车姓名</label>
                <input
                  class="fache"
                  type="text"
                  v-model="name1"
                  autocomplete="off"
                  placeholder="请输入发车人姓名"
                />
              </div>
              <div class="carnameright">
                <div class="xing">&nbsp;&nbsp;</div>
                <label class="join" for="">发车电话</label>
                <input
                  class="fache"
                  type="text"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="11"
                  v-model="tel1"
                  autocomplete="off"
                  placeholder="请输入发车人电话"
                />
              </div>
            </div>
            <div class="entrust">
              <div class="carnameleft">
                <div class="xing">&nbsp;&nbsp;</div>
                <label class="join" for="">接车姓名</label>
                <input
                  class="fache"
                  type="text"
                  v-model="name2"
                  autocomplete="off"
                  placeholder="请输入接车人姓名"
                />
              </div>

              <div class="carnameright">
                <div class="xing">&nbsp;&nbsp;</div>
                <label class="join" for="">接车电话</label>
                <input
                  class="fache"
                  type="text"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="11"
                  v-model="tel2"
                  autocomplete="off"
                  placeholder="请输入接车人电话"
                />
              </div>
            </div>
          </div>

          <!-- 任务点 -->
          <div class="unit rending">
            <div class="xing">*</div>
            <label class="join" for="">任务点&#12288;</label>
            <div class="dingren">
              <input
                v-model="address"
                id="address"
                class="accident-input"
                @blur="blur"
                type="text"
                autocomplete="off"
              />
              <img
                @click="renWei(1)"
                class="mapImg"
                src="../assets/img/cd/dingwei.png"
                alt=""
              />

              <el-button
                type="primary"
                @click="foundyu(this.belongCid)"
                v-if="yushenum > 0 ? true : false"
                >预设</el-button
              >
            </div>

            <div class="select-panel" v-show="isShow">
              <ul>
                <li
                  class="der"
                  v-for="(item, index) in words"
                  :key="index"
                  @click="map(index)"
                >
                  {{ item.label }}
                </li>
              </ul>
            </div>

            <input class="input-norm1" type="hidden" v-model="addressLnglat" />
          </div>
          <!-- 切换位置 -->
          <div class="qieBox" v-if="isTuo">
            <img
              class="qie"
              src="../assets/img/cd/qie.png"
              alt=""
              @click="changeaddress"
            />
          </div>
          <!-- 目的地 -->
          <div class="unit jianju rending" v-if="isTuo">
            <div class="xing">*</div>
            <label class="join" for="">目的地&#12288;</label>
            <div class="dingren">
              <input
                v-model="destination"
                class="accident-input"
                @blur="blur2"
                type="text"
                autocomplete="off"
              />

              <img
                @click="renWei(2)"
                class="mapImg"
                src="../assets/img/cd/dingwei.png"
                alt=""
              />

              <el-button type="primary" @click="huoqu">获取</el-button>
            </div>
            <div class="select-panel" v-show="isShow2">
              <ul>
                <li
                  class="der"
                  v-for="(item, index) in words2"
                  :key="index"
                  @click="map2(index)"
                >
                  {{ item.label }}
                </li>
              </ul>
            </div>
            <input
              class="input-norm1"
              type="hidden"
              v-model="destinationLnglat"
            />
          </div>
        </div>

        <!-- 收费信息 -->
        <div class="foundbox">
          <div class="foundtit">收费信息</div>
          <!-- 收费标准 -->
          <div class="unit">
            <div class="xing">&nbsp;&nbsp;</div>
            <label class="join">收费标准</label>
            <div class="jiage">
              <el-select
                class="item-chargetype"
                v-model="chargetype"
                @change="changechargetype"
              >
                <el-option
                  v-for="item in chargelist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <input
                class="input-norm"
                type="text"
                v-model="guidePrice"
                v-if="ismaster == true"
              />
              <input
                class="input-norm"
                type="text"
                v-model="guidePrice"
                v-else
                readonly
              />
              <div class="yuan">元</div>
              <div v-if="chargetype == 2" style="float: left">
                <input
                  class="input-norm"
                  type="text"
                  v-model="guideKm"
                  v-if="ismaster == true"
                />
                <input
                  class="input-norm"
                  type="text"
                  v-model="guideKm"
                  v-else
                  readonly
                />
                <div class="yuan1">公里</div>
                <input
                  class="input-norm1"
                  type="text"
                  v-model="kmPrice"
                  v-if="ismaster == true"
                />
                <input
                  class="input-norm1"
                  type="text"
                  v-model="kmPrice"
                  v-else
                  readonly
                />
                <div class="yuan2">元/公里</div>
              </div>
            </div>
          </div>
          <!-- 收费类型 -->
          <div class="unit">
            <div class="xing">&nbsp;&nbsp;</div>
            <div class="join">收费类型</div>
            <el-radio v-model="isGua" label="0" size="large">现金</el-radio>
            <el-radio v-model="isGua" label="1" size="large">挂账</el-radio>
            <el-select
              class="input-guakm"
              id="guaKm"
              v-model="this.guaKm"
              @change="changeguakm"
              v-if="this.isGua == 1 && this.chargetype == 2 && isTuo"
            >
              <el-option
                v-for="item in guakmlist"
                :key="item.guaKm"
                :label="item.name"
                :value="item.guaKm"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 路桥费 -->
          <div class="unit" v-if="isTuo">
            <div class="xing">&nbsp;&nbsp;</div>
            <div class="join">路桥费&#12288;</div>
            <el-radio v-model="bridgeStyle" label="0" size="large"
              >车主付</el-radio
            >
            <el-radio v-model="bridgeStyle" label="1" size="large"
              >拖车公司承担</el-radio
            >
          </div>
        </div>

        <!-- 其他信息 -->
        <div class="foundbox">
          <div class="foundtit">其他信息</div>
          <!-- 编号 -->
          <div class="unit">
            <div class="xing">&nbsp;&nbsp;</div>
            <label class="join" for="">编号&#12288;&#12288;</label>
            <input class="accident-input1" type="text" v-model="ticketId" />
          </div>
          <!-- 备注 -->
          <div class="unit">
            <div class="xing">&nbsp;&nbsp;</div>
            <label class="join" for="">备注&#12288;&#12288;</label>
            <el-input
              class="text"
              v-model="textarea1"
              autosize
              type="textarea"
              placeholder="请输入备注信息"
            />
          </div>

          <!-- 提示备注 -->
          <div class="beizhu" v-if="companyremark">
            <img class="tiImg" src="../assets/img/index/Vector.png" alt="" />
            <span class="tiTit">{{ companyremark }}</span>
          </div>
        </div>

        <!-- 预估 -->
        <div class="unit">
          <div class="xing">&nbsp;&nbsp;</div>
          <label class="join" for="">预估&#12288;&#12288;</label>
          <span class="mileage">{{ estimatekm }}公里</span>
          <span class="mileageprice">{{ estimateprice }}元</span>
        </div>

        <div class="btn">
          <!-- <button class="set"  id="set"  @click="create">创建</button> -->
          <el-button class="set" id="set" :plain="true" @click="create"
            >创建</el-button
          >
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>

    <search
      :isWei="weizhi"
      :isaddr="isaddrs"
      :foundaddr="foundaddr1"
      :foundlnglat="foundlnglat1"
      @addr1="addr"
      @addr1lnglat="addrlnglat"
      @addr2="dest"
      @addr2lnglat="destlnglat"
      @hide="hide"
    ></search>
    <presets
      :yushecid="yucid"
      :xuandan="xuandan"
      @closeyu="closeyu"
      v-if="jianyu"
      :isreadonly="false"
      @yuren="yuren"
      @yumu="yumu"
      @yurenlnglat="yurenlnglat"
      @yumulnglat="yumulnglat"
      @preline="preline"
    ></presets>
  </div>
</template>

<script>
// import { ElMessage, ElMessageBox } from 'element-plus'
import { ElMessage, ElMessageBox } from "element-plus";
import { ref } from "vue";
import * as api from "../api/index";
import * as api1 from "../api/join";
import search from "./search.vue";
import Presets from "./Presets.vue";
import { antiShake } from "../util/shaking.js";
const radio1 = ref("1");
const radio2 = ref("0");
const radio3 = ref("1");
const textarea1 = ref("");
const value1 = ref("");
const defaultTime = new Date(2000, 1, 1, 12, 0, 0);

const shortcuts = [
  {
    text: "Today",
    value: new Date(),
  },
  {
    text: "Yesterday",
    value: () => {
      const date = new Date();
      date.setTime(date.getTime() - 3600 * 1000 * 24);
      return date;
    },
  },
  {
    text: "A week ago",
    value: () => {
      const date = new Date();
      date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
      return date;
    },
  },
];

export default {
  components: { search, Presets },
  props: ["isset"],
  data() {
    return {
      weizhi: false,
      issets: this.isset,
      defaultTime,
      shortcuts,
      radio1: "",
      radio2,
      radio3,
      textarea1:'',
      value1: "",
      over: false,
      sex: "male",
      newvals: "",
      newvals2: "",
      label: "",
      words: [],
      isShow: false,
      words2: [],
      isaddrs: "",
      isShow2: false,
      isTuo: true,
      rescueType: 1,
      rescueList: [],
      hzlist: [],
      xiadan: [],
      belongCid: 1,
      name1: "",
      name2: "",
      tel1: "",
      tel2: "",
      chargetype: 1,
      chargelist: [
        { id: 1, name: "一口价" },
        { id: 2, name: "公里价" },
      ],
      guakmlist: [{ guaKm: 9999, name: "全程免托" }],
      guaKm: 9999,
      isGua: "0",
      guidePrice: 0,
      guideKm: 0,
      kmPrice: 0,
      onePricelist: [],
      kmPricelist: [],
      address: "",
      destination: "",
      addressLnglat: "",
      destinationLnglat: "",
      oldaddressLnglat: "",
      olddestinationLnglat: "",
      ticketId: "",
      bridgeStyle: "0",
      name: "",
      carnumber: "",
      mobile: "",
      foundaddr1: "",
      foundlnglat1: "", // 靠这两个值来传递input的值，点击图标显示地图会改变值，所以预留默认值来监听变化
      companyremark: "",
      is_entrust: 0,
      givename: "",
      givemobile: "",
      fours: false,
      companytype: 0,
      giveuser: "",

      isrescue: "2",
      carxuan: "",
      options: [],
      all: [],
      yincar: true,
      cartype: ["轿车"],
      cartypename: "轿车",
      needcar: "2T平板拖车",
      selectid: '35',

      jianyu: false,
      yucid: "",
      jishiprice: "",
      yushenum: "",
      selectline: "",
      ismaster: true,
      yuetime: false,

      estimatekm: "",
      estimateprice: "",
      estimatelng: "",
      estimatelat: "",
      estimatedlng: "",
      estimatedlat: "",
      number:1,

      showjs:true,
      showyy:true,
      showsl:true,
      
      carnumList: [
        {
          text: "",
          isadd: true,
        },
      ],
      addimg1: require("../assets/img/slices5/price-icon-addbtn.png"),
      addimg2: require("../assets/img/slices5/price-icon-minus-btn.png"),

      plancal:0,
    };
  },
  //获得初始
  async created() {
    let res_gethz = await api.getHzlist();
    this.hzlist = res_gethz.data;
    //this.getcartype();
    this.ismaster = res_gethz.ismaster;

    this.belongCid = this.hzlist[0].cid;
    this.isrescue = this.hzlist[0].isrescue;
    this.companyremark = this.hzlist[0].companyremark;
    this.yushenum = this.hzlist[0].preline;
    this.plancal = this.hzlist[0].plan_cal;
    this.companytype = parseInt(this.hzlist[0].type);
    this.showjs = this.hzlist[0].js == 1?true:false;
    this.showyy = this.hzlist[0].yy == 1?true:false;
    this.showsl = this.hzlist[0].sl == 1?true:false;

    if(this.showjs)
    {
      this.radio1 = '1';
    }else if(this.showyy)
    {
      this.radio1 = '2';
    }else if(this.showsl)
    {
      this.radio1 = '3';
    }

    this.fours = this.hzlist[0].is_carprice > 0 ? true : false;

    this.getcartypenew(this.belongCid);

    let res_rescuelist = await api.getservicelist(this.belongCid);
    if (res_rescuelist.code == 1) {
      this.rescueList = res_rescuelist.data;
      this.rescueType = this.rescueList[0].rescueType;
      if (
        this.rescueType == 1 ||
        this.rescueType == 2 ||
        this.rescueType == 19
      ) {
        this.isTuo = true;
      }

      this.changerescuetype();
    } else {
      //this.$message.warning(res_rescuelist.message);
    }

    this.diaoyong();
  },

  methods: {
    addcarnum(item, index) {
      if (item.isadd) {
        this.carnumList.push({
          text: "",
        });
        this.number++
      } else {
        this.carnumList.splice(index, 1);
        this.number--
      }
    },
    close() {
      //this.issets = false; // 关闭订单页面
      this.yincar = true;
      Object.assign(this.$data, this.$options.data());
      this.$emit('ovfound');
    },
    hide() {
      this.weizhi = false; // 关闭地图组件
    },
    closeyu() {
      this.jianyu = false;
    },
    foundyu() {
      this.jianyu = true;
      this.yucid = this.belongCid;
      this.xuandan = this.radio1;
      console.log("合作单位cid---", this.belongCid);
    },

    xuancar() {
      if (this.cartype.length == 1) {
        let ret = this.all.find((ev) => {
          return ev.rescued_name == this.cartype[0];
        });

        console.log("选择车型", ret);

        if (this.isTuo == true && this.fours == true) {
          if (this.selectline == "") {
            this.chargetype = 2;
            this.guidePrice = ret.price;
            this.guideKm = ret.free;
            this.kmPrice = ret.exceed;
          }
          this.cartypename = this.cartype[0];
          this.needcar = ret.type_name;
        } else {
          this.cartypename = this.cartype[0];
          this.needcar = this.isTuo ? ret.type_name : "";
        }
        this.selectid = ret.id;
      } else {
        let ret = this.all.find((ev) => {
          return (
            ev.rescued_name == this.cartype[0] &&
            ev.parameter == this.cartype[1]
          );
        });

        if (this.isTuo == true && this.fours == true) {
          if (this.selectline == "") {
            console.log(ret.price);
            console.log(ret.free);
            console.log(ret.exceed);
            this.chargetype = 2;
            this.guidePrice = ret.price;
            this.guideKm = ret.free;
            this.kmPrice = ret.exceed;
          }
          this.cartypename = this.cartype[0] + "/" + this.cartype[1];
          this.needcar = ret.type_name;
        } else {
          this.cartypename = this.cartype[0] + "/" + this.cartype[1];
          this.needcar = this.isTuo ? ret.type_name : "";
        }
        this.selectid = ret.id;
      }
    },
    funTime(label) {
      console.log("点击了：", label);
      if (label == 2) {
        this.yuetime = true;
      } else {
        this.yuetime = false;
      }
      this.over = label == "2" ? true : false;
      if (this.selectline != "") {
        if (label == "1") {
          this.chargetype = 1;
          this.guidePrice = this.selectline.price;
        } else if (label == "2") {
          this.chargetype = 1;
          this.guidePrice = this.selectline.price2;
        } else if (label == "3") {
          this.chargetype = 1;
          this.guidePrice = this.selectline.price3;
        }
      }
      this.diaoyong();
    },
    changeren() {
      let user = this.giveuser;
      if (this.xiadan.length > 0) {
        let changeRen = this.xiadan.filter(function (ele) {
          return ele.label == user;
        });

        this.givename = changeRen[0].name;
        this.givemobile = changeRen[0].mobile;

        console.log(this.givename);
      }
    },
    changeaddress() {
      let oa = this.address;
      let oal = this.addressLnglat;
      let oaa = this.oldaddressLnglat;
      let od = this.destination;
      let odl = this.destinationLnglat;
      let oda = this.olddestinationLnglat;
      this.address = od;
      this.addressLnglat = odl;
      this.oldaddressLnglat = oda;
      this.destination = oa;
      this.destinationLnglat = oal;
      this.olddestinationLnglat = oaa;
    },

    renWei(id) {
      // 任务点：1，目的地：2
      // 点击任务点，传入选择的值，触发地图组件显示
      this.weizhi = true;
      this.isaddrs = id;
      if (this.isaddrs == 1) {
        this.foundaddr1 = this.address;
        this.foundlnglat1 = this.addressLnglat;
        console.log("任务点1输入地址1：", this.foundaddr1);
        console.log("任务点1输入地址2：", this.foundlnglat1);
      } else {
        this.foundaddr1 = this.destination;
        this.foundlnglat1 = this.destinationLnglat;
        console.log("目的地2输入地址1：", this.foundaddr1);
        console.log("目的地2输入地址2：", this.foundlnglat1);
      }
    },

    async diaoyong() {
      if(this.estimatedlat == '' || this.estimatelat == '' || this.estimatelng == '' || this.estimatedlng == '')
      {
        return false;
      }
      let data = {
        cid: this.belongCid,
        lng: this.estimatelng,
        lat: this.estimatelat,
        dlng: this.estimatedlng,
        dlat: this.estimatedlat,
        price: this.guidePrice,
        km: this.guideKm,
        per: this.kmPrice,
        needcar: this.needcar,
      };
      let res = await api1.estimate(data);
      //console.log("预估预估", res.data);
      this.estimatekm = res.data.plankm;
      if(this.chargetype == 2)
      {
        let discount = 1;
        if(this.radio1 == '1')
        {
          discount = res.data.discount1;
        }else if(this.radio1 == '2')
        {
           discount = res.data.discount2;
        }else if(this.radio1 == '3')
        {
           discount = res.data.discount3;
        }

        this.estimateprice = (res.data.planprice * discount).toFixed(2);
      }else{
        this.estimateprice = this.guidePrice;
      }
      
    },

    //创建订单
    create:antiShake( async function() {

      if(this.plancal == 1 && (this.rescueType == 1 || this.rescueType == 2 || this.rescueType == 19) && this.address == this.destination)
      {
        ElMessageBox.confirm("此合作商订单为规划公里进行结算，请确认任务点和目的地(不能为同一个地方)", "提示", {
        confirmButtonText: "继续创建",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let post_cid = this.belongCid;
      let post_rescuestyle = this.radio1;
      if (this.value1 != "" && this.value1 != null) {
        var post_appointmentTime = parseInt(this.value1.getTime() / 1000);
      } else {
        var post_appointmentTime = 0;
      }

      let carNumber = '';
      let otherCarNumber = [];
      this.carnumList.forEach(function(eve){
          if(eve.isadd == true){
            carNumber = eve.text;
          }else{
            if(eve.text  != '')
            {
              otherCarNumber.push(eve.text)
            }else{
              otherCarNumber.push(carNumber)
            }
          }
      });

      if(carNumber == '')
      {
        this.$message.warning("请填写车牌车架号");
        return;
      }


      let post_rescueType = this.rescueType;
      let post_rescueName = rescueName.value;
      let post_rescueNumber = carNumber;
      let post_mobile = rescueMobile.value;
      let post_chargetype = this.chargetype;
      let post_isGua = this.isGua;
      let post_guidePrice = this.guidePrice;
      let post_guideKm = this.guideKm;
      let post_kmPrice = this.kmPrice;
      let post_guaKm = this.guaKm;
      let post_address = this.address;
      let post_destination = this.destination;
      let post_addressLnglat = this.addressLnglat;
      let post_destinationLnglat = this.destinationLnglat;
      let post_ticketId = this.ticketId;
      let post_remark = this.textarea1;
      let post_bridgestyle = this.bridgeStyle;
      let name1 = this.isTuo == true ? this.name1 : "";
      let name2 = this.isTuo == true ? this.name2 : "";
      let tel1 = this.isTuo == true ? this.tel1 : "";
      let tel2 = this.isTuo == true ? this.tel2 : "";
      let givename = this.givename;
      let givemobile = this.givemobile;
      let is_entrust = 0;

      let post_otherCarNumber = '';
      
      if(otherCarNumber.length > 0)
      {
          otherCarNumber.forEach(function(p){
             if(post_otherCarNumber == '')
             {
                post_otherCarNumber = p;
             }else{
                post_otherCarNumber += ','+ p;
             }
          })
      }

      // console.log(post_rescueNumber);
      // console.log(post_otherCarNumber);
      // return false;

      if(post_rescuestyle == '')
      {
        this.$message.warning("请选择订单类型");
        return;
      }

      if (post_rescuestyle == 2 && this.value1 == "") {

      }

      if (
        post_rescuestyle != 2 &&
        post_appointmentTime > 0 &&
        post_appointmentTime < parseInt(new Date().getTime() / 1000)
      ) {
        this.$message.warning("救援时间不能早于当前时间");
        return;
      }

      if (
        post_rescuestyle == 2 &&
        post_appointmentTime - 18000 <= parseInt(new Date().getTime() / 1000)
      ) {
        this.$message.warning("预约时间须5小时起");
        return;
      }

      if (post_rescueNumber == "") {
        this.$message.warning("请输入车牌号或车架号");
        return;
      }
      if (post_mobile == "") {
        this.$message.warning("请输入联系电话");
        return;
      }
      if (/^[0-9][0-9- ]{6,10}[0-9]$/.test(post_mobile) !== true) {
        this.$message.warning("请输入正确的手机号码");
        return;
      }

      if (post_address == "") {
        this.$message.warning("请输入事故点");
        return;
      }
      if (post_addressLnglat == "") {
        this.$message.warning("请重现选择事故点");
        return;
      }

      if (post_destination == "" && this.isTuo == true) {
        this.$message.warning("请输入目的地");
        return;
      }
      if (post_destinationLnglat == "" && this.isTuo == true) {
        this.$message.warning("请选择目的地");
        return;
      }
      if (
        (givename != "" && givemobile == "") ||
        (givename == "" && givemobile != "")
      ) {
        this.$message.warning("请补全下单人信息");
        return;
      }
      if ((name1 != "" && tel1 == "") || (name1 == "" && tel1 != "")) {
        this.$message.warning("请补全发车人信息");
        return;
      }

      if ((name2 != "" && tel2 == "") || (name2 == "" && tel2 != "")) {
        this.$message.warning("请补全接车人信息");
        return;
      }
      if(this.number == '' || this.number == 0)
      {
        this.$message.warning("请输入创建订单个数");
        return;
      }

      let zz = /^[1-9]\d*$/;

      if(!zz.test(this.number))
      {
        this.$message.warning("请正确输入创建订单个数");
        return;
      }

      if(this.number > 1)
      {
        this.$message.success("创建多个订单请耐心等待");
      }

      if(this.number == 1)
      {
        let res = await api.createOrder(
                post_cid,
                post_rescuestyle,
                post_appointmentTime,
                post_rescueType,
                post_rescueName,
                post_mobile,
                post_rescueNumber,
                post_address,
                post_addressLnglat,
                post_destination,
                post_destinationLnglat,
                post_isGua,
                post_guaKm,
                post_remark,
                post_ticketId,
                post_chargetype,
                post_guidePrice,
                post_guideKm,
                post_kmPrice,
                post_bridgestyle,
                name1,
                tel1,
                name2,
                tel2,
                givename,
                givemobile,
                is_entrust,
                this.isrescue,
                this.cartypename,
                this.needcar,
                post_appointmentTime
              );
              if (res.code == 1) {
                this.$message.success("创建成功");
                this.close();
              } else {
                this.$message.warning(res.message);
                return;
              }
      }else{
          let res = await api.createOrderDuptCar(
                  post_cid,
                  post_rescuestyle,
                  post_appointmentTime,
                  post_rescueType,
                  post_rescueName,
                  post_mobile,
                  post_rescueNumber,
                  post_address,
                  post_addressLnglat,
                  post_destination,
                  post_destinationLnglat,
                  post_isGua,
                  post_guaKm,
                  post_remark,
                  post_ticketId,
                  post_chargetype,
                  post_guidePrice,
                  post_guideKm,
                  post_kmPrice,
                  post_bridgestyle,
                  name1,
                  tel1,
                  name2,
                  tel2,
                  givename,
                  givemobile,
                  is_entrust,
                  this.isrescue,
                  this.cartypename,
                  this.needcar,
                  post_appointmentTime,
                  post_otherCarNumber
                );
                if (res.code == 1) {
                  this.$message.success("创建成功");
                  this.close();
                } else {
                  this.$message.warning(res.message);
                  return;
                }
      }         
        })
        .catch(() => {
          this.$message.warning("取消");
          return false;
        });
      }else{
        let post_cid = this.belongCid;
      let post_rescuestyle = this.radio1;
      if (this.value1 != "" && this.value1 != null) {
        var post_appointmentTime = parseInt(this.value1.getTime() / 1000);
      } else {
        var post_appointmentTime = 0;
      }

      let carNumber = '';
      let otherCarNumber = [];
      this.carnumList.forEach(function(eve){
          if(eve.isadd == true){
            carNumber = eve.text;
          }else{
            if(eve.text  != '')
            {
              otherCarNumber.push(eve.text)
            }else{
              otherCarNumber.push(carNumber)
            }
          }
      });

      if(carNumber == '')
      {
        this.$message.warning("请填写车牌车架号");
        return;
      }


      let post_rescueType = this.rescueType;
      let post_rescueName = rescueName.value;
      let post_rescueNumber = carNumber;
      let post_mobile = rescueMobile.value;
      let post_chargetype = this.chargetype;
      let post_isGua = this.isGua;
      let post_guidePrice = this.guidePrice;
      let post_guideKm = this.guideKm;
      let post_kmPrice = this.kmPrice;
      let post_guaKm = this.guaKm;
      let post_address = this.address;
      let post_destination = this.destination;
      let post_addressLnglat = this.addressLnglat;
      let post_destinationLnglat = this.destinationLnglat;
      let post_ticketId = this.ticketId;
      let post_remark = this.textarea1;
      let post_bridgestyle = this.bridgeStyle;
      let name1 = this.isTuo == true ? this.name1 : "";
      let name2 = this.isTuo == true ? this.name2 : "";
      let tel1 = this.isTuo == true ? this.tel1 : "";
      let tel2 = this.isTuo == true ? this.tel2 : "";
      let givename = this.givename;
      let givemobile = this.givemobile;
      let is_entrust = 0;

      let post_otherCarNumber = '';
      
      if(otherCarNumber.length > 0)
      {
          otherCarNumber.forEach(function(p){
             if(post_otherCarNumber == '')
             {
                post_otherCarNumber = p;
             }else{
                post_otherCarNumber += ','+ p;
             }
          })
      }

      // console.log(post_rescueNumber);
      // console.log(post_otherCarNumber);
      // return false;

      if(post_rescuestyle == '')
      {
        this.$message.warning("请选择订单类型");
        return;
      }

      if (post_rescuestyle == 2 && this.value1 == "") {

      }

      if (
        post_rescuestyle != 2 &&
        post_appointmentTime > 0 &&
        post_appointmentTime < parseInt(new Date().getTime() / 1000)
      ) {
        this.$message.warning("救援时间不能早于当前时间");
        return;
      }

      if (
        post_rescuestyle == 2 &&
        post_appointmentTime - 18000 <= parseInt(new Date().getTime() / 1000)
      ) {
        this.$message.warning("预约时间须5小时起");
        return;
      }

      if (post_rescueNumber == "") {
        this.$message.warning("请输入车牌号或车架号");
        return;
      }
      if (post_mobile == "") {
        this.$message.warning("请输入联系电话");
        return;
      }
      if (/^[0-9][0-9- ]{6,10}[0-9]$/.test(post_mobile) !== true) {
        this.$message.warning("请输入正确的手机号码");
        return;
      }

      if (post_address == "") {
        this.$message.warning("请输入事故点");
        return;
      }
      if (post_addressLnglat == "") {
        this.$message.warning("请重现选择事故点");
        return;
      }

      if (post_destination == "" && this.isTuo == true) {
        this.$message.warning("请输入目的地");
        return;
      }
      if (post_destinationLnglat == "" && this.isTuo == true) {
        this.$message.warning("请选择目的地");
        return;
      }
      if (
        (givename != "" && givemobile == "") ||
        (givename == "" && givemobile != "")
      ) {
        this.$message.warning("请补全下单人信息");
        return;
      }
      if ((name1 != "" && tel1 == "") || (name1 == "" && tel1 != "")) {
        this.$message.warning("请补全发车人信息");
        return;
      }

      if ((name2 != "" && tel2 == "") || (name2 == "" && tel2 != "")) {
        this.$message.warning("请补全接车人信息");
        return;
      }
      if(this.number == '' || this.number == 0)
      {
        this.$message.warning("请输入创建订单个数");
        return;
      }

      let zz = /^[1-9]\d*$/;

      if(!zz.test(this.number))
      {
        this.$message.warning("请正确输入创建订单个数");
        return;
      }

      if(this.number > 1)
      {
        this.$message.success("创建多个订单请耐心等待");
      }

      if(this.number == 1)
      {
        let res = await api.createOrder(
                post_cid,
                post_rescuestyle,
                post_appointmentTime,
                post_rescueType,
                post_rescueName,
                post_mobile,
                post_rescueNumber,
                post_address,
                post_addressLnglat,
                post_destination,
                post_destinationLnglat,
                post_isGua,
                post_guaKm,
                post_remark,
                post_ticketId,
                post_chargetype,
                post_guidePrice,
                post_guideKm,
                post_kmPrice,
                post_bridgestyle,
                name1,
                tel1,
                name2,
                tel2,
                givename,
                givemobile,
                is_entrust,
                this.isrescue,
                this.cartypename,
                this.needcar,
                post_appointmentTime
              );
              if (res.code == 1) {
                this.$message.success("创建成功");
                this.close();
              } else {
                this.$message.warning(res.message);
                return;
              }
      }else{
          let res = await api.createOrderDuptCar(
                  post_cid,
                  post_rescuestyle,
                  post_appointmentTime,
                  post_rescueType,
                  post_rescueName,
                  post_mobile,
                  post_rescueNumber,
                  post_address,
                  post_addressLnglat,
                  post_destination,
                  post_destinationLnglat,
                  post_isGua,
                  post_guaKm,
                  post_remark,
                  post_ticketId,
                  post_chargetype,
                  post_guidePrice,
                  post_guideKm,
                  post_kmPrice,
                  post_bridgestyle,
                  name1,
                  tel1,
                  name2,
                  tel2,
                  givename,
                  givemobile,
                  is_entrust,
                  this.isrescue,
                  this.cartypename,
                  this.needcar,
                  post_appointmentTime,
                  post_otherCarNumber
                );
                if (res.code == 1) {
                  this.$message.success("创建成功");
                  this.close();
                } else {
                  this.$message.warning(res.message);
                  return;
                }
      }
      }
      
    }),

    //更换合作单位 更新对应
    async changebelongCid(value) {
      console.log("选中了：", value, this.belongCid);

      let res_rescuelist = await api.getservicelist(this.belongCid);
      let nowcid = this.belongCid;

      this.givename = "";
      this.givemobile = "";
      let newArr = this.hzlist.filter(function (ele) {
        return ele.cid == nowcid;
      });
      newArr.forEach((item) => {
        return (this.yushenum = item.preline);
      });

      if (res_rescuelist.code == 1) {
        this.rescueList = res_rescuelist.data;
        this.rescueType = this.rescueList[0].rescueType;
        this.companyremark = newArr[0].companyremark;
        this.changerescuetype();
        if (newArr[0].is_carprice > 0) {
          this.fours = true;
          this.companytype = newArr[0].type;
          this.xiadan = newArr[0].userlist;
          this.isrescue = newArr[0].isrescue;
          this.showjs = newArr[0].js == 1?true:false;
          this.showyy = newArr[0].yy == 1?true:false;
          this.showsl = newArr[0].sl == 1?true:false;
          this.plancal = newArr[0].plan_cal;
          this.giveuser = "";
          this.givename = "";
          this.givemobile = "";
          this.getcartypenew(nowcid);
          //this.xuancar();
        } else {
          this.fours = false;
          this.companytype = newArr[0].type;
          this.xiadan = newArr[0].userlist;
          this.isrescue = newArr[0].isrescue;
          this.showjs = newArr[0].js == 1?true:false;
          this.showyy = newArr[0].yy == 1?true:false;
          this.showsl = newArr[0].sl == 1?true:false;
          this.plancal = newArr[0].plan_cal;
          this.giveuser = "";
          this.givename = "";
          this.givemobile = "";
          this.getcartype();
        }

        if(this.radio1 == '1' && !this.showjs)
        {
          console.log('即时属性失效')
          this.radio1 ='';
        }
        if(this.radio1 == '2' && !this.showyy)
        {
          console.log('预约属性失效')
          this.radio1 ='';
        }
        if(this.radio1 == '3' && !this.showsl)
        {
          console.log('顺路属性失效')
          this.radio1 ='';
        }
      } else {
        // alert(res_rescuelist.message);
        this.rescueList = [];
        this.rescueType = "";
        this.companyremark = "";
        if (newArr[0].is_carprice > 0) {
          this.fours = true;
          this.xiadan = newArr[0].userlist;
          this.companytype = newArr[0].type;
          this.isrescue = newArr[0].isrescue;
          this.showjs = newArr[0].js == 1?true:false;
          this.showyy = newArr[0].yy == 1?true:false;
          this.showsl = newArr[0].sl == 1?true:false;
          this.plancal = newArr[0].plan_cal;
          this.giveuser = "";
          this.givename = "";
          this.givemobile = "";
          this.getcartypenew(nowcid);
          //this.xuancar();
        } else {
          this.fours = false;
          this.xiadan = newArr[0].userlist;
          this.companytype = newArr[0].type;
          this.isrescue = newArr[0].isrescue;
          this.showjs = newArr[0].js == 1?true:false;
          this.showyy = newArr[0].yy == 1?true:false;
          this.showsl = newArr[0].sl == 1?true:false;
          this.plancal = newArr[0].plan_cal;
          this.giveuser = "";
          this.givename = "";
          this.givemobile = "";
          this.getcartype();
        }

        if(this.radio1 == '1' && !this.showjs)
        {
          console.log('即时属性失效')
          this.radio1 ='';
        }
        if(this.radio1 == '2' && !this.showyy)
        {
          console.log('预约属性失效')
          this.radio1 ='';
        }
        if(this.radio1 == '3' && !this.showsl)
        {
          console.log('顺路属性失效')
          this.radio1 ='';
        }

        this.selectline = "";
        this.$message.warning(res_rescuelist.message);
        return;
      }
    },
    //更换服务 更新对应
    async changerescuetype() {
      if (
        this.rescueType == 1 ||
        this.rescueType == 2 ||
        this.rescueType == 19
      ) {
        this.isTuo = true;
      } else {
        this.isTuo = false;
      }
      let res_pricelist = await api.getpricelist(
        this.belongCid,
        this.rescueType
      );
      //console.log(res_pricelist);
      this.onePricelist = res_pricelist.data.oneprice;
      this.kmPricelist = res_pricelist.data.kmPrice;
      if (res_pricelist.code == 1) {
        this.isGua = res_pricelist.data.isGua;
        this.bridgeStyle = res_pricelist.data.bridgestyle;
        if (this.isTuo && res_pricelist.data.kmPrice.length > 0) {
          this.chargetype = 2;
          this.guidePrice = res_pricelist.data.kmPrice[0].guidePrice;
          this.guideKm = res_pricelist.data.kmPrice[0].guideKm;
          this.kmPrice = res_pricelist.data.kmPrice[0].kmPrice;
          this.guaKm = res_pricelist.data.kmPrice[0].gua_km;
        } else {
          if (res_pricelist.data.oneprice.length > 0) {
            this.chargetype = 1;
            this.guidePrice = res_pricelist.data.oneprice[0].guidePrice;
          } else {
            this.chargetype = 2;
            this.guidePrice = res_pricelist.data.kmPrice[0].guidePrice;
            this.guideKm = res_pricelist.data.kmPrice[0].guideKm;
            this.kmPrice = res_pricelist.data.kmPrice[0].kmPrice;
            this.guaKm = res_pricelist.data.kmPrice[0].gua_km;
          }
        }
        var kmlist = res_pricelist.data.guaKm;
        if (kmlist.length > 0) {
          this.guakmlist = [];
          for (var i = 0; i < kmlist.length; i++) {
            if (kmlist[i] != 9999) {
              this.guakmlist.push({
                guaKm: kmlist[i],
                name: kmlist[i] + "公里",
              });
            } else {
              this.guakmlist.push({ guaKm: kmlist[i], name: "全程免拖" });
            }
          }
        }
      }

      if(this.fours == true)
      {
        this.getcartypenew(this.belongCid);
      }else{
        this.getcartype();
      }
    },
    //更换收费标准
    changechargetype() {
      if (this.chargetype == 1) {
        if (this.onePricelist.length > 0) {
          this.guidePrice = this.onePricelist[0].guidePrice;
        } else {
          //  alert('无一口价预设');
          this.$message.warning("无一口价预设");
          this.chargetype = 2;
        }
      } else {
        // 切换公里价时 先查询是否有车型价格体系 有则以车型价格体系为准
        if (this.isTuo == true && this.fours == true && this.selectid != "") {
          let ret = this.all.find((ev) => {
            return ev.id == this.selectid;
          });
          this.guidePrice = ret.price;
          this.guideKm = ret.free;
          this.kmPrice = ret.exceed;
        } else {
          if (this.kmPricelist.length > 0) {
            this.guidePrice = this.kmPricelist[0].guidePrice;
            this.guideKm = this.kmPricelist[0].guideKm;
            this.kmPrice = this.kmPricelist[0].kmPrice;
            this.guaKm = this.kmPricelist[0].gua_km;
          } else {
            //  alert('无公里价预设');
            this.$message.warning("无公里价预设");
            this.chargetype = 1;
          }
        }
      }
      this.diaoyong();
    },
    //更新免拖公里数
    changeguakm() {
      for (var k = 0; k < this.kmPricelist.length; k++) {
        if (this.kmPricelist[k].gua_km == this.guaKm && this.fours == false) {
          this.guidePrice = this.kmPricelist[k].guidePrice;
          this.guideKm = this.kmPricelist[k].guideKm;
          this.kmPrice = this.kmPricelist[k].kmPrice;
        }
      }
    },
    // 事故点输入框
    blur() {
      setTimeout(() => {
        this.isShow = false;
        console.log("失去焦点----");
      }, 200);
    },
    async search(newval) {
      this.newvals = newval;
      this.addressLnglat = "";
      let res = await api.workMap(this.newvals);
      this.words = res.data;
      if (this.words.length > 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    map(query) {
      this.oldaddressLnglat = this.words[query].value;
      this.address = this.words[query].value;
      this.addressLnglat = this.words[query].location;
      this.isShow = false;

      console.log("经纬度", this.addressLnglat);
      let jielng = this.addressLnglat.indexOf(",");
      let lng = this.addressLnglat.substring(0, jielng);
      let lat = this.addressLnglat.substring(jielng + 1, jielng.length);

      this.estimatelng = lng;
      this.estimatelat = lat;
      // console.log("截取经纬度",lat);
    },
    blur2() {
      setTimeout(() => {
        this.isShow2 = false;
        console.log("失去焦点----");
      }, 200);
    },
    async search2(newval) {
      this.newvals2 = newval;
      this.destinationLnglat = "";
      let res2 = await api.workMap(this.newvals2);
      this.words2 = res2.data;
      if (this.words2.length > 0) {
        this.isShow2 = true;
      } else {
        this.isShow2 = false;
      }
    },
    map2(query) {
      this.olddestinationLnglat = this.words2[query].value;
      this.destination = this.words2[query].value;
      this.destinationLnglat = this.words2[query].location;
      this.isShow2 = false;

      let jiedlng = this.destinationLnglat.indexOf(",");
      let dlng = this.destinationLnglat.substring(0, jiedlng);
      let dlat = this.destinationLnglat.substring(jiedlng + 1, jiedlng.length);

      this.estimatedlng = dlng;
      this.estimatedlat = dlat;

      console.log("经纬度", this.destinationLnglat);
    },
    addr(addr1) {
      this.oldaddressLnglat = addr1;
      this.address = addr1;
      this.isShow = false;
    },
    addrlnglat(addr1lnglat) {
      this.addressLnglat = addr1lnglat;
    },
    dest(addr2) {
      this.olddestinationLnglat = addr2;
      this.destination = addr2;
      this.isShow2 = false;
    },
    destlnglat(addr2lnglat) {
      this.destinationLnglat = addr2lnglat;
    },

    // 预设过来
    yuren(yuren) {
      this.oldaddressLnglat = yuren;
      this.address = yuren;
      this.isShow = false;
    },
    yurenlnglat(yurenlnglat) {
      this.addressLnglat = yurenlnglat;
      let jielng = this.addressLnglat.indexOf(",");
      let lng = this.addressLnglat.substring(0, jielng);
      let lat = this.addressLnglat.substring(jielng + 1, jielng.length);

      this.estimatelng = lng;
      this.estimatelat = lat;
    },
    yumu(yumu) {
      this.olddestinationLnglat = yumu;
      this.destination = yumu;
      this.isShow2 = false;
    },
    yumulnglat(yumulnglat) {
      this.destinationLnglat = yumulnglat;
      let jiedlng = this.destinationLnglat.indexOf(",");
      let dlng = this.destinationLnglat.substring(0, jiedlng);
      let dlat = this.destinationLnglat.substring(jiedlng + 1, jiedlng.length);

      this.estimatedlng = dlng;
      this.estimatedlat = dlat;
    },
    yuprice(yuprice) {
      this.guidePrice = yuprice;
    },
    preline(item) {
      this.selectline = item;
      console.log(item);
      console.log(this.radio1);
      if (this.radio1 == "1") {
        console.log(this.radio1);
        this.chargetype = 1;
        this.guidePrice = this.selectline.price;
      } else if (this.radio1 == "2") {
        console.log(this.radio1);
        this.chargetype = 1;
        this.guidePrice = this.selectline.price2;
      } else if (this.radio1 == "3") {
        console.log(this.radio1);
        this.chargetype = 1;
        this.guidePrice = this.selectline.price3;
      }
    },

    async huoqu() {
      console.log("点击了", this.belongCid);
      let res = await api1.jionDetail(this.belongCid);
      console.log("获取地址", res.data.data.address);
      this.destination = res.data.data.address;
    },

    async getcartype() {
      let res = await api.getcartype();
      let carlist = Object.entries(res.data);
      console.log("carlist", carlist);
      this.options = [];
      this.all = [];
      for (let i = 0; i < carlist.length; i++) {
        if (carlist[i][1].length > 1) {
          let ylist = [];
          for (let y = 0; y < carlist[i][1].length; y++) {
            let objy = {
              value: carlist[i][1][y].parameter,
              label: carlist[i][1][y].parameter,
              id: carlist[i][1][y].id,
            };
            ylist.push(objy);
            this.all.push(carlist[i][1][y]);
          }
          let obj = {
            value: carlist[i][0],
            label: carlist[i][0],
            children: ylist,
          };
          this.options.push(obj);
        } else {
          let obj = {
            value: carlist[i][0],
            label: carlist[i][0],
            id: carlist[i][1][0].id,
          };
          this.options.push(obj);
          this.all.push(carlist[i][1][0]);
        }
      }
    },
    async getcartypenew(cid) {
      if(this.rescueType != 1)
        return true;

      let res = await api.getcartypenew(cid);
      let carlist = Object.entries(res.data);
      console.log("carlist", carlist);
      this.options = [];
      this.all = [];
      for (let i = 0; i < carlist.length; i++) {
        if (carlist[i][1].length > 1) {
          let ylist = [];
          for (let y = 0; y < carlist[i][1].length; y++) {
            let objy = {
              value: carlist[i][1][y].parameter,
              label: carlist[i][1][y].parameter,
              id: carlist[i][1][y].id,
            };
            ylist.push(objy);
            this.all.push(carlist[i][1][y]);
          }
          let obj = {
            value: carlist[i][0],
            label: carlist[i][0],
            children: ylist,
          };
          this.options.push(obj);
        } else {
          let obj = {
            value: carlist[i][0],
            label: carlist[i][0],
            id: carlist[i][1][0].id,
          };
          this.options.push(obj);
          this.all.push(carlist[i][1][0]);
        }
      }

      if (this.selectline == "" && this.isTuo == true && this.fours == true) {
        if (this.cartype[1] != undefined) {
          let ret = this.all.find((ev) => {
            return (
              ev.rescued_name == this.cartype[0] &&
              ev.parameter == this.cartype[1]
            );
          });

          this.chargetype = 2;
          this.guidePrice = ret.price;
          this.guideKm = ret.free;
          this.kmPrice = ret.exceed;

          this.cartypename = this.cartype[0] + "/" + this.cartype[1];
          this.needcar = ret.type_name;
        } else {
          let ret = this.all.find((ev) => {
            return ev.rescued_name == this.cartype[0];
          });

          this.chargetype = 2;
          this.guidePrice = ret.price;
          this.guideKm = ret.free;
          this.kmPrice = ret.exceed;

          this.cartypename = this.cartype[0];
          this.needcar = ret.type_name;
        }
      } else {
        this.cartypename =
          this.cartype[1] != undefined
            ? this.cartype[0] + "/" + this.cartype[1]
            : this.cartype[0];
        this.needcar = this.isTuo ? "2吨平板拖车" : "";
      }
    },
  },
  watch: {
    async isset(newval) {
      // console.log("---", newval);
      // this.issets = newval;
      // let res_gethz = await api.getHzlist();
      // this.hzlist = res_gethz.data;
      // this.belongCid = this.hzlist[0].cid;
      // this.yushenum = this.hzlist[0].preline;
      // this.fours = this.hzlist[0].is_carprice > 0 ? true : false;

      // let res_rescuelist = await api.getservicelist(this.belongCid);
      // if (res_rescuelist.code == 1) {
      //   this.rescueList = res_rescuelist.data;
      //   this.rescueType = this.rescueList[0].rescueType;
      //   if (
      //     this.rescueType == 1 ||
      //     this.rescueType == 2 ||
      //     this.rescueType == 19
      //   ) {
      //     this.isTuo = true;
      //   }

      //   this.changerescuetype();

      //   if (this.fours == true) {
      //     this.getcartypenew(this.belongCid);
      //   }else{
      //     this.getcartype();
      //   }
      // } else {
      //   // alert(res_rescuelist.message);
      //   this.$message.warning(res_rescuelist.message);
      // }
    },
    address(newval) {
      if (newval == this.oldaddressLnglat) {
        this.isShow = false;
        return;
      }
      this.addressLnglat = "";
      if (newval == "") {
        this.isShow = false;
        return false;
      }
      if (this.addressLnglat == "") {
        this.search(newval);
      }
    },
    destination(newval) {
      console.log("------", newval);
      if (newval == this.olddestinationLnglat) {
        this.isShow2 = false;
        return;
      }
      this.destinationLnglat = "";
      if (newval == "") {
        this.isShow2 = false;
        return false;
      }
      if (this.destinationLnglat == "") {
        this.search2(newval);
      }
    },
    guidePrice(newval)
    {
      this.diaoyong()
    },
    guideKm(newval)
    {
      this.diaoyong()
    },
    kmPrice(newval){
      this.diaoyong()
    },
    belongCid(newval) {
      // console.log("预估合作单位", newval);
      if (this.belongCid != "" && newval != "") {
        this.diaoyong();
      }
    },
    addressLnglat(newval) {
      let jielng = this.addressLnglat.indexOf(",");
      let lng = this.addressLnglat.substring(0, jielng);
      let lat = this.addressLnglat.substring(jielng + 1, jielng.length);

      this.estimatelng = lng;
      this.estimatelat = lat;
      if (this.destinationLnglat != "" && newval != "") {
        this.diaoyong();
      }
    },
    destinationLnglat(newval) {
      let jiedlng = this.destinationLnglat.indexOf(",");
      let dlng = this.destinationLnglat.substring(0, jiedlng);
      let dlat = this.destinationLnglat.substring(jiedlng + 1, jiedlng.length);

      this.estimatedlng = dlng;
      this.estimatedlat = dlat;
      if (this.addressLnglat != "" && newval != "") {
        this.diaoyong();
      }
    },
    needcar(newval) {
      if (this.needcar != "" && newval != "") {
        this.diaoyong();
      }
    },
    is_entrust(newval) {
      console.log(newval);
    },
  },
};
</script>

<style lang="less" scoped>
ul {
  list-style: none;
}
li {
  margin: 9px;
  margin-left: 2px;
  cursor: pointer;
}
select {
  width: 350px;
  height: 40px;
  float: left;
}
.item-label {
  float: left;
  margin-top: 22px;
  //margin-right: 20px;
}
.item-chargetype {
  float: left;
  //margin-top: 22px;
  width: 100px;
  margin-right: 12px;
}
.demo-range .el-date-editor {
  margin: 8px;
}

.demo-range .el-range-separator {
  box-sizing: content-box;
}
.time {
  margin-left: 75px;
}

.box {
  width: 640px;
  // height: 940px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
  // background-color: pink;
}
.cha {
  float: left;
  width: 40px;
  height: 40px;
  margin-top: 70px;
  margin-left: 0px;
}
.box-main {
  width: 600px;
  height: 940px;
  background: #fff;
  margin-left: 40px;
  margin-top: -21px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
  overflow-y: scroll;
}
.box-main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  padding-left: 37px;
}
h1 {
  line-height: 80px;
  width: 160px;
  height: 26px;
  padding-top: 21px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.unit {
  // height: 40px;
  opacity: 1;
  margin-top: 12px;
  margin-left: 30px;
  display: flex;
  align-items: center;
}
.qieBox {
  // margin-left: 30px;
  display: flex;
  align-items: center;
  position: relative;
}
.qieBox /deep/ .el-button--primary {
  position: absolute;
  right: 20px;
}
.pons {
  margin-bottom: 10px;
}

.join {
  // width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  // line-height: 0px;
  color: #2a3346;
  opacity: 1;
  margin-right: 20px;
}

.input-unit,
.input-item {
  //width: 350px;
  height: 40px;
  box-sizing: border-box;
  //padding-left: 12px;
  // margin-left: 16px;
  background: #ffffff;
  //border: 1px solid #dadfe6;
  opacity: 1;
  outline: none;
  border-radius: 2px;
  padding-top: 5px;
}

.jian {
  width: 40px;
  height: 40px;
  float: left;
  padding-left: 12px;
  padding-top: 8px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;

  box-sizing: border-box;
}
.jian {
  position: absolute;
  top: 110px;
  right: 144px;
}

// 车主信息
.input-owner1 {
  width: 170px;
  // width: 130px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  // margin-left: 16px;
  box-sizing: border-box;
  border-radius: 2px;
  outline: none;
  padding-left: 12px;
}
.input-owner {
  width: 170px;
  // width: 140px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  box-sizing: border-box;
  // margin-left: 10px;
  border-radius: 2px;
  outline: none;
  padding-left: 12px;
}
.input-guakm {
  width: 100px;
}
// 下单人
.xiaInput {
  width: 450px;
  // width: 350px;
  height: 32px;
  // border: 1px solid #dadfe6;
  margin-right: 10px;
  // padding-left: 12px;
  box-sizing: border-box;
}

// 服务项目

// 收费标准

.label-noem {
  float: left;
  margin-right: 16px;
  margin-top: 15px;
}

.yuan,
.yuan1,
.yuan2 {
  float: left;
  height: 32px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 0 2px 2px 0;
  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  margin-right: 12px;
}
.yuan {
  width: 40px;
}
.yuan1 {
  width: 50px;
}
.yuan2 {
  width: 70px;
}
.input-norm {
  float: left;
  width: 47px;
  height: 32px;
  // margin-left: 10px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px 0 0 2px;
  outline: none;
  box-sizing: border-box;
  text-align: center;
  border-right: 0;
}
.input-norm1 {
  float: left;
  width: 46px;
  height: 32px;
  background: #ffffff;
  // margin-left: 10px;
  outline: none;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px 0 0 2px;
  text-align: center;
  box-sizing: border-box;
  border-right: 0;
}
.input-norm2 {
  float: left;
  width: 46px;
  height: 32px;
  background: #ffffff;
  // margin-left: 10px;
  outline: none;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px 0 0 2px;
  text-align: center;
  box-sizing: border-box;
  //border-right: 0;
}
// 事故点
.entrust {
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
// .duoxuan {
//   margin-left: 34px;
// }
.fache {
  width: 170px;
  // width: 210px;
  height: 32px;
  padding: 3px 12px;
  box-sizing: border-box;
  // padding-left: 12px;
  border: 1px solid #dcdfe6;
  // margin-right: 10px;
}

.ren {
  line-height: 20px;
}
.accident-input,
.accident-input1 {
  width: 370px;
  // width: 350px;
  height: 32px;
  outline: none;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  padding-left: 12px;
}
.accident-input1 {
  width: 440px;
  box-sizing: border-box;
}
input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
input::-webkit-input-placeholder {
  font-size: 14px;
  color: #c0c4cc;
}

.accident-input:hover {
  border: 1px solid #c0c4cc;
}
.accident-input1:hover {
  border: 1px solid #c0c4cc;
}
.remark {
  margin-left: 30px;
}
// .accident-input {
//   margin-left: 34px;
// }
// .accident-input1 {
//   margin-left: 48px;
// }
.dingren {
  position: relative;
}
.mapImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  // margin-left: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.qie {
  width: 16px;
  height: 16px;
  margin-left: 50px;
  cursor: pointer;
}

.text {
  width: 440px;
}
.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 106px;
  margin-top: 44px;
  margin-bottom: 30px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}

// 预约时间
// .yuradio {
//   width: 210px;
// }

// .block /deep/ .el-input--inner {
//   width: 210px;
//   background-color: pink;
// }
// .demo-datetime-picker {
//   display: flex;
//   width: 100%;
//   padding: 0;
//   flex-wrap: wrap;
// }
// .demo-datetime-picker .block {
//   background-color: pink;
//   padding: 30px 0;
//   text-align: center;
//   border-right: solid 1px var(--el-border-color-base);
//   flex: 1;
// }
// .demo-datetime-picker .block:last-child {
//   border-right: none;
// }
// .demo-datetime-picker .demonstration {
//   display: block;
//   color: var(--el-text-color-secondary);
//   font-size: 14px;
//   margin-bottom: 20px;
// }

// 地址
.container {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}

.msg {
  margin: 5px;
}
.rending {
  position: relative;
}

.select-panel {
  // height: 100px;
  position: absolute;
  bottom: 40px;
  margin-left: 77px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  // background-color: pink;
}

.select-item {
  /*height: 0;*/
  z-index: 999;
  margin: 1px;
  padding: 2px;
  background: #fff;
  opacity: 0.8;
}

.comment {
  line-height: 31px;
}

.beizhu {
  font-size: 14px;
  margin: 16px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  border: 1px solid #d9d9d9;
}
.tiImg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.tiTit {
  color: rgba(0, 0, 0, 0.45);
}
.kong {
  width: 350px;
}
.jianju {
  margin-top: 0;
}

.unit /deep/ .el-cascader .el-input .el-input__inner {
  width: 350px;
}
.carnameleft /deep/ .el-cascader .el-input .el-input__inner {
  width: 170px;
}
.basic /deep/ .el-select .el-input {
  width: 450px;
}
.carnameleft /deep/ .el-select .el-input {
  width: 170px;
}

.unit /deep/ .el-input--prefix .el-input__inner {
  width: 210px;
}

.yuradio /deep/ .el-radio--large {
  margin-right: 15px;
}
.xing {
  color: red;
}
.rending /deep/ .el-button--primary {
  position: absolute;
  margin-left: 20px;
  box-sizing: border-box;
}

.foundtit {
  font-size: 16px;
  color: #405580;
  font-weight: bold;
  // padding-left: 37px;
  margin-top: 28px;
  margin-left: 37px;
  border-left: 5px solid #2c68ff;
  padding-left: 10px;
  box-sizing: border-box;
  position: relative;
}
.carnameleft,
.carnameright {
  display: flex;
  align-items: center;
}
.carnameright {
  margin-left: 25px;
}
.yuetimebox /deep/ .el-input__suffix {
  right: 30px;
}
.mileage {
  font-size: 16px;
  color: #2c68ff;
  font-weight: bold;
  margin-right: 16px;
}
.mileageprice {
  font-size: 16px;
  color: #ff525c;
  font-weight: bold;
}

.xuannum {
  position: absolute;
  left: 100px;
  top: -4px;
  display: flex;
  align-items: center;
}
strong {
  font-size: 0.083333rem;
  font-weight: 500;
  font-size: 12px;
  color: red;
  margin-left: 10px;
}


.xuannum /deep/ .el-select .el-input .el-select__caret.el-icon{
  display:none;
}
.input-item-x {
  width:70px;
  height: 31px;
  box-sizing: border-box;
  //padding-left: 12px;
  // margin-left: 16px;
  background: #ffffff;
  //border: 1px solid #dadfe6;
  opacity: 1;
  outline: none;
  border-radius: 2px;
  text-align: center;
}

.xuannum /deep/ .el-input__inner{
  text-align: right;
}

.xuannum /deep/ .input-item-x .el-input__suffix{
  display: none;
}

.not-arrow {
  border: 1px solid #dcd8d8;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /*去掉下拉箭头*/
  width: 40px;
  height: 31px;
  text-align: center;
  border-radius: 2px;
}
.not-arrow::-ms-expand {
  display: none;
}
select:focus {
  outline: none;
  border-color: #2c68ff;
  box-shadow: none;
}
.arrow-opt{
  height:50px;
  padding:0px 5px;
}

.newunit {
  opacity: 1;
  margin-top: 12px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.newcarnameleft {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.addimg {
  width: 24px;
  height: 32px;
  cursor: pointer;
}
.carnameleft,
.carnameright {
   display: flex;

   align-items: center;
}
.carnameleft {

  margin-right: 25px;
}
</style>
